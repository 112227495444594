// src/arrays-contain-same-elements/index.ts
var arraysContainSameElements = (arr1, arr2) => {
  const set1 = new Set(arr1);
  const set2 = new Set(arr2);
  return arr1.every((item) => set2.has(item)) && arr2.every((item) => set1.has(item));
};
var arrays_contain_same_elements_default = arraysContainSameElements;

// src/build-app-url/index.ts
var buildAppUrl = (path) => {
  var _a, _b;
  const url = ((_a = import.meta.env) == null ? void 0 : _a.VITE_APP_URL) || ((_b = process.env) == null ? void 0 : _b.VITE_APP_URL);
  return `${url}${path}`;
};
var build_app_url_default = buildAppUrl;

// src/capitalize/index.ts
var capitalize = (word) => {
  if (!word)
    return "";
  return `${word.charAt(0).toUpperCase()}${word.slice(1)}`;
};
var capitalize_default = capitalize;

// src/clip-text-at/index.ts
var clipTextAt = ({ text, length }) => {
  if (!text)
    return "";
  if (text.length <= length)
    return text;
  return `${text.substring(0, length)}...`;
};
var clip_text_at_default = clipTextAt;

// src/cookies/index.ts
var setCookie = ({ name, value, days }) => {
  const expireDate = new Date(Date.now() + days * 864e5).toUTCString();
  const cookieName = `${name}=${encodeURIComponent(value)};`;
  const expires = `expires=${expireDate};`;
  const path = `path=/;`;
  const domain = `domain=.spectora.com`;
  const cookie = `${cookieName} ${expires} ${path} ${domain}`;
  document.cookie = cookie;
};
var getCookie = (name) => {
  const match = document.cookie.match(new RegExp("(^| )" + name + "=([^;]+)"));
  if (match)
    return match[2];
};

// src/date-ago/index.ts
var dateAgo = () => {
};
var date_ago_default = dateAgo;

// src/date-end-of/index.ts
var dateEndOf = () => {
};
var date_end_of_default = dateEndOf;

// src/date-range-from-string/index.ts
var dateRangeFromString = () => {
};
var date_range_from_string_default = dateRangeFromString;

// src/date-start-of/index.ts
var dateStartOf = () => {
};
var date_start_of_default = dateStartOf;

// src/dig/index.ts
var dig = (obj, path, defaultValue = void 0) => {
  const travel = (regexp) => String.prototype.split.call(path, regexp).filter(Boolean).reduce(
    (res, key) => res !== null && res !== void 0 ? res[key] : res,
    obj
  );
  const result = travel(/[,[\]]+?/) || travel(/[,[\].]+?/);
  return result === void 0 || result === obj ? defaultValue : result;
};
var dig_default = dig;

// src/format-bytes/index.ts
var formatBytes = (bytes) => {
  const units = ["bytes", "KB", "MB", "GB"];
  let unitIndex = 0;
  while (bytes >= 1024 && unitIndex < units.length - 1) {
    bytes /= 1024;
    unitIndex++;
  }
  return `${Math.round(bytes)} ${units[unitIndex]}`;
};
var format_bytes_default = formatBytes;

// src/format-contact-name/index.ts
var formatContactName = ({
  contact,
  fallback = "-",
  reverse
}) => {
  const firstName = contact.first_name ?? "";
  const lastName = contact.last_name ?? "";
  if (!firstName && !lastName) {
    return fallback;
  }
  if (contact.show_as_company) {
    return firstName.trim();
  }
  const fullName = reverse ? [lastName, firstName].join(", ") : `${firstName} ${lastName}`;
  return fullName.trim();
};
var format_contact_name_default = formatContactName;

// src/format-currency/index.ts
var formatCurrency = ({ value, empty = "" }) => {
  const isInvalidValue = isNaN(value) || value === null;
  if (isInvalidValue) {
    return empty || "";
  }
  return Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD"
  }).format(value);
};
var format_currency_default = formatCurrency;

// src/format-date/index.ts
import dayjs from "dayjs";
var formatDate = ({
  value,
  format = "MM/DD/YYYY",
  fallback = "-"
}) => value ? dayjs(value).format(format) : fallback;
var format_date_default = formatDate;

// src/format-date-ago/index.ts
var formatDateAgo = () => {
};
var format_date_ago_default = formatDateAgo;

// src/format-date-time/index.ts
var formatDateTime = () => {
};
var format_date_time_default = formatDateTime;

// src/format-date-time-in-timezone/index.ts
var formatDateTimeInTimezone = () => {
};
var format_date_time_in_timezone_default = formatDateTimeInTimezone;

// src/format-full-name/index.ts
var formatFullName = ({
  first_name,
  last_name,
  fallback = "-",
  reverse
}) => {
  if (!first_name && !last_name) {
    return fallback;
  }
  return reverse ? `${last_name}, ${first_name}` : `${first_name} ${last_name}`;
};
var format_full_name_default = formatFullName;

// src/format-number/index.ts
var formatNumber = () => {
};
var format_number_default = formatNumber;

// src/format-phone/index.ts
import Imask from "imask";
var DEFAULT_MASK = {
  all: {
    mask: "000-000-0000"
  },
  countryCode: {
    mask: "000-000-0000"
  }
};
var locales = { US: DEFAULT_MASK, CA: DEFAULT_MASK };
var phoneMasksForLocale = (locale) => Object.values(locales[locale] || {});
var formatPhone = ({ value, locale }) => {
  if (!value)
    return "";
  const sanitizedValue = String(value).replace(/^\+1|\D+/g, "");
  const mask = phoneMasksForLocale(locale || "US");
  return mask.length > 0 ? Imask.pipe(sanitizedValue, { mask }) : value;
};
var format_phone_default = formatPhone;

// src/format-text/index.ts
var formatText = ({ value }) => {
  let result = `<p>${value || ""}</p>`;
  result = result.replace(/\r\n\r\n/g, "</p><p>").replace(/\n\n/g, "</p><p>");
  result = result.replace(/\r\n/g, "<br />").replace(/\n/g, "<br />");
  return result;
};
var format_text_default = formatText;

// src/generate-csv-file/index.ts
var generateCSVFile = ({ headers, rows, fileName }) => {
  const csvContent = [headers.join(","), ...rows].join("\n");
  const blob = new Blob([csvContent], { type: "text/csv" });
  return new File([blob], fileName, { type: "text/csv" });
};
var generate_csv_file_default = generateCSVFile;

// src/generate-file-download/index.ts
var generateFileDownload = (file) => {
  const url = URL.createObjectURL(file);
  const a = document.createElement("a");
  a.href = url;
  a.download = file.name;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
};
var generate_file_download_default = generateFileDownload;

// src/generate-uuid/index.ts
import { v4 as uuid } from "uuid";
function generateUUid(options = {}) {
  return uuid(options);
}

// src/get-window-url-query-param/index.ts
var getWindowURLQueryParam = (name) => {
  var _a;
  const queryParams = new URLSearchParams(window.location.search);
  return (_a = queryParams.get(name)) == null ? void 0 : _a.toString();
};
var get_window_url_query_param_default = getWindowURLQueryParam;

// src/input-mask/index.ts
var DEFAULT_LOCALIZED_MASKS = {
  ein: "##-#######",
  phone: "+1 ###-###-####",
  zip: "#####"
};
var EIN_LOCALIZED_MASKS = {
  US: DEFAULT_LOCALIZED_MASKS.ein,
  CA: DEFAULT_LOCALIZED_MASKS.ein
};
var PHONE_LOCALIZED_MASKS = {
  US: DEFAULT_LOCALIZED_MASKS.phone,
  CA: DEFAULT_LOCALIZED_MASKS.phone
};
var ZIP_LOCALIZED_MASKS = {
  US: DEFAULT_LOCALIZED_MASKS.zip,
  CA: "a#a #a#"
};
var inputMaskStrategy = {
  ein: EIN_LOCALIZED_MASKS,
  phone: PHONE_LOCALIZED_MASKS,
  zip: ZIP_LOCALIZED_MASKS
};
var inputMask = ({ mask, locale }) => inputMaskStrategy[mask][locale || "US"];
var input_mask_default = inputMask;

// src/input-validation/utils.ts
var inputValidationType = (required) => required ? "required|matches" : "matches";
var requiredMessage = ({ node }) => `${node.context.label} is required.`;
var generateValidation = ({
  required,
  validationMessages,
  rules,
  example,
  type
}) => ({
  type: inputValidationType(required),
  rules,
  messages: {
    matches: `Please enter a valid ${type}. (Ex: ${example})`,
    required: requiredMessage,
    ...validationMessages
  }
});

// src/input-validation/inputEINValidation.ts
var DEFAULT_EIN_VALIDATION_STRATEGY = {
  rules: {
    matches: (node) => /./.test(node.value)
  },
  example: "123456789"
};
var US_EIN_VALIDATION_STRATEGY = {
  rules: {
    matches: (node) => /\d{2}[-]\d{7}$|(__-_______)/.test(node.value)
  },
  example: "12-3456789"
};
var inputEINStrategy = {
  Default: DEFAULT_EIN_VALIDATION_STRATEGY,
  US: US_EIN_VALIDATION_STRATEGY,
  CA: US_EIN_VALIDATION_STRATEGY
};
var inputEINValidation = ({
  locale,
  required,
  validationMessages
}) => {
  const { rules, example } = inputEINStrategy[locale] || inputEINStrategy.Default;
  return generateValidation({
    type: "ein",
    required,
    validationMessages,
    rules,
    example
  });
};
var inputEINValidation_default = inputEINValidation;

// src/input-validation/inputPhoneValidation.ts
var US_PHONE_VALIDATION_STRATEGY = {
  rules: {
    required: (node) => Boolean(node.value) && !/\+1 ___-___-____/.test(node.value),
    matches: (node) => /\d{3}[-]\d{3}[-]\d{4}$|(\+1 ___-___-____)/.test(node.value)
  },
  example: "+1 123-456-7890"
};
var DEFAULT_PHONE_VALIDATION_STRATEGY = {
  rules: {
    matches: (node) => /^\d+$/.test(node.value)
  },
  example: "1234567890"
};
var inputPhoneStrategy = {
  Default: DEFAULT_PHONE_VALIDATION_STRATEGY,
  US: US_PHONE_VALIDATION_STRATEGY,
  CA: US_PHONE_VALIDATION_STRATEGY
};
var inputPhoneValidation = ({
  locale,
  required,
  validationMessages
}) => {
  const { rules, example } = inputPhoneStrategy[locale || "US"] || inputPhoneStrategy.Default;
  return generateValidation({
    type: "phone",
    required,
    validationMessages,
    rules,
    example
  });
};
var inputPhoneValidation_default = inputPhoneValidation;

// src/input-validation/inputZipValidation.ts
var inputZipValidationStrategy = {
  Default: {
    rules: {
      matches: (node) => /./.test(node.value)
    },
    example: "12345"
  },
  US: {
    rules: {
      required: (node) => Boolean(node.value) && !/_{5}/.test(node.value),
      matches: (node) => {
        const isEmpty = /_{5}/.test(node.value);
        const isValid = /^\d{5}$/.test(node.value);
        return isEmpty || isValid;
      }
    },
    example: "12345"
  },
  CA: {
    rules: {
      required: (node) => Boolean(node.value) && !/^_{3}\s_{3}$/.test(node.value),
      matches: (node) => {
        const isEmpty = /^_{3}\s_{3}$/.test(node.value);
        const isValid = /^[A-Za-z]\d[A-Za-z]\s\d[A-Za-z]\d$/.test(node.value);
        return isEmpty || isValid;
      }
    },
    example: "V6Z 1B9"
  }
};
var inputZipValidation = ({
  locale,
  required,
  validationMessages
}) => {
  const { rules, example } = inputZipValidationStrategy[locale || "US"] || inputZipValidationStrategy.Default;
  return generateValidation({
    type: "zip code",
    required,
    validationMessages,
    rules,
    example
  });
};
var inputZipValidation_default = inputZipValidation;

// src/input-validation/index.ts
var validationStrategy = {
  ein: inputEINValidation_default,
  phone: inputPhoneValidation_default,
  zip: inputZipValidation_default
};
var inputValidation = ({
  mask,
  locale = "US",
  required,
  validationMessages
}) => validationStrategy[mask]({ locale, required, validationMessages });
var input_validation_default = inputValidation;

// src/is-array/index.ts
var isArray = (value) => {
  return value instanceof Array;
};
var is_array_default = isArray;

// src/is-file-type-accepted/index.ts
var isFileTypeAccepted = ({ accept, file }) => {
  var _a, _b, _c;
  if (!accept)
    return true;
  const acceptList = accept.split(",").map((item) => item.trim());
  const fileType = file == null ? void 0 : file.type;
  const fileExtension = `.${(_c = (_b = (_a = file == null ? void 0 : file.name) == null ? void 0 : _a.split(".")) == null ? void 0 : _b.pop()) == null ? void 0 : _c.toLowerCase()}`;
  return acceptList.some(
    (type) => type.startsWith(".") ? fileExtension === type.toLowerCase() : type.includes("/*") ? fileType == null ? void 0 : fileType.startsWith(type.split("/")[0] + "/") : fileType === type
  );
};
var is_file_type_accepted_default = isFileTypeAccepted;

// src/is-production/index.ts
var isProduction = () => {
  var _a, _b;
  const env = ((_a = import.meta.env) == null ? void 0 : _a.VITE_APP_SPECTORA_ENV) || ((_b = process.env) == null ? void 0 : _b.VITE_APP_SPECTORA_ENV);
  return env === "production";
};
var is_production_default = isProduction;

// src/is-same-array/index.ts
var isSameArray = (arr1, arr2) => arr1.length === arr2.length && [...arr1].sort().join(",") === [...arr2].sort().join(",");
var is_same_array_default = isSameArray;

// src/is-valid-email/index.ts
var isValidEmail = (email) => /^\w+([+\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(String(email));
var is_valid_email_default = isValidEmail;

// src/is-valid-url/index.ts
var isValidURL = (url) => /^(?:(?:https?):\/\/)?(?:[\w-]+\.)+[a-z]{2,}(?::\d+)?(?:\/[^/?#]+)*(\/)?(?:\?[^#]*)?(?:#.*)?$/.test(
  url
);
var is_valid_url_default = isValidURL;

// src/log-app-version/index.ts
var logAppVersion = ({
  name,
  version
}) => {
  console.log(
    `%c spectora ${name} %c version: ${version} %c `,
    "background:#203851 ; padding: 1px; border-radius: 3px 0 0 3px;  color: #fff",
    "background: #3F6C9E; padding: 1px; border-radius: 0 3px 3px 0;  color: #fff",
    "background:transparent"
  );
};
var log_app_version_default = logAppVersion;

// src/parse-api-error/index.ts
var parseAPIError = (err) => {
  var _a, _b, _c, _d;
  return ((_d = (_c = (_b = (_a = err == null ? void 0 : err.response) == null ? void 0 : _a.data) == null ? void 0 : _b.errors) == null ? void 0 : _c.map((error) => error.title)) == null ? void 0 : _d.join(", ")) ?? "";
};
var parse_api_error_default = parseAPIError;

// src/parse-csv-file-to-json/index.ts
import Papa from "papaparse";

// src/format-csv-parse-errors/index.ts
var formatCSVParseErrors = (errors) => errors.map(({ message, row }) => `Row ${row + 2} => ${message}`).join("\n");
var format_csv_parse_errors_default = formatCSVParseErrors;

// src/parse-csv-file-to-json/index.ts
var parseCSVFileToJSON = async (file) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.onload = (event) => {
    var _a;
    const content = (_a = event == null ? void 0 : event.target) == null ? void 0 : _a.result;
    const {
      data: rows,
      meta: { fields: headers },
      errors
    } = Papa.parse(content, {
      header: true,
      skipEmptyLines: true
    });
    if (errors.length > 0) {
      reject(format_csv_parse_errors_default(errors));
    } else {
      resolve({ headers, rows });
    }
  };
  reader.onerror = (event) => {
    console.error("Error reading file");
    reject(event);
  };
  reader.readAsText(file);
});
var parse_csv_file_to_json_default = parseCSVFileToJSON;

// src/parse-date-format/index.ts
var parseDateFormatToFlatpickr = (date) => date.replace("%m", "m").replace("%d", "d").replace("%Y", "Y").replace("%H", "H").replace("%l", "h").replace("%M", "m").replace("%S", "s").replace("%P", "a");
var parseDateFormatToDayJs = (date) => date.replace("%m", "MM").replace("%d", "DD").replace("%Y", "YYYY").replace("%H", "H").replace("%l", "h").replace("%M", "mm").replace("%S", "ss").replace("%P", "a");
var convertDateStrategy = {
  flatpickr: parseDateFormatToFlatpickr,
  dayjs: parseDateFormatToDayJs
};
var parseDateFormat = ({
  value,
  format = "dayjs"
}) => convertDateStrategy[format](value || "");
var parse_date_format_default = parseDateFormat;

// src/redirect-unauthorized/index.ts
var redirectUnauthorized = ({
  message = `You're not able to view this page. Please contact your company admin.`
} = {}) => {
  const url = build_app_url_default("/");
  window.location.href = `${url}?error=${message}`;
};
var redirect_unauthorized_default = redirectUnauthorized;

// src/remove-url-protocol/index.ts
var removeURLProtocol = (url) => {
  if (!url)
    return "";
  return String(url).replace(/http:\/\/|https:\/\//, "");
};
var remove_url_protocol_default = removeURLProtocol;

// src/sanitize-ein/index.ts
var sanitizeEIN = (ein, country) => {
  const mask = input_mask_default({ mask: "ein", locale: country });
  if ((mask == null ? void 0 : mask.replaceAll("#", "_")) === ein)
    return "";
  return ein;
};
var sanitize_ein_default = sanitizeEIN;

// src/sanitize-image-url/index.ts
var sanitizeImageUrl = (image) => typeof image === "string" ? image.replace(/^\/\//, "https://") : image;
var sanitize_image_url_default = sanitizeImageUrl;

// src/sanitize-phone/index.ts
var sanitizePhone = (phone) => {
  if (!phone)
    return "";
  const sanitizedPhone = String(phone).replace(/[^0-9+]/g, "");
  return sanitizedPhone.length < 3 ? "" : sanitizedPhone;
};
var sanitize_phone_default = sanitizePhone;

// src/sanitize-zip/index.ts
var sanitizeZip = (zip) => {
  if (!zip)
    return "";
  const sanitizedZip = String(zip).replace(/_/g, "");
  return sanitizedZip.length < 3 ? "" : sanitizedZip;
};
var sanitize_zip_default = sanitizeZip;

// src/to-form-data/index.ts
var toFormData = ({
  prefix = "",
  data,
  parseMultipartArrays = false
}) => {
  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    if (Array.isArray(data[key]) && parseMultipartArrays) {
      Object.values(data[key]).forEach((value) => {
        const name = prefix ? `${prefix}[${key}][]` : `${key}[]`;
        formData.append(name, String(value));
      });
    } else {
      const name = prefix ? `${prefix}[${key}]` : key;
      formData.append(name, data[key]);
    }
  });
  return formData;
};
var to_form_data_default = toFormData;

// src/to-pascal-case/index.ts
var toPascalCase = (str) => str.split("/").map(
  (snake) => snake.split("_").map((substr) => substr.charAt(0).toUpperCase() + substr.slice(1)).join("").split("-").map((substr) => substr.charAt(0).toUpperCase() + substr.slice(1)).join("")
).join("/");
var to_pascal_case_default = toPascalCase;

// src/to-snake-case/index.ts
var toSnakeCase = (str) => str.trim().replace(/\s+/g, "_").toLowerCase();
var to_snake_case_default = toSnakeCase;

// src/underscore-to-titlecase/index.ts
var underscoreToTitlecase = (str) => {
  return str.split("/").map(
    (snake) => snake.split("_").map((substr) => substr.charAt(0).toUpperCase() + substr.slice(1)).join(" ").split("-").map((substr) => substr.charAt(0).toUpperCase() + substr.slice(1)).join(" ")
  ).join("/");
};
var underscore_to_titlecase_default = underscoreToTitlecase;

// src/us-states/index.ts
var getUSStates = () => {
  const states = [
    { abbreviation: "AK", name: "Alaska" },
    { abbreviation: "AL", name: "Alabama" },
    { abbreviation: "AR", name: "Arkansas" },
    { abbreviation: "AZ", name: "Arizona" },
    { abbreviation: "CA", name: "California" },
    { abbreviation: "CO", name: "Colorado" },
    { abbreviation: "CT", name: "Connecticut" },
    { abbreviation: "DC", name: "District of Columbia" },
    { abbreviation: "DE", name: "Delaware" },
    { abbreviation: "FL", name: "Florida" },
    { abbreviation: "GA", name: "Georgia" },
    { abbreviation: "HI", name: "Hawaii" },
    { abbreviation: "IA", name: "Iowa" },
    { abbreviation: "ID", name: "Idaho" },
    { abbreviation: "IL", name: "Illinois" },
    { abbreviation: "IN", name: "Indiana" },
    { abbreviation: "KS", name: "Kansas" },
    { abbreviation: "KY", name: "Kentucky" },
    { abbreviation: "LA", name: "Louisiana" },
    { abbreviation: "MA", name: "Massachusetts" },
    { abbreviation: "MD", name: "Maryland" },
    { abbreviation: "ME", name: "Maine" },
    { abbreviation: "MI", name: "Michigan" },
    { abbreviation: "MN", name: "Minnesota" },
    { abbreviation: "MO", name: "Missouri" },
    { abbreviation: "MS", name: "Mississippi" },
    { abbreviation: "MT", name: "Montana" },
    { abbreviation: "NC", name: "North Carolina" },
    { abbreviation: "ND", name: "North Dakota" },
    { abbreviation: "NE", name: "Nebraska" },
    { abbreviation: "NH", name: "New Hampshire" },
    { abbreviation: "NJ", name: "New Jersey" },
    { abbreviation: "NM", name: "New Mexico" },
    { abbreviation: "NV", name: "Nevada" },
    { abbreviation: "NY", name: "New York" },
    { abbreviation: "OH", name: "Ohio" },
    { abbreviation: "OK", name: "Oklahoma" },
    { abbreviation: "OR", name: "Oregon" },
    { abbreviation: "PA", name: "Pennsylvania" },
    { abbreviation: "RI", name: "Rhode Island" },
    { abbreviation: "SC", name: "South Carolina" },
    { abbreviation: "SD", name: "South Dakota" },
    { abbreviation: "TN", name: "Tennessee" },
    { abbreviation: "TX", name: "Texas" },
    { abbreviation: "UT", name: "Utah" },
    { abbreviation: "VA", name: "Virginia" },
    { abbreviation: "VT", name: "Vermont" },
    { abbreviation: "WA", name: "Washington" },
    { abbreviation: "WI", name: "Wisconsin" },
    { abbreviation: "WV", name: "West Virginia" },
    { abbreviation: "WY", name: "Wyoming" }
  ];
  return states;
};
var getUSStateByAbbreviation = (abbr) => getUSStates().find((state) => state.abbreviation === abbr);
var getUSStatesAsSelectOptions = () => getUSStates().map((state) => ({
  value: state.abbreviation,
  label: state.name
}));
export {
  arrays_contain_same_elements_default as arraysContainSameElements,
  build_app_url_default as buildAppUrl,
  capitalize_default as capitalize,
  clip_text_at_default as clipTextAt,
  date_ago_default as dateAgo,
  date_end_of_default as dateEndOf,
  date_range_from_string_default as dateRangeFromString,
  date_start_of_default as dateStartOf,
  dig_default as dig,
  format_bytes_default as formatBytes,
  format_contact_name_default as formatContactName,
  format_currency_default as formatCurrency,
  format_date_default as formatDate,
  format_date_ago_default as formatDateAgo,
  format_date_time_default as formatDateTime,
  format_date_time_in_timezone_default as formatDateTimeInTimezone,
  format_full_name_default as formatFullName,
  format_number_default as formatNumber,
  format_phone_default as formatPhone,
  format_text_default as formatText,
  generate_csv_file_default as generateCSVFile,
  generate_file_download_default as generateFileDownload,
  generateUUid,
  getCookie,
  getUSStateByAbbreviation,
  getUSStates,
  getUSStatesAsSelectOptions,
  get_window_url_query_param_default as getWindowURLQueryParam,
  input_mask_default as inputMask,
  input_validation_default as inputValidation,
  is_array_default as isArray,
  is_file_type_accepted_default as isFileTypeAccepted,
  is_production_default as isProduction,
  is_same_array_default as isSameArray,
  is_valid_email_default as isValidEmail,
  is_valid_url_default as isValidURL,
  log_app_version_default as logAppVersion,
  parse_api_error_default as parseAPIError,
  parse_csv_file_to_json_default as parseCSVFileToJSON,
  parse_date_format_default as parseDateFormat,
  redirect_unauthorized_default as redirectUnauthorized,
  remove_url_protocol_default as removeURLProtocol,
  sanitize_ein_default as sanitizeEIN,
  sanitize_image_url_default as sanitizeImageUrl,
  sanitize_phone_default as sanitizePhone,
  sanitize_zip_default as sanitizeZip,
  setCookie,
  to_form_data_default as toFormData,
  to_pascal_case_default as toPascalCase,
  to_snake_case_default as toSnakeCase,
  underscore_to_titlecase_default as underscoreToTitlecase
};
