<script>
import { buildAclMassEmailRule } from '@spectora/acl'
import { BaseIconV2, BaseLogo } from '@spectora/ui'
import { getCookie } from '@spectora/utils'
import axios from 'axios'

const APP_URL = `${import.meta.env.VITE_API_URL}`
const TABS = {
  automation: {
    icon: 'cogs',
    path: '/automation',
    name: 'Automation',
    canView: (context) => {
      return context.canViewAutomation
    },
  },
  actions: {
    icon: 'cogs',
    path: '/actions',
    name: 'Actions',
    canView: (context) => {
      return context.canViewActions
    },
  },
  'advanced-onboarding': {
    icon: 'bar-chart',
    path: '/onboarding/advanced',
    name: 'Advanced',
    canView: (context) => {
      return context.permissions.canAccessFinancialData
    },
  },
  billing: {
    icon: 'fa-solid fa-money-bill-1',
    name: 'Billing',
    path: '/billing',
    canView: (context) => {
      return context.permissions.canAccessFinancialData
    },
  },
  calendar: {
    icon: 'calendar',
    iconVariant: 'regular',
    path: '/calendar',
    name: 'Calendar',
  },
  changelog: {
    icon: 'bolt',
    href: 'https://feedback.spectora.com/changelog',
    target: '_BLANK',
    name: "What's New",
  },
  change_role: {
    icon: 'refresh',
    path: '/users/change_role',
    name: 'Change Role',
    canView: (context) => {
      return context.permissions.hasMultipleProfiles
    },
  },
  contacts: {
    icon: 'address-card',
    iconVariant: 'regular',
    name: 'Contacts',
    route: 'contacts',
    canView: (context) => {
      const clientsOrAgentsEnabled =
        context.permissions.enabledAgents || context.permissions.enabledClients

      return context.permissions.canManageContacts && clientsOrAgentsEnabled
    },
    getUrl(context) {
      if (context.permissions.canUseAgentReports) {
        return '/contacts/agents'
      }

      return `${APP_URL}/contacts/clients`
    },
  },
  dashboard: {
    icon: 'gauge',
    path: '/dashboard',
    name: 'Dashboard',
  },
  data: {
    icon: 'table',
    path: '/data',
    name: 'Data Exports',
    canView: (context) => {
      return context.permissions.canAccessFinancialData
    },
  },
  conversations: {
    route: 'conversations',
    icon: 'comments',
    getUrl: () => '/conversations/inspectors',
    name: 'Conversations',
    showBadge: (context) => context.hasUnreadMessages,
    canView: (context) => {
      return (
        context.permissions.enabledAdvanced &&
        context.permissions.canAccessConversations
      )
    },
  },
  'email-queue': {
    icon: 'envelope',
    path: '/emails',
    name: 'Email Queue',
    canView: (context) => {
      return context.permissions.isCompanyAdmin
    },
  },
  inspections: {
    icon: 'search',
    path: '/manage#/inspections',
    name: 'Inspections',
  },
  marketing: {
    icon: 'line-chart',
    path: '/marketing',
    name: 'Marketing Services',
  },
  metrics: {
    icon: 'line-chart',
    name: 'Metrics',
    getPath(context) {
      return context.permissions.enabledAdvancedMetrics
        ? '/metrics-dashboard'
        : '/metrics'
    },
    canView(context) {
      return context.permissions.canAccessFinancialData
    },
  },
  'new-inspection': {
    icon: 'plus-circle',
    name: 'New Inspection',
    path: '/v/inspections/new',
    canView: (context) => {
      return context.permissions.canScheduleAny
    },
  },
  payouts: {
    icon: 'money-bill-1',
    name: 'Payouts',
    path: '/payouts',
    canView: (context) => {
      return context.permissions.canAccessFinancialData
    },
  },
  payments: {
    icon: 'money-bill-1',
    name: 'Payments',
    path: '/payments',
    canView: (context) => {
      return (
        context.permissions.enabledPayments &&
        context.permissions.canAccessFinancialData
      )
    },
  },
  paysplits: {
    icon: 'chart-pie',
    name: 'Paysplits',
    path: '/paysplits',
    canView: (context) => {
      return (
        context.permissions.enabledPaysplits &&
        context.permissions.canAccessFinancialData
      )
    },
  },
  'pay-reports': {
    icon: 'chart-pie',
    name: 'Pay Reports',
    path: '/paysplits_report',
    canView: (context) => {
      return (
        context.permissions.enabledPaysplits &&
        context.permissions.canAccessFinancialData
      )
    },
  },
  reporting: {
    route: 'reporting',
    icon: 'file',
    iconVariant: 'regular',
    name: 'Reporting',
    getUrl: () => '/reporting/top-agents',
    canView: (context) => {
      return (
        context.permissions.canAccessFinancialData ||
        context.permissions.enabledAdvanced
      )
    },
  },
  tasking: {
    route: 'tasks.index',
    icon: 'list',
    getUrl: () => '/tasks',
    name: 'Tasks',
    showBadge: (context) => context.hasDueTasks,
    canView: (context) => {
      return context.permissions.enabledAdvanced
    },
  },
  profile: {
    icon: 'briefcase',
    name: 'Profile',
    getPath(context) {
      if (context.permissions.isCompanyAdmin) {
        return '/company'
      }

      return '/profile'
    },
  },
  settings: {
    icon: 'sliders',
    path: '/settings',
    name: 'Settings',
  },
  'manage-equipment': {
    route: 'radon_devices.index',
    icon: 'wrench',
    getUrl() {
      return `/advanced/radon-devices`
    },
    canView: (context) => {
      return context.permissions.enabledAdvanced
    },
    name: 'Equipment',
  },
  'text-queue': {
    icon: 'comment-dots',
    name: 'Text Queue',
    path: '/text_messages',
    canView: (context) => {
      return context.permissions.isCompanyAdmin
    },
  },
  team: {
    icon: 'users',
    name: 'Team',
    path: '/team',
    canView: (context) => {
      return context.permissions.isCompanyAdmin
    },
  },
  templates: {
    icon: 'clipboard',
    iconVariant: 'regular',
    name: 'Templates',
    path: '/templates/editor',
    canView: (context) => {
      return context.permissions.canEditTemplate
    },
  },
  'time-tracking': {
    icon: 'clock',
    iconVariant: 'regular',
    name: 'Time Tracking',
    path: '/v/time_tracking/#/',
    canView: (context) => {
      return (
        context.permissions.enabledHourly &&
        context.permissions.canAccessFinancialData
      )
    },
  },
  unconfirmed: {
    icon: 'search-minus',
    name: 'Unconfirmed',
    path: '/unconfirmed',
  },
  'mass emailing': {
    route: 'massEmails',
    icon: 'envelope',
    id: 'mass-email-tab-link',
    name: 'Mass Emails',
    getUrl() {
      return '/mass-emails'
    },
    canView: (context) => {
      const user = {
        profile: {
          is_company_admin: context.permissions.isCompanyAdmin,
          company: context.company,
        },
      }

      return buildAclMassEmailRule(user)
    },
  },
  'support-center': {
    icon: 'circle-question',
    iconVariant: 'regular',
    id: 'support-center-tab-link',
    name: 'Support Center',
    getUrl: () => '/support-center',
    className: 'ml-auto',
  },
}

export default {
  components: {
    BaseIconV2,
    BaseLogo,
  },
  props: {
    company: {
      type: Object,
      default: () => ({
        settings: {},
      }),
    },
    profile: {
      type: Object,
      default: () => ({
        settings: {},
      }),
    },
    user: {
      type: Object,
      default: () => ({}),
    },
    fixed: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      dropdownItems: [],
      dropdownIsOpen: false,
      clickAwayListener: null,
      subnavTabs: [],
    }
  },
  computed: {
    canViewAutomation() {
      return (
        this.permissions.isCompanyAdmin &&
        this.permissions.canAccessFinancialData &&
        !this.permissions.enabledAutoTracks
      )
    },
    canViewActions() {
      const isActionsEnabled =
        this.permissions.trialAutoTracks || this.permissions.enabledAutoTracks

      return (
        this.permissions.isCompanyAdmin &&
        this.permissions.canAccessFinancialData &&
        isActionsEnabled
      )
    },
    hasDueTasks() {
      return (this.user?.due_tasks_count || 0) > 0
    },
    hasUnreadMessages() {
      return (this.company?.unread_conversations_count || 0) > 0
    },
    isMasquerading() {
      const cookie = getCookie('masquerading')
      return cookie === 'true'
    },
    profileSettings() {
      return (this.profile && this.profile.settings) || {}
    },
    permissions() {
      const profileSettings = (this.profile && this.profile.settings) || {}
      const companySettings = (this.company && this.company.settings) || {}
      const companyFeatures = (this.company && this.company.features) || {}
      const user = this.user || {}

      return {
        canUseAgentReports: companySettings.enable_agents_reports,
        calendarV2: !!companyFeatures.calendar_v2,
        canAccessFinancialData:
          profileSettings.can_access_financial_data || false,
        canAccessConversations:
          companySettings.enable_advanced &&
          profileSettings.can_access_conversations,
        canEditTemplate: profileSettings.can_edit_template || false,
        canManageContacts: profileSettings.can_manage_contacts || false,
        canScheduleAny: profileSettings.can_schedule_any || false,
        enabledAdvanced: companySettings.enable_advanced,
        enabledAdvancedMetrics: companySettings.enable_advanced_metrics,
        enabledAgents: companySettings.enable_agents,
        enabledAutoTracks: companySettings.enable_auto_tracks,
        enabledClients: companySettings.enable_clients,
        enabledHourly: companySettings.enable_hourly,
        enabledPayments: companySettings.enable_payments,
        enabledPaysplits: companySettings.enable_paysplits,
        hasMultipleProfiles: user.multiple_profiles,
        isAdmin: user.admin,
        isCompanyAdmin: this.profile.is_company_admin || false,
        trialAutoTracks: companySettings.trial_auto_tracks,
      }
    },
    showActionsAndAutomations() {
      return !this.permissions.enabledAutoTracks
    },
    subnavTabNames() {
      if (!this.profileSettings.subnav_order) {
        return []
      }

      const subnavTabNames =
        (this.profileSettings.subnav_order || '').split(',') || []
      return [...subnavTabNames, 'support-center']
    },
    userName() {
      return `${this.user.first_name} ${this.user.last_name}`
    },
  },
  created() {
    this.clickAwayListener = window.addEventListener('click', (e) => {
      const navDropdownWrapper = document.getElementsByClassName(
        'nav-dropdown-wrapper',
      )[0]

      if (navDropdownWrapper) {
        const clickedWithinMenu = navDropdownWrapper.contains(e.target)

        if (!clickedWithinMenu) {
          this.dropdownIsOpen = false
        }
      }
    })
  },
  mounted() {
    this.buildDropdownNav()
    this.buildSubnav()

    if (typeof window.Canny === 'undefined') {
      return
    }

    window.Canny('initChangelog', {
      appID: '61521a2f40a6850e796b2ed4',
      position: 'bottom',
      align: 'right',
    })
  },
  unmounted() {
    window.removeEventListener('click', this.clickAwayListener)
  },
  methods: {
    buildDropdownNav() {
      const names = [
        'change_role',
        'dashboard',
        'new-inspection',
        'inspections',
        'calendar',
        'unconfirmed',
        'templates',
        'contacts',
        'mass emailing',
        'automation',
        'actions',
        'team',
        'metrics',
        'payments',
        'data',
        'conversations',
        'reporting',
        'time-tracking',
        'tasking',
        'billing',
        'advanced-onboarding',
        'settings',
        'manage-equipment',
        'marketing',
        'changelog',
      ]

      this.dropdownItems = names.map((pathName) =>
        this.buildPath({ pathName, context: this }),
      )
    },
    buildPath({ pathName }) {
      const info = TABS[pathName] ? { ...TABS[pathName] } : null

      if (!info) {
        console.error(`[ERROR] ${pathName} not a valid path`)
        return {}
      }

      info.viewable = true

      if (info.canView && !this.permissions.isAdmin) {
        info.viewable = info.canView(this)
      }

      if (info.getPath) {
        info.path = info.getPath(this)
      }

      if (info.getUrl) {
        info.path = info.getUrl(this)
      }

      if (!info.getUrl) {
        info.path = this.getLegacyAppPath(info.path)
      }

      if (info.showBadge) {
        info.badge = info.showBadge(this)
      }

      return info
    },
    buildSubnav() {
      let subnavNames = this.subnavTabNames

      // if both actions and automations are enabled, let's make sure they are
      // both added to the subnav
      if (this.canViewActions && subnavNames.includes('automation')) {
        const automationIndex = subnavNames.indexOf('automation')
        subnavNames.splice(automationIndex + 1, 0, 'actions')
      }

      if (this.permissions.isAdmin) {
        subnavNames = ['dashboard']
      }

      this.subnavTabs = subnavNames.map((pathName) => {
        return this.buildPath({ pathName, context: this })
      })
    },
    getLegacyAppPath(path) {
      return `${APP_URL}${path}`
    },
    async handleSignOut() {
      await axios.delete(`${APP_URL}/logout.json`, { withCredentials: true })
      window.location = APP_URL
    },
    isSelected(tab) {
      return this.$route.name
        ? this.$route.name.indexOf(tab.route) !== -1
        : false
    },
  },
}
</script>

<template>
  <div class="spectora-nav-wrapper bg-white">
    <div class="spectora-nav-shadow">
      <div class="page-container relative z-20">
        <nav class="spectora-nav-inner flex items-center">
          <div class="flex-1">
            <base-logo :href="getLegacyAppPath('/')" />
          </div>
          <div class="items-center" style="display: flex">
            <div
              v-if="isMasquerading && !permissions.isAdmin"
              class="hide-on-mobile"
            >
              <a
                style="color: orange"
                rel="nofollow"
                data-method="post"
                :href="getLegacyAppPath('/restore_admin')"
              >
                Back to Admin
              </a>
            </div>
            <div class="nav-spacer hide-on-mobile">
              <slot name="search"></slot>
            </div>
            <div
              v-if="permissions.enabledAdvanced"
              class="nav-spacer hide-on-mobile"
            >
              <a
                id="tasks-button"
                href="/tasks"
                class="nav-circle-btn relative flex items-center justify-center rounded-full bg-[#5c9ccf]"
              >
                <base-icon-v2 icon="list" color="white" />
                <div v-if="hasDueTasks" class="badge" />
              </a>
            </div>
            <div
              v-if="permissions.canAccessConversations"
              class="nav-spacer hide-on-mobile"
            >
              <a
                id="conversations-button"
                href="/conversations/inspectors"
                class="nav-circle-btn relative flex items-center justify-center rounded-full bg-[#5c9ccf]"
              >
                <base-icon-v2 icon="comments" color="white" />
                <div v-if="hasUnreadMessages" class="badge" />
              </a>
            </div>
            <div class="nav-spacer hide-on-mobile">
              <a
                id="changelog-button"
                class="nav-circle-btn canny-btn relative flex items-center justify-center rounded-full text-white"
                data-canny-changelog
              >
                <base-icon-v2 icon="bolt" size="xl" />
                <div class="Canny_BadgeContainer">
                  <div class="Canny_Badge"></div>
                </div>
              </a>
            </div>
            <div class="nav-spacer">
              <div class="nav-dropdown-wrapper relative">
                <div class="dropdown-button">
                  <div class="hide-on-mobile">
                    <a
                      style="justify-content: center"
                      @click="dropdownIsOpen = !dropdownIsOpen"
                    >
                      <div class="flex justify-end">
                        <span
                          class="font-bold text-sp-blue-800 max-w-[200px] overflow-hidden text-ellipsis whitespace-nowrap"
                        >
                          {{ userName }}
                        </span>
                        <base-icon-v2 icon="caret-down" class="ml-1" />
                      </div>
                      <div
                        v-if="company"
                        :title="company.name"
                        class="max-w-[200px] overflow-hidden text-ellipsis whitespace-nowrap"
                      >
                        {{ company.name }}
                      </div>
                    </a>
                  </div>
                  <div class="show-on-mobile" style="font-size: 1.5em">
                    <a
                      class="flex items-center"
                      style="justify-content: center"
                      @click="dropdownIsOpen = !dropdownIsOpen"
                    >
                      <base-icon-v2 icon="bars" color="black" />
                      <div
                        v-if="hasUnreadMessages || hasDueTasks"
                        class="badge"
                      />
                    </a>
                  </div>
                </div>

                <transition name="fast-slide-down">
                  <div
                    v-if="dropdownIsOpen"
                    class="dropdown-menu border-gray-200"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="menu-button"
                  >
                    <ul class="dropdown-content">
                      <li class="dropdown-content-nonlink">
                        <div class="profile-type capitalize">
                          Role: {{ profile.type?.replace(/_/g, ' ') }}
                        </div>
                      </li>
                      <template v-for="tab in dropdownItems">
                        <li v-if="tab.viewable" :key="tab.path">
                          <a
                            :href="tab.href || tab.path"
                            :target="tab.target"
                            class="dropdown-content-link relative flex items-center border-gray-200"
                          >
                            <div class="dropdown-item-icon">
                              <base-icon-v2
                                :icon="tab.icon"
                                :variant="tab.iconVariant"
                              />
                            </div>
                            <div class="whitespace-nowrap">{{ tab.name }}</div>
                            <div
                              v-if="tab.badge"
                              class="badge show-on-mobile"
                            />
                          </a>
                        </li>
                      </template>
                      <li class="dropdown-content-link flex items-center">
                        <a
                          href="#"
                          class="flex items-center text-red-600"
                          @click.prevent="handleSignOut"
                        >
                          <base-icon-v2
                            style="margin-right: 10px"
                            icon="sign-out"
                            class="whitespace-spacer mr-2.5"
                          />
                          <div>Sign out</div>
                        </a>
                      </li>
                    </ul>
                  </div>
                </transition>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
    <div class="subnav hide-on-mobile">
      <div class="page-container flex items-center">
        <div class="subnav-inner flex-1">
          <div class="subnav-tabs flex items-center">
            <template v-for="tab in subnavTabs">
              <div
                v-if="tab.viewable"
                :key="tab.name"
                class="subnav-tab"
                :class="tab.className"
              >
                <a
                  :id="tab.id"
                  :href="tab.path"
                  class="subnav-tab-link flex"
                  :class="{ active: isSelected(tab) }"
                >
                  <div class="subnav-tab-icon">
                    <base-icon-v2 :icon="tab.icon" :variant="tab.iconVariant" />
                  </div>
                  <div class="subnav-tab-name">
                    {{ tab.name }}
                  </div>
                </a>
              </div>
            </template>
          </div>
        </div>
        <div v-if="false">
          <a target="_blank" href="http://support.spectora.com/">
            <div
              class="help-button tooltipped"
              data-tooltip="Visit our Support Center for how-to articles and tutorials."
              data-tooltip-id="0a6f2a3f-04dd-9b0a-f43c-d7545a687970"
            >
              <base-icon-v2 icon="book" />
              <span class="hide-on-small-only">Help</span>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@media only screen and (min-width: 993px) {
  .spectora-nav-container {
    // margin: auto;
    // max-width: 1400px;
    // width: 80%;
    @apply px-8;
  }
}

@media only screen and (max-width: 600px) {
  .show-on-mobile {
    display: block;
  }

  .hide-on-mobile {
    display: none;
  }

  #autosuggest__input {
    width: 200px !important;
  }

  .spectora-nav-inner {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media only screen and (min-width: 601px) {
  .show-on-mobile {
    display: none;
  }

  .hide-on-mobile {
    display: block;
  }
}

.spectora-nav-wrapper {
  .spectora-nav-shadow {
    box-shadow:
      0 2px 5px 0 rgba(0, 0, 0, 0.16),
      0 2px 10px 0 rgba(0, 0, 0, 0.12);
    position: relative;
  }

  .spectora-nav-inner {
    background: #fff;
    height: 64px;
  }

  .dropdown-menu {
    position: absolute;
    right: 0px;
    top: 45px;
    width: 220px;
    border: 1px solid;
    background: #fff;

    --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1),
      0 4px 6px -4px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color),
      0 4px 6px -4px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    border-radius: 0.375rem;
  }

  .dropdown-content {
    list-style: none;
    margin: 0px;
    padding: 0px;
  }

  @media (max-height: 850px) {
    .dropdown-content {
      columns: 2;
    }
    .dropdown-menu {
      width: auto;
    }
  }

  @media (max-height: 550px) {
    .dropdown-content {
      columns: 3;
    }
  }

  .dropdown-item-icon {
    margin-right: 10px;
    width: 15px;
    text-align: center;
  }
  .dropdown-content-nonlink {
    padding: 7px 15px;
  }
  .dropdown-content-link {
    padding: 7px 15px;
    font-size: 0.9em;
    border-top: 1px solid #e5e7eb;
    color: #5c9ccf;
  }
  .dropdown-content-link:hover {
    background: whiteSmoke;
  }
  .nav-circle-btn {
    height: 36px;
    width: 36px;
  }
  .nav-spacer {
    margin-left: 8px;
    margin-right: 8px;
  }
  .rounded-full {
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
  }
  .nav-title {
    font-size: 30px;
    padding-top: 2px;
    display: inline-block;
    color: #3b464f;
    letter-spacing: 2px;
    font-weight: 400;
  }
  .nav-logo {
    margin-top: 2px;
    height: 44px;
    width: 44px;
    margin-right: 14px;
  }
  .text-white {
    color: #fff;
  }

  /* ------------------------------ */
  /* SUBNAV */
  /* ------------------------------ */

  .subnav {
    background: #45769c;
  }
  .subnav-inner {
  }
  .subnav-tabs {
    flex-wrap: wrap;
  }
  .subnav-tab-link {
    font-family: 'Open Sans', sans-serif;
    color: #fff;
    padding: 7px 10px;
    margin-top: 8px;
    margin-bottom: 8px;
    margin-right: 15px;
    font-size: 0.9rem;
    font-weight: 100;
    color: white;
    letter-spacing: 1px;
    border-bottom: 1px solid transparent;
  }
  .subnav-tab-link:hover,
  .subnav-tab-link.active {
    border-bottom: 1px solid #fff;
  }

  .subnav-tab-icon {
    margin-right: 5px;
  }
  .subnav-tab-link.active {
    border-bottom: 1px solid #fff;
  }

  /* ------------------------------ */
  /* TAILWIND */
  /* ------------------------------ */

  .border-gray-200 {
    border-color: #e5e7eb;
  }
  .text-red-600 {
    color: #dc2626;
  }
  .text-black {
    color: #000;
  }
  .flex {
    display: flex;
  }
  .flex-1 {
    flex: 1 1 0%;
  }
  .items-center {
    align-items: center;
  }
  .relative {
    position: relative;
  }

  .badge {
    position: absolute;
    top: 0px;
    right: 0px;
    border-radius: 10px;
    background-color: red;
    padding: 5px;
    border: 1px solid white;
  }

  .dropdown-button .show-on-mobile .badge {
    top: -4px;
    right: -6px;
  }

  .dropdown-content-link .badge {
    top: calc(50% - 5px);
    right: 10px;
  }

  /* ------------------------------ */
  /* CANNY */
  /* ------------------------------ */

  .canny-btn {
    background: #5c9ccf;
    text-align: center;

    .Canny_BadgeContainer .Canny_Badge {
      position: absolute;
      top: 0px;
      right: 0px;
      border-radius: 10px;
      background-color: red;
      padding: 5px;
      border: 1px solid white;
    }
  }
}
</style>
