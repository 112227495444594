const PUBLIC_ROUTES = [
  '/contractors/text-messages/subscribe',
  '/join',
  '/partnerships/liveeasy/concierge-request',
  '/request-deletion',
  '/text-messages/unsubscribe',
]

const isPublicRoute = (route: string): boolean =>
  PUBLIC_ROUTES.some((publicRoute) => route.startsWith(publicRoute))

export default isPublicRoute
