import { useAcl } from '@spectora/acl'
import { redirectUnauthorized } from '@spectora/utils'
import { NavigationGuardNext } from 'vue-router'
import routerGuardRedirectUnlessCan from './routerGuardRedirectUnlessCan'

const routerGuardRequireAccessConversationsPermissions = (
  to,
  from,
  next: NavigationGuardNext,
): void => {
  const { ROLES, canUseAdvanced, canAccessConversations } = useAcl()

  if (!canUseAdvanced || !canAccessConversations) {
    return redirectUnauthorized()
  }

  routerGuardRedirectUnlessCan({
    permission: [ROLES.canAccessConversations, ROLES.canUseAdvanced],
    next,
    nextRouteName: 'index',
  })
}

export default routerGuardRequireAccessConversationsPermissions
