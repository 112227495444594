import api, {
  iCompanyPartial,
  iIManagerPartial,
  iInspectorPartial,
  iUserPartial,
} from '@/api'
import { isPublicRoute } from '@/utils'
import { getWindowURLQueryParam } from '@spectora/utils'
import { defineStore } from 'pinia'

const settingsStore = defineStore('settings', {
  getters: {
    paymentProcessorIsSpectora: (state) => {
      return state.company.payment_processor === 'spectora'
    },
    currencySymbol: (state) => state.company.currency_sym || '$',
  },
  state: () => {
    const company: iCompanyPartial = { settings: {} }
    const profile: iIManagerPartial | iInspectorPartial = { settings: {} }
    const user: iUserPartial = {}

    return {
      company,
      profile,
      user,
    }
  },
  actions: {
    async fetch() {
      // Messy way to ensure we have the proper current company for all queries
      const currentCompanyId = getWindowURLQueryParam('current_company_id')

      if (currentCompanyId) {
        api.setDefaultHeader({
          name: 'COMPANY_ID',
          value: getWindowURLQueryParam('current_company_id'),
        })
      }

      if (isPublicRoute(window.location.pathname)) {
        return
      }

      const response = await api.v2.users.me({
        include: ['profile.company'],
        optional_fields: { user: ['intercom_hash', 'due_tasks_count'] },
      })

      // This adds support for CCR reps, making it seem like they have a single current company
      // If it is a CCR rep, it smushes it right on to the response, so everything else works as expected.
      if (currentCompanyId) {
        const currentCompanyResponse = await api.instance.get(
          '/api/v2/profiles/company',
        )

        if (response?.data?.profile) {
          response.data.profile.company = currentCompanyResponse.data
        }
      }

      const user = response.data

      this.company = response?.data?.profile?.company || {}
      this.profile = response?.data?.profile || {}
      this.user = user
    },
    setCompanySetting(key: string, value: any) {
      if (!this.company?.settings) return
      this.company.settings[key] = value
    },
  },
})

export default settingsStore
