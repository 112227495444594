<script setup>
import api from '@/api'
import dayjs from '@/utils/dayjs'
import { useAcl } from '@spectora/acl'
import Multiselect from '@vueform/multiselect'
import { ref } from 'vue'
import { useRouter } from 'vue-router'

const { canUseAdvanced } = useAcl()
const router = useRouter()
const appUrl = import.meta.env.VITE_APP_URL
const searching = ref(false)

const searchableTypes = [
  { name: 'Inspections', type: 'inspection' },
  { name: 'Clients', type: 'contact' },
  { name: 'Agents', type: 'connection' },
]

const handleSelect = (item) => {
  if (item.type === 'inspection') {
    window.location = `${appUrl}/inspection/${item.slug}/edit`
  } else if (item.type === 'connection') {
    const action = canUseAdvanced ? 'show' : 'edit'
    router.push({ name: `contacts.agents.${action}`, params: { id: item.id } })
  }
}

const formatDate = (date) => {
  return dayjs(date).format('[On] MM/DD/YYYY [at] h:mm a')
}

const search = async (query) => {
  if (query.length === 0) {
    return []
  }

  searching.value = true

  const response = await api.instance.get('/api/v2/searchables', {
    params: { filter: { fulltext: query } },
  })

  const results = []

  searchableTypes.forEach((searchableType) => {
    const { name, type } = searchableType
    const data = (response.data || []).filter((row) => row.type === type) || []
    const mappedData = data.map((data) => {
      data.value = data.id
      return data
    })

    results.push({ label: name, options: mappedData })
  })

  searching.value = false

  // return results
  return results
}

const classes = {
  container:
    'relative mx-auto w-full flex items-center justify-end box-border cursor-pointer border border-gray-300 rounded bg-white text-base leading-snug outline-none',
  containerDisabled: 'cursor-default bg-gray-100',
  containerOpen: 'rounded-b-none',
  containerOpenTop: 'rounded-t-none',
  containerActive: 'ring ring-green-500 ring-opacity-30',
  singleLabel:
    'flex items-center h-full max-w-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5 pr-16 box-border',
  singleLabelText:
    'overflow-ellipsis overflow-hidden block whitespace-nowrap max-w-full',
  multipleLabel:
    'flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5',
  search:
    'w-full absolute inset-0 outline-none focus:ring-0 appearance-none box-border border-0 text-base font-sans bg-white rounded pl-3.5',
  tags: 'flex-grow flex-shrink flex flex-wrap items-center mt-1 pl-2',
  tag: 'bg-green-500 text-white text-sm font-semibold py-0.5 pl-2 rounded mr-1 mb-1 flex items-center whitespace-nowrap',
  tagDisabled: 'pr-2 opacity-50',
  tagRemove:
    'flex items-center justify-center p-1 mx-0.5 rounded-sm hover:text-white group',
  tagRemoveIcon:
    'bg-multiselect-remove bg-center bg-no-repeat opacity-30 inline-block w-3 h-3 group-hover:opacity-60',
  tagsSearchWrapper:
    'inline-block relative mx-1 mb-1 flex-grow flex-shrink h-full',
  tagsSearch:
    'absolute inset-0 border-0 outline-none focus:ring-0 appearance-none p-0 text-base font-sans box-border w-full',
  tagsSearchCopy: 'invisible whitespace-pre-wrap inline-block h-px',
  placeholder:
    'flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5 text-sm',
  caret:
    'bg-multiselect-caret bg-center bg-no-repeat w-2.5 h-4 py-px box-content mr-3.5 relative z-10 opacity-40 flex-shrink-0 flex-grow-0 transition-transform transform pointer-events-none',
  caretOpen: 'rotate-180 pointer-events-auto',
  clear:
    'pr-3.5 relative z-10 opacity-40 transition duration-300 flex-shrink-0 flex-grow-0 flex hover:opacity-80',
  clearIcon:
    'bg-multiselect-remove bg-center bg-no-repeat w-2.5 h-4 py-px box-content inline-block',
  spinner:
    'bg-multiselect-spinner bg-center bg-no-repeat w-4 h-4 z-10 mr-3.5 animate-spin flex-shrink-0 flex-grow-0',
  dropdown:
    'max-h-60 absolute -left-px -right-px bottom-0 transform translate-y-full border border-gray-300 -mt-px overflow-y-scroll z-50 bg-white flex flex-col rounded-b',
  dropdownTop:
    '-translate-y-full top-px bottom-auto flex-col-reverse rounded-b-none rounded-t',
  dropdownHidden: 'hidden',
  options: 'flex flex-col p-0 m-0 list-none',
  optionsTop: 'flex-col-reverse',
  group: 'p-0 m-0',
  groupLabel:
    'flex text-sm box-border items-center justify-start text-left py-1 px-3 font-semibold bg-gray-200 cursor-default leading-normal',
  groupLabelPointable: 'cursor-pointer',
  groupLabelPointed: 'bg-gray-300 text-gray-700',
  groupLabelSelected: 'bg-green-600 text-white',
  groupLabelDisabled: 'bg-gray-100 text-gray-300 cursor-not-allowed',
  groupLabelSelectedPointed: 'bg-green-600 text-white opacity-90',
  groupLabelSelectedDisabled:
    'text-green-100 bg-green-600 bg-opacity-50 cursor-not-allowed',
  groupOptions: 'p-0 m-0',
  option:
    'flex items-center justify-start box-border text-left cursor-pointer text-base leading-snug py-2 px-3',
  optionPointed: 'text-gray-800 bg-gray-100',
  optionSelected: 'text-white bg-green-500',
  optionDisabled: 'text-gray-300 cursor-not-allowed',
  optionSelectedPointed: 'text-white bg-green-500 opacity-90',
  optionSelectedDisabled:
    'text-green-100 bg-green-500 bg-opacity-50 cursor-not-allowed',
  noOptions: 'py-2 px-3 text-gray-600 bg-white text-left',
  noResults: 'py-2 px-3 text-gray-600 bg-white text-left',
  fakeInput:
    'bg-transparent absolute left-0 right-0 -bottom-px w-full h-px border-0 p-0 appearance-none outline-none text-transparent',
  spacer: 'h-9 py-px box-content',
}
</script>

<template>
  <div class="relative">
    <i
      v-if="!searching"
      class="fa-solid fa-search z-40"
      aria-hidden="true"
      style="
        position: absolute;
        right: 10px;
        top: 10px;
        font-size: 1.3em;
        color: #888888;
      "
    />
    <i
      v-if="searching"
      class="fa-solid fa-spinner fa-spin z-40"
      style="position: absolute; right: 10px; top: 10px; font-size: 1.3em"
    />
  </div>
  <Multiselect
    :searchable="true"
    :classes="classes"
    :loading="false"
    :caret="false"
    placeholder="Search Everything..."
    track-by="id"
    :resolve-on-load="false"
    :filter-results="false"
    :can-clear="false"
    :min-chars="2"
    :group-hide-empty="true"
    :delay="500"
    :object="true"
    :groups="true"
    :options="search"
    class="multiselect-autocomplete form-multiselect z-10 h-10"
    @select="handleSelect"
  >
    <template #option="{ option: suggestion }">
      <template v-if="suggestion && suggestion.type === 'inspection'">
        <img class="avatar" :src="suggestion.image_url" />
        <div>
          <div>{{ suggestion.full_address }}</div>
          <div style="color: #9ca3af; font-size: 0.9em; margin-top: 5px">
            {{ formatDate(suggestion.datetime) }}
          </div>
        </div>
      </template>
      <template v-if="suggestion && suggestion.type === 'connection'">
        <img class="avatar" :src="suggestion.image" />
        <div>
          <div>
            {{
              [suggestion.last_name, suggestion.first_name]
                .filter((a) => a)
                .join(', ')
            }}
          </div>
          <div v-if="suggestion.agency_name" class="connection-agency">
            {{ suggestion.agency_name }}
          </div>
          <div class="connection-email-phone">
            {{ suggestion.email }} | {{ suggestion.phone }}
          </div>
        </div>
      </template>
    </template>
  </Multiselect>
</template>

<style scoped lang="scss">
.avatar {
  display: flex;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 15px;
}

.multiselect-autocomplete {
  font-family: 'Open Sans', sans-serif;
  width: 400px;
  border: 1px solid #888888;
  --ms-group-label-bg: whiteSmoke;
  --ms-option-bg-selected: #ddd;
  --ms-option-bg-pointed: #ddd;
  --ms-option-bg-selected-pointed: #ddd;
  --ms-spinner-color: #fff;
  --ms-group-label-py: 10px;
  --ms-group-label-px: 15px;

  .multiselect-dropdown {
    overflow: scroll;
    overflow-x: hidden;
    max-height: 70vh;
  }

  &.is-active {
    box-shadow: none;
  }
}
</style>
