// src/useAnalytics.ts
import productAnalyticsService from "@spectora/product-analytics-service";
var setGtmDataLayer = (params = {}) => {
  const { user } = params;
  if (params.gtmParams) {
    window.dataLayer = [params.gtmParams];
    return;
  }
  window.dataLayer = [
    {
      userID: user == null ? void 0 : user.id,
      loggedIn: !params.isPublicRoute,
      profileType: user == null ? void 0 : user.profile_type
    }
  ];
};
var initGtm = (params = {}) => {
  if (typeof window.google_tag_manager !== "undefined") {
    return;
  }
  const gtmIdKey = params.isPublicRoute ? "VITE_PUBLIC_GTM_ID" : "VITE_GTM_ID";
  const gtmId = import.meta.env[gtmIdKey];
  if (!gtmId) {
    console.log(`[INFO] No ${gtmIdKey} found. Ignoring GTM`);
    return;
  }
  setGtmDataLayer(params);
  (function(w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
    const f = d.getElementsByTagName(s)[0];
    const j = d.createElement(s);
    const dl = l != "dataLayer" ? "&l=" + l : "";
    j.async = true;
    j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
    f.parentNode.insertBefore(j, f);
  })(window, document, "script", "dataLayer", gtmId);
};
var initHeap = (params = {}) => {
  const { user } = params;
  const appId = import.meta.env.VITE_PRODUCT_ANALYTICS_APP_ID;
  appId && productAnalyticsService.init({ appId });
  (user == null ? void 0 : user.id) && productAnalyticsService.identify({ id: user.id });
};
var initIntercom = (params = {}) => {
  if (typeof window.Intercom !== "undefined") {
    return;
  }
  const appId = import.meta.env.VITE_APP_INTERCOM_APP_ID;
  const user = params.user;
  (function() {
    const w = window;
    const ic = w.Intercom;
    if (typeof ic === "function") {
      ic("reattach_activator");
      ic("update", w.intercomSettings);
    } else {
      const d = document;
      const i = function(...rest) {
        i.c(rest);
      };
      i.q = [];
      i.c = function(args) {
        i.q.push(args);
      };
      w.Intercom = i;
      const l = function() {
        const s = d.createElement("script");
        s.type = "text/javascript";
        s.async = true;
        s.src = `https://widget.intercom.io/widget/${appId}`;
        const x = d.getElementsByTagName("script")[0];
        x.parentNode.insertBefore(s, x);
      };
      if (document.readyState === "complete") {
        l();
      } else if (w.attachEvent) {
        w.attachEvent("onload", l);
      } else {
        w.addEventListener("load", l, false);
      }
    }
  })();
  const intercomSettings = {
    api_base: "https://api-iam.intercom.io",
    app_id: appId,
    user_id: user.id,
    name: user.name,
    email: user.email,
    user_hash: ""
  };
  if (user.intercom_hash) {
    intercomSettings.user_hash = user.intercom_hash;
  }
  window.Intercom("boot", intercomSettings);
};
var initHubspotTracking = () => {
  if (typeof window._hsq !== "undefined") {
    return;
  }
  const hs_code = import.meta.env.VITE_HS_TRACKING_CODE;
  if (!hs_code) {
    console.log(`[INFO] No VITE_HS_TRACKING_CODE found. Ignoring HS tracking`);
    return;
  }
  const script = document.createElement("script");
  script.type = "text/javascript";
  script.id = "hs-script-loader";
  script.src = `//js.hs-scripts.com/${hs_code}.js`;
  script.async = true;
  script.defer = true;
  document.body.appendChild(script);
};
var useAnalytics_default = () => {
  const setup = (attributes = {}) => {
    try {
      initGtm(attributes);
      initHeap(attributes);
      initIntercom(attributes);
    } catch (err) {
      console.error("Analytics Setup", err);
    }
  };
  const intercomEvents = {
    massEmailCreated: "created-mass-email"
  };
  const startIntercomChat = (initialContent) => {
    window.Intercom && window.Intercom("showNewMessage", initialContent);
  };
  const trackIntercomEvent = (eventName) => {
    if (!eventName)
      return;
    window.Intercom && window.Intercom("trackEvent", eventName);
  };
  const hubspotPageTrack = (path) => {
    if (typeof window._hsq === "undefined") {
      console.log(`[INFO] No HS tracking. Ignoring page track`);
    }
    const _hsq = window._hsq = window._hsq || [];
    _hsq.push(["setPath", path]);
    _hsq.push(["trackPageView"]);
  };
  return {
    setup,
    intercomEvents,
    startIntercomChat,
    trackIntercomEvent,
    initHubspotTracking,
    hubspotPageTrack
  };
};

// src/useFetch.ts
import { ref } from "vue";
var useFetch = (options) => {
  const data = ref({});
  const meta = ref({});
  const loading = ref(false);
  const error2 = ref(null);
  const status = ref(0);
  const params = ref(options.params);
  const prepareData = (data2) => {
    if (options.prepareData) {
      return options.prepareData(data2);
    }
    return data2;
  };
  const fetchData = async () => {
    loading.value = true;
    error2.value = null;
    try {
      const response = await options.fetcher({ params: options.params });
      data.value = prepareData(response.data);
      if (response.meta) {
        meta.value = response.meta;
      }
    } catch (err) {
      error2.value = err;
    } finally {
      loading.value = false;
    }
  };
  if (options.immediate) {
    fetchData();
  }
  return {
    data,
    error: error2,
    fetchData,
    params,
    loading,
    meta,
    status
  };
};
var useFetch_default = useFetch;

// src/useFetchAll.ts
import { dig } from "@spectora/utils";
import { computed, ref as ref2 } from "vue";
var useFetchAll = (options) => {
  const data = ref2([]);
  const defaultParams = {
    sort: "",
    fields: {},
    filter: {},
    include: [],
    page: { size: 50, number: 1 },
    ...options.params
  };
  const immediate = Object.prototype.hasOwnProperty.call(options, "immediate") ? options.immediate : true;
  const prepareData = options.prepareData ? options.prepareData : (data2) => data2 || [];
  const prepareParams = options.prepareParams ? options.prepareParams : (params2) => params2 || {};
  const total = ref2(0);
  const loading = ref2(false);
  const params = ref2({
    ...options.params,
    sort: defaultParams.sort,
    filter: defaultParams.filter,
    include: defaultParams.include || null,
    fields: defaultParams.fields || null,
    page: {
      size: defaultParams.page.size || 50,
      number: 1
    }
  });
  const setSort = (sort) => {
    params.value.sort = sort;
  };
  const setPageNumber = (number) => {
    params.value.page.number = number;
  };
  const fetchData = async () => {
    loading.value = true;
    const fetchParams = prepareParams(params.value);
    const response = await options.fetcher({ params: fetchParams });
    total.value = dig(response, "meta.pagination.records", 0);
    data.value = prepareData(response.data);
    loading.value = false;
  };
  const pagination = computed(() => ({
    current: params.value.page.number,
    size: params.value.page.size,
    records: total.value
  }));
  if (immediate) {
    fetchData();
  }
  return {
    data,
    fetchData,
    loading,
    params,
    setSort,
    setPageNumber,
    total,
    pagination
  };
};
var useFetchAll_default = useFetchAll;

// src/useFileUpload.ts
import { computed as computed2 } from "vue";
var useFileUpload = ({ data, fileName = "image" }) => {
  const file = computed2(() => data == null ? void 0 : data.value[fileName]);
  const handleFileChange = ({ file: file2 }) => {
    data.value[fileName] = file2;
  };
  return { file, handleFileChange };
};
var useFileUpload_default = useFileUpload;

// ../../node_modules/@formkit/utils/dist/index.mjs
var explicitKeys = [
  "__key",
  "__init",
  "__shim",
  "__original",
  "__index",
  "__prevKey"
];
function token() {
  return Math.random().toString(36).substring(2, 15);
}
function eq(valA, valB, deep = true, explicit = ["__key"]) {
  if (valA === valB)
    return true;
  if (typeof valB === "object" && typeof valA === "object") {
    if (valA instanceof Map)
      return false;
    if (valA instanceof Set)
      return false;
    if (valA instanceof Date)
      return false;
    if (valA === null || valB === null)
      return false;
    if (Object.keys(valA).length !== Object.keys(valB).length)
      return false;
    for (const k of explicit) {
      if ((k in valA || k in valB) && valA[k] !== valB[k])
        return false;
    }
    for (const key in valA) {
      if (!(key in valB))
        return false;
      if (valA[key] !== valB[key] && !deep)
        return false;
      if (deep && !eq(valA[key], valB[key], deep, explicit))
        return false;
    }
    return true;
  }
  return false;
}
function camel(str) {
  return str.replace(/-([a-z0-9])/gi, (_s, g) => g.toUpperCase());
}
function kebab(str) {
  return str.replace(/([a-z0-9])([A-Z])/g, (_s, trail, cap) => trail + "-" + cap.toLowerCase()).replace(" ", "-").toLowerCase();
}
function clone(obj, explicit = explicitKeys) {
  if (obj === null || obj instanceof RegExp || obj instanceof Date || obj instanceof Map || obj instanceof Set || typeof File === "function" && obj instanceof File)
    return obj;
  let returnObject;
  if (Array.isArray(obj)) {
    returnObject = obj.map((value) => {
      if (typeof value === "object")
        return clone(value, explicit);
      return value;
    });
  } else {
    returnObject = Object.keys(obj).reduce((newObj, key) => {
      newObj[key] = typeof obj[key] === "object" ? clone(obj[key], explicit) : obj[key];
      return newObj;
    }, {});
  }
  for (const key of explicit) {
    if (key in obj) {
      Object.defineProperty(returnObject, key, {
        enumerable: false,
        value: obj[key]
      });
    }
  }
  return returnObject;
}
function cloneAny(obj) {
  return typeof obj === "object" ? clone(obj) : obj;
}
function undefine(value) {
  return value !== void 0 && value !== "false" && value !== false ? true : void 0;
}
function init(obj) {
  return !Object.isFrozen(obj) ? Object.defineProperty(obj, "__init", {
    enumerable: false,
    value: true
  }) : obj;
}
function slugify(str) {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().replace(/[^a-z0-9]/g, " ").trim().replace(/\s+/g, "-");
}

// ../../node_modules/@formkit/core/dist/index.mjs
function createDispatcher() {
  const middleware = [];
  let currentIndex = 0;
  const use2 = (dispatchable) => middleware.push(dispatchable);
  const dispatch = (payload) => {
    const current = middleware[currentIndex];
    if (typeof current === "function") {
      return current(payload, (explicitPayload) => {
        currentIndex++;
        return dispatch(explicitPayload === void 0 ? payload : explicitPayload);
      });
    }
    currentIndex = 0;
    return payload;
  };
  use2.dispatch = dispatch;
  use2.unshift = (dispatchable) => middleware.unshift(dispatchable);
  use2.remove = (dispatchable) => {
    const index = middleware.indexOf(dispatchable);
    if (index > -1)
      middleware.splice(index, 1);
  };
  return use2;
}
function createEmitter() {
  const listeners = /* @__PURE__ */ new Map();
  const receipts = /* @__PURE__ */ new Map();
  let buffer = void 0;
  const emitter = (node, event) => {
    if (buffer) {
      buffer.set(event.name, [node, event]);
      return;
    }
    if (listeners.has(event.name)) {
      listeners.get(event.name).forEach((wrapper) => {
        if (event.origin === node || wrapper.modifiers.includes("deep")) {
          wrapper.listener(event);
        }
      });
    }
    if (event.bubble) {
      node.bubble(event);
    }
  };
  emitter.on = (eventName, listener) => {
    const [event, ...modifiers] = eventName.split(".");
    const receipt = listener.receipt || token();
    const wrapper = {
      modifiers,
      event,
      listener,
      receipt
    };
    listeners.has(event) ? listeners.get(event).push(wrapper) : listeners.set(event, [wrapper]);
    receipts.has(receipt) ? receipts.get(receipt).push(event) : receipts.set(receipt, [event]);
    return receipt;
  };
  emitter.off = (receipt) => {
    var _a;
    if (receipts.has(receipt)) {
      (_a = receipts.get(receipt)) === null || _a === void 0 ? void 0 : _a.forEach((event) => {
        const eventListeners = listeners.get(event);
        if (Array.isArray(eventListeners)) {
          listeners.set(event, eventListeners.filter((wrapper) => wrapper.receipt !== receipt));
        }
      });
      receipts.delete(receipt);
    }
  };
  emitter.pause = (node) => {
    if (!buffer)
      buffer = /* @__PURE__ */ new Map();
    if (node) {
      node.walk((child) => child._e.pause());
    }
  };
  emitter.play = (node) => {
    if (!buffer)
      return;
    const events = buffer;
    buffer = void 0;
    events.forEach(([node2, event]) => emitter(node2, event));
    if (node) {
      node.walk((child) => child._e.play());
    }
  };
  return emitter;
}
function emit$1(node, context, name, payload, bubble2 = true) {
  context._e(node, {
    payload,
    name,
    bubble: bubble2,
    origin: node
  });
  return node;
}
function bubble(node, _context, event) {
  if (isNode(node.parent)) {
    node.parent._e(node.parent, event);
  }
  return node;
}
function on(_node, context, name, listener) {
  return context._e.on(name, listener);
}
function off(node, context, receipt) {
  context._e.off(receipt);
  return node;
}
var errorHandler = createDispatcher();
errorHandler((error2, next) => {
  if (!error2.message)
    error2.message = String(`E${error2.code}`);
  return next(error2);
});
var warningHandler = createDispatcher();
warningHandler((warning, next) => {
  if (!warning.message)
    warning.message = String(`W${warning.code}`);
  const result = next(warning);
  if (console && typeof console.warn === "function")
    console.warn(result.message);
  return result;
});
function warn(code, data = {}) {
  warningHandler.dispatch({ code, data });
}
function error(code, data = {}) {
  throw Error(errorHandler.dispatch({ code, data }).message);
}
function createMessage(conf, node) {
  const m = {
    blocking: false,
    key: token(),
    meta: {},
    type: "state",
    visible: true,
    ...conf
  };
  if (node && m.value && m.meta.localize !== false) {
    m.value = node.t(m);
    m.meta.locale = node.config.locale;
  }
  return m;
}
function createMessages(node, ...errors) {
  const sourceKey = `${node.name}-set`;
  const make = (error2) => createMessage({
    key: slugify(error2),
    type: "error",
    value: error2,
    meta: { source: sourceKey, autoClear: true }
  });
  return errors.filter((m) => !!m).map((errorSet) => {
    if (typeof errorSet === "string")
      errorSet = [errorSet];
    if (Array.isArray(errorSet)) {
      return errorSet.map((error2) => make(error2));
    } else {
      const errors2 = {};
      for (const key in errorSet) {
        if (Array.isArray(errorSet[key])) {
          errors2[key] = errorSet[key].map((error2) => make(error2));
        } else {
          errors2[key] = [make(errorSet[key])];
        }
      }
      return errors2;
    }
  });
}
var registry = /* @__PURE__ */ new Map();
var reflected = /* @__PURE__ */ new Map();
var emit = createEmitter();
function deregister(node) {
  if (reflected.has(node)) {
    const id = reflected.get(node);
    reflected.delete(node);
    registry.delete(id);
    emit(node, {
      payload: null,
      name: id,
      bubble: false,
      origin: node
    });
  }
}
function getNode$1(id) {
  return registry.get(id);
}
function configChange(node, prop, value) {
  let usingFallback = true;
  !(prop in node.config._t) ? node.emit(`config:${prop}`, value, false) : usingFallback = false;
  if (!(prop in node.props)) {
    node.emit("prop", { prop, value });
    node.emit(`prop:${prop}`, value);
  }
  return usingFallback;
}
function submitForm(id) {
  const formElement = document.getElementById(id);
  if (formElement instanceof HTMLFormElement) {
    const event = new Event("submit", { cancelable: true, bubbles: true });
    formElement.dispatchEvent(event);
    return;
  }
  warn(151, id);
}
function clearState(node) {
  const clear = (n) => {
    for (const key in n.store) {
      const message = n.store[key];
      if (message.type === "error" || message.type === "ui" && key === "incomplete") {
        n.store.remove(key);
      } else if (message.type === "state") {
        n.store.set({ ...message, value: false });
      }
    }
  };
  clear(node);
  node.walk(clear);
}
function reset(id, resetTo) {
  const node = typeof id === "string" ? getNode$1(id) : id;
  if (node) {
    const initial = (n) => cloneAny(n.props.initial) || (n.type === "group" ? {} : n.type === "list" ? [] : void 0);
    node._e.pause(node);
    node.input(cloneAny(resetTo) || initial(node), false);
    node.walk((child) => child.input(initial(child), false));
    const finalInit = initial(node);
    node.input(typeof finalInit === "object" ? cloneAny(resetTo) || init(finalInit) : finalInit, false);
    node._e.play(node);
    clearState(node);
    node.emit("reset", node);
    return node;
  }
  warn(152, id);
  return;
}
var defaultConfig = {
  delimiter: ".",
  delay: 0,
  locale: "en",
  rootClasses: (key) => ({ [`formkit-${kebab(key)}`]: true })
};
var useIndex = Symbol("index");
var valueRemoved = Symbol("removed");
var valueMoved = Symbol("moved");
var valueInserted = Symbol("inserted");
function isList(arg) {
  return arg.type === "list" && Array.isArray(arg._value);
}
function isNode(node) {
  return node && typeof node === "object" && node.__FKNode__ === true;
}
var invalidSetter = (node, _context, property) => {
  error(102, [node, property]);
};
var traps = {
  _c: trap(getContext, invalidSetter, false),
  add: trap(addChild),
  addProps: trap(addProps),
  address: trap(getAddress, invalidSetter, false),
  at: trap(getNode),
  bubble: trap(bubble),
  clearErrors: trap(clearErrors$1),
  calm: trap(calm),
  config: trap(false),
  define: trap(define),
  disturb: trap(disturb),
  destroy: trap(destroy),
  hydrate: trap(hydrate),
  index: trap(getIndex, setIndex, false),
  input: trap(input),
  each: trap(eachChild),
  emit: trap(emit$1),
  find: trap(find),
  on: trap(on),
  off: trap(off),
  parent: trap(false, setParent),
  plugins: trap(false),
  remove: trap(removeChild),
  root: trap(getRoot, invalidSetter, false),
  reset: trap(resetValue),
  resetConfig: trap(resetConfig),
  setErrors: trap(setErrors$1),
  submit: trap(submit),
  t: trap(text),
  use: trap(use),
  name: trap(getName, false, false),
  walk: trap(walkTree)
};
function trap(getter, setter, curryGetter = true) {
  return {
    get: getter ? (node, context) => curryGetter ? (...args) => getter(node, context, ...args) : getter(node, context) : false,
    set: setter !== void 0 ? setter : invalidSetter.bind(null)
  };
}
function createValue(options) {
  if (options.type === "group") {
    return init(options.value && typeof options.value === "object" && !Array.isArray(options.value) ? options.value : {});
  } else if (options.type === "list") {
    return init(Array.isArray(options.value) ? options.value : []);
  }
  return options.value;
}
function input(node, context, value, async = true) {
  context._value = validateInput(node, node.hook.input.dispatch(value));
  node.emit("input", context._value);
  if (context.isSettled)
    node.disturb();
  if (async) {
    if (context._tmo)
      clearTimeout(context._tmo);
    context._tmo = setTimeout(commit, node.props.delay, node, context);
  } else {
    commit(node, context);
  }
  return context.settled;
}
function validateInput(node, value) {
  switch (node.type) {
    case "input":
      break;
    case "group":
      if (!value || typeof value !== "object")
        error(107, [node, value]);
      break;
    case "list":
      if (!Array.isArray(value))
        error(108, [node, value]);
      break;
  }
  return value;
}
function commit(node, context, calm2 = true, hydrate2 = true) {
  context._value = context.value = node.hook.commit.dispatch(context._value);
  if (node.type !== "input" && hydrate2)
    node.hydrate();
  node.emit("commit", context.value);
  if (calm2)
    node.calm();
}
function partial(context, { name, value, from }) {
  if (Object.isFrozen(context._value))
    return;
  if (isList(context)) {
    const insert = value === valueRemoved ? [] : value === valueMoved && typeof from === "number" ? context._value.splice(from, 1) : [value];
    context._value.splice(name, value === valueMoved || from === valueInserted ? 0 : 1, ...insert);
    return;
  }
  if (value !== valueRemoved) {
    context._value[name] = value;
  } else {
    delete context._value[name];
  }
}
function hydrate(node, context) {
  const _value = context._value;
  context.children.forEach((child) => {
    if (typeof _value !== "object")
      return;
    if (child.name in _value) {
      const childValue = child.type !== "input" || _value[child.name] && typeof _value[child.name] === "object" ? init(_value[child.name]) : _value[child.name];
      child.input(childValue, false);
    } else {
      if (node.type !== "list" || typeof child.name === "number") {
        partial(context, { name: child.name, value: child.value });
      }
      if (!_value.__init) {
        if (child.type === "group")
          child.input({}, false);
        else if (child.type === "list")
          child.input([], false);
        else
          child.input(void 0, false);
      }
    }
  });
  return node;
}
function disturb(node, context) {
  var _a;
  if (context._d <= 0) {
    context.isSettled = false;
    node.emit("settled", false, false);
    context.settled = new Promise((resolve) => {
      context._resolve = resolve;
    });
    if (node.parent)
      (_a = node.parent) === null || _a === void 0 ? void 0 : _a.disturb();
  }
  context._d++;
  return node;
}
function calm(node, context, value) {
  var _a;
  if (value !== void 0 && node.type !== "input") {
    partial(context, value);
    return commit(node, context, true, false);
  }
  if (context._d > 0)
    context._d--;
  if (context._d === 0) {
    context.isSettled = true;
    node.emit("settled", true, false);
    if (node.parent)
      (_a = node.parent) === null || _a === void 0 ? void 0 : _a.calm({ name: node.name, value: context.value });
    if (context._resolve)
      context._resolve(context.value);
  }
}
function destroy(node, context) {
  node.emit("destroying", node);
  node.store.filter(() => false);
  if (node.parent) {
    node.parent.remove(node);
  }
  deregister(node);
  context._value = context.value = void 0;
  node.emit("destroyed", node);
}
function define(node, context, definition) {
  context.type = definition.type;
  context.props.definition = clone(definition);
  context.value = context._value = createValue({
    type: node.type,
    value: context.value
  });
  if (definition.forceTypeProp) {
    if (node.props.type)
      node.props.originalType = node.props.type;
    context.props.type = definition.forceTypeProp;
  }
  if (definition.family) {
    context.props.family = definition.family;
  }
  if (definition.features) {
    definition.features.forEach((feature) => feature(node));
  }
  if (definition.props) {
    node.addProps(definition.props);
  }
  node.emit("defined", definition);
}
function addProps(node, context, props) {
  var _a;
  if (node.props.attrs) {
    const attrs = { ...node.props.attrs };
    node.props._emit = false;
    for (const attr in attrs) {
      const camelName = camel(attr);
      if (props.includes(camelName)) {
        node.props[camelName] = attrs[attr];
        delete attrs[attr];
      }
    }
    const initial = cloneAny(context._value);
    node.props.initial = node.type !== "input" ? init(initial) : initial;
    node.props._emit = true;
    node.props.attrs = attrs;
    if (node.props.definition) {
      node.props.definition.props = [
        ...((_a = node.props.definition) === null || _a === void 0 ? void 0 : _a.props) || [],
        ...props
      ];
    }
  }
  node.emit("added-props", props);
  return node;
}
function addChild(parent, parentContext, child, listIndex) {
  if (parent.type === "input")
    error(100, parent);
  if (child.parent && child.parent !== parent) {
    child.parent.remove(child);
  }
  if (!parentContext.children.includes(child)) {
    if (listIndex !== void 0 && parent.type === "list") {
      parentContext.children.splice(listIndex, 0, child);
      if (Array.isArray(parent.value) && parent.value.length < parentContext.children.length) {
        parent.disturb().calm({
          name: listIndex,
          value: child.value,
          from: valueInserted
        });
      }
    } else {
      parentContext.children.push(child);
    }
    if (!child.isSettled)
      parent.disturb();
  }
  if (child.parent !== parent) {
    child.parent = parent;
    if (child.parent !== parent) {
      parent.remove(child);
      child.parent.add(child);
      return parent;
    }
  } else {
    child.use(parent.plugins);
  }
  commit(parent, parentContext, false);
  parent.ledger.merge(child);
  parent.emit("child", child);
  return parent;
}
function setParent(child, context, _property, parent) {
  if (isNode(parent)) {
    if (child.parent && child.parent !== parent) {
      child.parent.remove(child);
    }
    context.parent = parent;
    child.resetConfig();
    !parent.children.includes(child) ? parent.add(child) : child.use(parent.plugins);
    return true;
  }
  if (parent === null) {
    context.parent = null;
    return true;
  }
  return false;
}
function removeChild(node, context, child) {
  const childIndex = context.children.indexOf(child);
  if (childIndex !== -1) {
    if (child.isSettled)
      node.disturb();
    context.children.splice(childIndex, 1);
    let preserve = undefine(child.props.preserve);
    let parent = child.parent;
    while (preserve === void 0 && parent) {
      preserve = undefine(parent.props.preserve);
      parent = parent.parent;
    }
    if (!preserve) {
      node.calm({
        name: node.type === "list" ? childIndex : child.name,
        value: valueRemoved
      });
    } else {
      node.calm();
    }
    child.parent = null;
    child.config._rmn = child;
  }
  node.ledger.unmerge(child);
  return node;
}
function eachChild(_node, context, callback) {
  context.children.forEach((child) => callback(child));
}
function walkTree(_node, context, callback, stopIfFalse = false) {
  context.children.forEach((child) => {
    if (callback(child) !== false || !stopIfFalse) {
      child.walk(callback);
    }
  });
}
function resetConfig(node, context) {
  const parent = node.parent || void 0;
  context.config = createConfig(node.config._t, parent);
  node.walk((n) => n.resetConfig());
}
function use(node, context, plugin, run = true, library = true) {
  if (Array.isArray(plugin) || plugin instanceof Set) {
    plugin.forEach((p) => use(node, context, p));
    return node;
  }
  if (!context.plugins.has(plugin)) {
    if (library && typeof plugin.library === "function")
      plugin.library(node);
    if (run && plugin(node) !== false) {
      context.plugins.add(plugin);
      node.children.forEach((child) => child.use(plugin));
    }
  }
  return node;
}
function setIndex(node, _context, _property, setIndex2) {
  if (isNode(node.parent)) {
    const children = node.parent.children;
    const index = setIndex2 >= children.length ? children.length - 1 : setIndex2 < 0 ? 0 : setIndex2;
    const oldIndex = children.indexOf(node);
    if (oldIndex === -1)
      return false;
    children.splice(oldIndex, 1);
    children.splice(index, 0, node);
    node.parent.children = children;
    if (node.parent.type === "list")
      node.parent.disturb().calm({ name: index, value: valueMoved, from: oldIndex });
    return true;
  }
  return false;
}
function getIndex(node) {
  if (node.parent) {
    const index = [...node.parent.children].indexOf(node);
    return index === -1 ? node.parent.children.length : index;
  }
  return -1;
}
function getContext(_node, context) {
  return context;
}
function getName(node, context) {
  var _a;
  if (((_a = node.parent) === null || _a === void 0 ? void 0 : _a.type) === "list")
    return node.index;
  return context.name !== useIndex ? context.name : node.index;
}
function getAddress(node, context) {
  return context.parent ? context.parent.address.concat([node.name]) : [node.name];
}
function getNode(node, _context, locator) {
  const address = typeof locator === "string" ? locator.split(node.config.delimiter) : locator;
  if (!address.length)
    return void 0;
  const first = address[0];
  let pointer = node.parent;
  if (!pointer) {
    if (String(address[0]) === String(node.name))
      address.shift();
    pointer = node;
  }
  if (first === "$parent")
    address.shift();
  while (pointer && address.length) {
    const name = address.shift();
    switch (name) {
      case "$root":
        pointer = node.root;
        break;
      case "$parent":
        pointer = pointer.parent;
        break;
      case "$self":
        pointer = node;
        break;
      default:
        pointer = pointer.children.find((c) => String(c.name) === String(name)) || select(pointer, name);
    }
  }
  return pointer || void 0;
}
function select(node, selector) {
  const matches = String(selector).match(/^(find)\((.*)\)$/);
  if (matches) {
    const [, action, argStr] = matches;
    const args = argStr.split(",").map((arg) => arg.trim());
    switch (action) {
      case "find":
        return node.find(args[0], args[1]);
      default:
        return void 0;
    }
  }
  return void 0;
}
function find(node, _context, searchTerm, searcher) {
  return bfs(node, searchTerm, searcher);
}
function bfs(tree, searchValue, searchGoal = "name") {
  const search = typeof searchGoal === "string" ? (n) => n[searchGoal] == searchValue : searchGoal;
  const stack = [tree];
  while (stack.length) {
    const node = stack.shift();
    if (search(node, searchValue))
      return node;
    stack.push(...node.children);
  }
  return void 0;
}
function getRoot(n) {
  let node = n;
  while (node.parent) {
    node = node.parent;
  }
  return node;
}
function createConfig(target = {}, parent) {
  let node = void 0;
  return new Proxy(target, {
    get(...args) {
      const prop = args[1];
      if (prop === "_t")
        return target;
      const localValue = Reflect.get(...args);
      if (localValue !== void 0)
        return localValue;
      if (parent) {
        const parentVal = parent.config[prop];
        if (parentVal !== void 0)
          return parentVal;
      }
      if (target.rootConfig && typeof prop === "string") {
        const rootValue = target.rootConfig[prop];
        if (rootValue !== void 0)
          return rootValue;
      }
      if (prop === "delay" && (node === null || node === void 0 ? void 0 : node.type) === "input")
        return 20;
      return defaultConfig[prop];
    },
    set(...args) {
      const prop = args[1];
      const value = args[2];
      if (prop === "_n") {
        node = value;
        if (target.rootConfig)
          target.rootConfig._add(node);
        return true;
      }
      if (prop === "_rmn") {
        if (target.rootConfig)
          target.rootConfig._rm(node);
        node = void 0;
        return true;
      }
      if (!eq(target[prop], value, false)) {
        const didSet = Reflect.set(...args);
        if (node) {
          node.emit(`config:${prop}`, value, false);
          configChange(node, prop, value);
          node.walk((n) => configChange(n, prop, value), true);
        }
        return didSet;
      }
      return true;
    }
  });
}
function text(node, _context, key, type = "ui") {
  const fragment = typeof key === "string" ? { key, value: key, type } : key;
  const value = node.hook.text.dispatch(fragment);
  node.emit("text", value, false);
  return value.value;
}
function submit(node) {
  const name = node.name;
  do {
    if (node.props.isForm === true)
      break;
    if (!node.parent)
      error(106, name);
    node = node.parent;
  } while (node);
  if (node.props.id) {
    submitForm(node.props.id);
  }
}
function resetValue(node, _context, value) {
  return reset(node, value);
}
function setErrors$1(node, _context, localErrors, childErrors) {
  const sourceKey = `${node.name}-set`;
  const errors = node.hook.setErrors.dispatch({ localErrors, childErrors });
  createMessages(node, errors.localErrors, errors.childErrors).forEach((errors2) => {
    node.store.apply(errors2, (message) => message.meta.source === sourceKey);
  });
  return node;
}
function clearErrors$1(node, context, clearChildErrors = true, sourceKey) {
  setErrors$1(node, context, []);
  if (clearChildErrors) {
    sourceKey = sourceKey || `${node.name}-set`;
    node.walk((child) => {
      child.store.filter((message) => {
        return !(message.type === "error" && message.meta && message.meta.source === sourceKey);
      });
    });
  }
  return node;
}

// src/useForm.ts
import { parseAPIError } from "@spectora/utils";
import { computed as computed3, ref as ref3, watch } from "vue";

// src/useNotifier.ts
import { baseToastNotify } from "@spectora/ui";
var defaultTimeout = 4e3;
var useNotifier = () => {
  const notifySuccess = ({
    title = "Success",
    text: text2,
    timeout
  }) => {
    baseToastNotify({ group: "app", title, text: text2 }, timeout || defaultTimeout);
  };
  const notifyError = ({
    title = "Error",
    text: text2,
    timeout
  }) => {
    baseToastNotify({ group: "error", title, text: text2 }, timeout || defaultTimeout);
  };
  const notifyWarning = ({
    title = "Warning",
    text: text2,
    timeout
  }) => {
    baseToastNotify(
      { group: "warning", title, text: text2 },
      timeout || defaultTimeout
    );
  };
  return { notifyError, notifySuccess, notifyWarning };
};
var useNotifier_default = useNotifier;

// src/useForm.ts
var useForm = (options = {}) => {
  const {
    api,
    apiOptions,
    handleCreate,
    handleUpdate,
    model,
    onCreateSuccess,
    onSubmit,
    onSubmitSuccess,
    onUpdateSuccess,
    prepareFormData,
    resetOnSubmit
  } = options;
  const initialValues = options.initialValues.value ? options.initialValues : ref3(options.initialValues);
  const form = ref3({ ...initialValues.value });
  const notify = Object.prototype.hasOwnProperty.call(options, "notify") ? options.notify : true;
  const submitting = ref3(false);
  const { notifySuccess, notifyError } = useNotifier_default();
  watch(
    () => initialValues,
    (newValue) => {
      form.value = { ...newValue.value };
    },
    { deep: true, immediate: false }
  );
  const create = async (formData) => {
    if (handleCreate) {
      const response2 = await handleCreate(formData);
      if (onCreateSuccess) {
        onCreateSuccess({ formData, response: response2 });
      }
      return response2;
    }
    if (!api) {
      console.warn("[useForm] no api was provided so form cannot be saved");
      return;
    }
    if (!api.post) {
      console.warn(
        "[useForm] provided api does not have a post method, so form create cannot be called."
      );
      return;
    }
    const response = await api.post({
      data: { ...formData },
      ...apiOptions
    });
    if (onCreateSuccess) {
      onCreateSuccess({ formData, response });
    }
    return response;
  };
  const update = async (formData, initialValues2) => {
    if (handleUpdate) {
      const response2 = await handleUpdate(formData, initialValues2);
      if (onUpdateSuccess) {
        onUpdateSuccess({ formData, response: response2 });
      }
      return response2;
    }
    if (!api) {
      console.warn("[useForm] no api was provided so form cannot be saved");
      return;
    }
    if (!api.put) {
      console.warn(
        "[useForm] provided api does not have a put method, so form create cannot be called."
      );
      return;
    }
    const response = await api.put({
      params: { id: formData.id },
      data: { ...formData },
      ...apiOptions
    });
    if (onUpdateSuccess) {
      onUpdateSuccess({ formData, response });
    }
    return response;
  };
  const handleSubmit = async (originalFormData) => {
    if (onSubmit) {
      onSubmit(originalFormData);
    }
    try {
      submitting.value = true;
      const formData = prepareFormData ? prepareFormData(originalFormData) : originalFormData;
      const response = formData.id ? await update(formData, options.initialValues.value) : await create(formData);
      if (notify) {
        const text2 = model ? `Your ${model} was successfully saved.` : `Record was successfully saved.`;
        notifySuccess({ title: "Success", text: text2 });
      }
      submitting.value = false;
      if (resetOnSubmit) {
        reset(formId);
      }
      if (onSubmitSuccess) {
        onSubmitSuccess(response);
      }
      return response;
    } catch (err) {
      console.error(err);
      submitting.value = false;
      if (notify) {
        const text2 = parseAPIError(err);
        if (text2) {
          notifyError({ title: "Error", text: text2 });
        }
      }
    }
  };
  const formId = options.name ? options.name : Math.floor(
    Math.random() * Math.floor(Math.random() * Date.now())
  ).toString();
  const title = computed3(
    () => {
      var _a;
      return ((_a = form.value) == null ? void 0 : _a.id) ? `Edit ${model}` : `Add New ${model}`;
    }
  );
  const editing = computed3(() => {
    var _a;
    return !!((_a = form.value) == null ? void 0 : _a.id);
  });
  return {
    editing,
    form,
    formId,
    handleSubmit,
    reset,
    submitting,
    submitForm,
    title
  };
};
var useForm_default = useForm;

// src/useHead.ts
import { createHead, useHead } from "@unhead/vue";

// src/useLocalStorage.ts
import { useLocalStorage } from "@vueuse/core";

// src/useMediaQuery.ts
import { useMediaQuery } from "@vueuse/core";

// src/useModal.ts
import { ref as ref4 } from "vue";
var useModal = () => {
  const shown = ref4(false);
  const hide = () => {
    shown.value = false;
  };
  const show = () => {
    shown.value = true;
  };
  const toggle = () => {
    shown.value = !!shown.value;
  };
  return {
    hide,
    show,
    shown,
    toggle,
    isOpen: shown,
    open: show,
    close: hide
  };
};
var useModal_default = useModal;

// src/useMounted.ts
import { onMounted, ref as ref5 } from "vue";
var useMounted = () => {
  const isMounted = ref5(false);
  onMounted(() => isMounted.value = true);
  return {
    isMounted
  };
};
var useMounted_default = useMounted;

// src/useTitle.ts
import { useHead as useHead2 } from "@unhead/vue";
var useTitle_default = (title = "") => useHead2({ title });

// src/useWebSocket.ts
var useWebSocket = ({
  channel,
  onSubscription = (data) => {
    console.log("onSubscription", data);
  },
  onMessage = (data) => {
    console.log("onMessage", data);
  },
  onError = (error2) => {
    console.log("onError", error2);
  }
}) => {
  const connection = new WebSocket(import.meta.env.VITE_WS_URL);
  connection.onopen = () => connection.send(
    JSON.stringify({
      command: "subscribe",
      identifier: JSON.stringify({ channel })
    })
  );
  connection.onmessage = (message) => {
    const payload = JSON.parse(message.data);
    switch (payload.type) {
      case "ping":
        break;
      case "welcome":
        break;
      case "confirm_subscription":
        onSubscription(payload.message);
        break;
      case "disconnect":
        break;
      default:
        onMessage(payload.message);
        break;
    }
  };
  connection.onerror = onError;
  const sendMessage = (data) => connection.send(
    JSON.stringify({
      command: "message",
      identifier: JSON.stringify({ channel }),
      data: JSON.stringify(data)
    })
  );
  return {
    sendMessage
  };
};
var useWebSocket_default = useWebSocket;

// src/useTimezone.ts
var RAILS_TIMEZONE_MAPPING = {
  "International Date Line West": "Pacific/Midway",
  "Midway Island": "Pacific/Midway",
  "American Samoa": "Pacific/Pago_Pago",
  Hawaii: "Pacific/Honolulu",
  Alaska: "America/Juneau",
  "Pacific Time (US & Canada)": "America/Los_Angeles",
  Tijuana: "America/Tijuana",
  "Mountain Time (US & Canada)": "America/Denver",
  Arizona: "America/Phoenix",
  Chihuahua: "America/Chihuahua",
  Mazatlan: "America/Mazatlan",
  "Central Time (US & Canada)": "America/Chicago",
  Saskatchewan: "America/Regina",
  Guadalajara: "America/Mexico_City",
  "Mexico City": "America/Mexico_City",
  Monterrey: "America/Monterrey",
  "Central America": "America/Guatemala",
  "Eastern Time (US & Canada)": "America/New_York",
  "Indiana (East)": "America/Indiana/Indianapolis",
  Bogota: "America/Bogota",
  Lima: "America/Lima",
  Quito: "America/Lima",
  "Atlantic Time (Canada)": "America/Halifax",
  Caracas: "America/Caracas",
  "La Paz": "America/La_Paz",
  Santiago: "America/Santiago",
  Newfoundland: "America/St_Johns",
  Brasilia: "America/Sao_Paulo",
  "Buenos Aires": "America/Argentina/Buenos_Aires",
  Georgetown: "America/Guyana",
  Greenland: "America/Godthab",
  "Mid-Atlantic": "Atlantic/South_Georgia",
  Azores: "Atlantic/Azores",
  "Cape Verde Is.": "Atlantic/Cape_Verde",
  Dublin: "Europe/Dublin",
  Edinburgh: "Europe/London",
  Lisbon: "Europe/Lisbon",
  London: "Europe/London",
  Casablanca: "Africa/Casablanca",
  Monrovia: "Africa/Monrovia",
  UTC: "Etc/UTC",
  Belgrade: "Europe/Belgrade",
  Bratislava: "Europe/Bratislava",
  Budapest: "Europe/Budapest",
  Ljubljana: "Europe/Ljubljana",
  Prague: "Europe/Prague",
  Sarajevo: "Europe/Sarajevo",
  Skopje: "Europe/Skopje",
  Warsaw: "Europe/Warsaw",
  Zagreb: "Europe/Zagreb",
  Brussels: "Europe/Brussels",
  Copenhagen: "Europe/Copenhagen",
  Madrid: "Europe/Madrid",
  Paris: "Europe/Paris",
  Amsterdam: "Europe/Amsterdam",
  Berlin: "Europe/Berlin",
  Bern: "Europe/Berlin",
  Rome: "Europe/Rome",
  Stockholm: "Europe/Stockholm",
  Vienna: "Europe/Vienna",
  "West Central Africa": "Africa/Algiers",
  Bucharest: "Europe/Bucharest",
  Cairo: "Africa/Cairo",
  Helsinki: "Europe/Helsinki",
  Kyiv: "Europe/Kiev",
  Riga: "Europe/Riga",
  Sofia: "Europe/Sofia",
  Tallinn: "Europe/Tallinn",
  Vilnius: "Europe/Vilnius",
  Athens: "Europe/Athens",
  Istanbul: "Europe/Istanbul",
  Minsk: "Europe/Minsk",
  Jerusalem: "Asia/Jerusalem",
  Harare: "Africa/Harare",
  Pretoria: "Africa/Johannesburg",
  Moscow: "Europe/Moscow",
  "St. Petersburg": "Europe/Moscow",
  Volgograd: "Europe/Moscow",
  Kuwait: "Asia/Kuwait",
  Riyadh: "Asia/Riyadh",
  Nairobi: "Africa/Nairobi",
  Baghdad: "Asia/Baghdad",
  Tehran: "Asia/Tehran",
  "Abu Dhabi": "Asia/Muscat",
  Muscat: "Asia/Muscat",
  Baku: "Asia/Baku",
  Tbilisi: "Asia/Tbilisi",
  Yerevan: "Asia/Yerevan",
  Kabul: "Asia/Kabul",
  Ekaterinburg: "Asia/Yekaterinburg",
  Islamabad: "Asia/Karachi",
  Karachi: "Asia/Karachi",
  Tashkent: "Asia/Tashkent",
  Chennai: "Asia/Kolkata",
  Kolkata: "Asia/Kolkata",
  Mumbai: "Asia/Kolkata",
  "New Delhi": "Asia/Kolkata",
  Kathmandu: "Asia/Kathmandu",
  Astana: "Asia/Dhaka",
  Dhaka: "Asia/Dhaka",
  "Sri Jayawardenepura": "Asia/Colombo",
  Almaty: "Asia/Almaty",
  Novosibirsk: "Asia/Novosibirsk",
  Rangoon: "Asia/Rangoon",
  Bangkok: "Asia/Bangkok",
  Hanoi: "Asia/Bangkok",
  Jakarta: "Asia/Jakarta",
  Krasnoyarsk: "Asia/Krasnoyarsk",
  Beijing: "Asia/Shanghai",
  Chongqing: "Asia/Chongqing",
  "Hong Kong": "Asia/Hong_Kong",
  Urumqi: "Asia/Urumqi",
  "Kuala Lumpur": "Asia/Kuala_Lumpur",
  Singapore: "Asia/Singapore",
  Taipei: "Asia/Taipei",
  Perth: "Australia/Perth",
  Irkutsk: "Asia/Irkutsk",
  "Ulaan Bataar": "Asia/Ulaanbaatar",
  Seoul: "Asia/Seoul",
  Osaka: "Asia/Tokyo",
  Sapporo: "Asia/Tokyo",
  Tokyo: "Asia/Tokyo",
  Yakutsk: "Asia/Yakutsk",
  Darwin: "Australia/Darwin",
  Adelaide: "Australia/Adelaide",
  Canberra: "Australia/Melbourne",
  Melbourne: "Australia/Melbourne",
  Sydney: "Australia/Sydney",
  Brisbane: "Australia/Brisbane",
  Hobart: "Australia/Hobart",
  Vladivostok: "Asia/Vladivostok",
  Guam: "Pacific/Guam",
  "Port Moresby": "Pacific/Port_Moresby",
  Magadan: "Asia/Magadan",
  "Solomon Is.": "Pacific/Guadalcanal",
  "New Caledonia": "Pacific/Noumea",
  Fiji: "Pacific/Fiji",
  Kamchatka: "Asia/Kamchatka",
  "Marshall Is.": "Pacific/Majuro",
  Auckland: "Pacific/Auckland",
  Wellington: "Pacific/Auckland",
  "Nuku'alofa": "Pacific/Tongatapu",
  "Tokelau Is.": "Pacific/Fakaofo",
  Samoa: "Pacific/Apia"
};
var getJSTimezoneFromRails = (value) => value && RAILS_TIMEZONE_MAPPING[value] || null;
var useTimezone = (settings) => {
  var _a;
  const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const timezone = getJSTimezoneFromRails((_a = settings.company.settings) == null ? void 0 : _a.time_zone) || browserTimezone;
  return {
    timezone
  };
};
var useTimezone_default = useTimezone;
export {
  createHead,
  useAnalytics_default as useAnalytics,
  useFetch_default as useFetch,
  useFetchAll_default as useFetchAll,
  useFileUpload_default as useFileUpload,
  useForm_default as useForm,
  useHead,
  useLocalStorage,
  useMediaQuery,
  useModal_default as useModal,
  useMounted_default as useMounted,
  useNotifier_default as useNotifier,
  useTimezone_default as useTimezone,
  useTitle_default as useTitle,
  useWebSocket_default as useWebSocket
};
