// src/index.ts
import axios from "axios";

// src/paramsSerializer.ts
import qs from "qs";
var paramsSerializer = {
  serialize: (params) => {
    return qs.stringify(params, { arrayFormat: "brackets" });
  }
};
var paramsSerializer_default = paramsSerializer;

// src/processError.ts
var processError = (error, options = {}) => {
  if (options.onUnauthorized && error.response && error.response.status === 401) {
    options.onUnauthorized();
    return;
  }
  throw error;
};
var processError_default = processError;

// src/processResponse.ts
import { deserialize } from "deserialize-json-api";

// src/flattenResponseObject.ts
var flattenResponseObject = (data = {}) => {
  const newData = { ...data };
  const isObject = (value) => {
    return typeof value === "object";
  };
  Object.keys(newData).forEach((key) => {
    try {
      const value = newData[key];
      const valueIsObject = isObject(value);
      if (valueIsObject && value.data && isObject(value.data) && value.data.attributes) {
        newData[key] = newData[key].data.attributes;
      }
      if (valueIsObject && value.attributes && isObject(value.attributes)) {
        newData[key] = newData[key].attributes;
      }
    } catch (err) {
    }
  });
  return newData;
};
var flattenResponseObject_default = flattenResponseObject;

// src/flattenResponse.ts
var flattenResponse = (data) => {
  if (data instanceof Array) {
    return data.map(flattenResponseObject_default);
  }
  if (data instanceof Object) {
    return flattenResponseObject_default(data);
  }
  return data;
};
var flattenResponse_default = flattenResponse;

// src/processResponse.ts
var isJsonApiResponse = (response) => {
  const data = response.data;
  const hasAttributes = (data2) => {
    if (!data2) {
      return false;
    }
    return data2 && data2.attributes;
  };
  if (hasAttributes(data)) {
    return true;
  }
  if (data && data instanceof Array) {
    return true;
  }
  return false;
};
var processResponse = (response) => {
  if (isJsonApiResponse(response.data)) {
    const deserializedData = deserialize({ ...response.data }).data;
    response.meta = response.data.meta;
    response.dataRaw = response.data;
    response.data = flattenResponse_default(deserializedData);
  }
  return response;
};
var processResponse_default = processResponse;

// src/processOptions.ts
var processOptions = (options) => {
  options.params = JSON.parse(JSON.stringify(options.params || {}));
  if (options.params && options.params.include && options.params.include instanceof Array) {
    options.params.include = options.params.include.join(",");
  }
  if (options.params && options.params.filter) {
    const newFilters = {};
    Object.keys(options.params.filter).forEach((key) => {
      const value = options.params.filter[key];
      if (value !== null) newFilters[key] = value;
    });
    options.params.filter = newFilters;
  }
  return options;
};
var processOptions_default = processOptions;

// src/buildGet.ts
var buildGet = ({
  api,
  baseUrl
}) => {
  return async (options) => {
    var _a;
    const id = (_a = options == null ? void 0 : options.params) == null ? void 0 : _a.id;
    const processedOptions = processOptions_default(options);
    return api.get(
      `${baseUrl}/${id}`,
      processedOptions
    );
  };
};
var buildGet_default = buildGet;

// src/buildGetAll.ts
var buildGetAll = ({
  api,
  baseUrl
}) => {
  return async (options = {}) => {
    const processedOptions = processOptions_default(options);
    return api.get(baseUrl, processedOptions);
  };
};
var buildGetAll_default = buildGetAll;

// src/buildPost.ts
import { toFormData } from "@spectora/utils";
var prepareData = (options) => {
  const data = options.data;
  if (!data) {
    return {};
  }
  const newData = { ...data };
  Object.keys(newData).forEach((key) => {
    newData[key] === void 0 ? delete newData[key] : {};
    if (!options.keepNullParams) {
      newData[key] === null ? delete newData[key] : {};
    }
  });
  return newData;
};
var buildPost = ({
  api,
  baseUrl,
  resource
}) => {
  return async (options) => {
    let config = { ...options };
    const preparedData = prepareData(options);
    if (preparedData && config.multipart) {
      const formData = toFormData({
        prefix: "data[attributes]",
        data: preparedData,
        parseMultipartArrays: config.parseMultipartArrays
      });
      formData.append("data[type]", resource);
      config.transformRequest = (formData2) => formData2;
      return api.post(baseUrl, formData, config);
    }
    if (preparedData) {
      const data = { type: resource, attributes: preparedData };
      config = baseUrl.includes("/v2") ? {
        ...config,
        params: { [resource]: options.params },
        data
      } : data;
    }
    return api.post(baseUrl, config);
  };
};
var buildPost_default = buildPost;

// src/buildPut.ts
import { toFormData as toFormData2 } from "@spectora/utils";
var prepareData2 = (options) => {
  const data = options.data;
  if (!data) {
    return {};
  }
  const newData = { ...data };
  Object.keys(newData).forEach((key) => {
    newData[key] === void 0 ? delete newData[key] : {};
    if (!options.keepNullParams) {
      newData[key] === null ? delete newData[key] : {};
    }
  });
  return newData;
};
var buildPut = ({
  api,
  baseUrl,
  resource
}) => {
  return async (options) => {
    var _a;
    const id = (_a = options == null ? void 0 : options.params) == null ? void 0 : _a.id;
    let config = { ...options };
    const url = `${baseUrl}/${id}`;
    const preparedData = prepareData2(options);
    if (preparedData && config.multipart) {
      const formData = toFormData2({
        prefix: "data[attributes]",
        data: preparedData,
        parseMultipartArrays: config.parseMultipartArrays
      });
      formData.append("data[id]", id);
      formData.append("data[type]", resource);
      config.transformRequest = (formData2) => formData2;
      return api.put(url, formData, config);
    }
    if (preparedData) {
      const data = { id, type: resource, attributes: preparedData };
      config = baseUrl.includes("/v2") ? {
        ...config,
        data
      } : data;
    }
    return api.put(url, config);
  };
};
var buildPut_default = buildPut;

// src/buildDelete.ts
var buildDelete = ({
  api,
  baseUrl
}) => {
  return async (options) => {
    var _a;
    const id = (_a = options == null ? void 0 : options.params) == null ? void 0 : _a.id;
    return api.delete(`${baseUrl}/${id}`, options);
  };
};
var buildDelete_default = buildDelete;

// src/v1/agents.ts
var agents = (api) => {
  const baseUrl = "/api/v1/agents";
  const resource = "agent";
  return {
    baseUrl,
    get: buildGet_default({ api, baseUrl }),
    getAll: buildGetAll_default({ api, baseUrl }),
    delete: buildDelete_default({ api, baseUrl }),
    post: buildPost_default({ api, baseUrl, resource }),
    put: buildPut_default({ api, baseUrl, resource })
  };
};
var agents_default = agents;

// src/v1/assignments.ts
var assignments = (api) => {
  const baseUrl = `/api/v1/assignments`;
  const resource = "assignment";
  return {
    baseUrl,
    getAll: buildGetAll_default({
      api,
      baseUrl
    }),
    resource
  };
};
var assignments_default = assignments;

// src/v1/autocompleteChoices.ts
var autocompleteChoices = (api) => {
  const baseUrl = "/api/v1/autocomplete_choices";
  return {
    baseUrl,
    getAll: buildGetAll_default({
      api,
      baseUrl
    })
  };
};
var autocompleteChoices_default = autocompleteChoices;

// src/v1/companies.ts
var companies = (api) => {
  const baseUrl = "/api/v1/companies";
  const resource = "company";
  return {
    baseUrl,
    get: buildGet_default({ api, baseUrl }),
    getAll: buildGetAll_default({ api, baseUrl }),
    delete: buildDelete_default({ api, baseUrl }),
    post: buildPost_default({ api, baseUrl, resource }),
    put: buildPut_default({ api, baseUrl, resource })
  };
};
var companies_default = companies;

// src/v1/imports.ts
var imports = (api) => {
  const baseUrl = "/api/v1/imports";
  const extractHeaders = async (config) => {
    const url = `${baseUrl}/headers`;
    const data = config.data || config.params;
    return await api.post(
      url,
      data,
      config
    );
  };
  return {
    baseUrl,
    extractHeaders
  };
};
var imports_default = imports;

// src/v1/contractorTextMessageSubscribe.ts
var contractorTextMessageSubscribe = (api) => {
  const baseUrl = "/api/v1/public/text_messages/contractors/subscribe";
  const resource = "subscribe";
  return {
    baseUrl,
    post: buildPost_default({ api, baseUrl, resource })
  };
};
var contractorTextMessageSubscribe_default = contractorTextMessageSubscribe;

// src/v1/contractorTextMessageSubscribeFormDetails.ts
var contractorTextMessageSubscribeFormDetails = (api) => {
  const baseUrl = "/api/v1/public/text_messages/contractors/subscribe_form_details";
  return {
    baseUrl,
    get: buildGet_default({
      api,
      baseUrl
    })
  };
};
var contractorTextMessageSubscribeFormDetails_default = contractorTextMessageSubscribeFormDetails;

// src/v1/inspectionAgreementTemplates.ts
var inspectionAgreementTemplates = (api) => {
  const baseUrl = "/api/v1/inspection_agreement_templates";
  return {
    baseUrl,
    get: buildGet_default({ api, baseUrl }),
    getAll: buildGetAll_default({ api, baseUrl })
  };
};
var inspectionAgreementTemplates_default = inspectionAgreementTemplates;

// src/v1/inspections.ts
var inspections = (api) => {
  const baseUrl = `/api/v1/inspections`;
  const resource = "inspection";
  return {
    baseUrl,
    get: buildGet_default({ api, baseUrl }),
    getAll: buildGetAll_default({ api, baseUrl }),
    post: buildPost_default({ api, baseUrl, resource }),
    put: buildPut_default({ api, baseUrl, resource }),
    export: async (options = {}) => {
      const url = `${baseUrl}/export`;
      const data = options.data || options.params;
      return api.post(url, data, options);
    }
  };
};
var inspections_default = inspections;

// src/v1/inspectors.ts
var inspectors = (api) => {
  const baseUrl = "/api/v1/inspectors";
  const resource = "inspector";
  return {
    baseUrl,
    get: buildGet_default({ api, baseUrl }),
    getAll: buildGetAll_default({ api, baseUrl }),
    resource
  };
};
var inspectors_default = inspectors;

// src/v1/reports.ts
var reports = (api) => {
  const baseUrl = "/api/v1/reports";
  const resource = "report";
  return {
    baseUrl,
    get: buildGet_default({ api, baseUrl }),
    getAll: buildGetAll_default({ api, baseUrl }),
    resource
  };
};
var reports_default = reports;

// src/v1/reportViews.ts
var reportViews = (api) => {
  const baseUrl = "/api/v1/report_views";
  const resource = "report_view";
  return {
    baseUrl,
    get: buildGet_default({ api, baseUrl }),
    getAll: buildGetAll_default({ api, baseUrl }),
    resource
  };
};
var reportViews_default = reportViews;

// src/v1/serviceAreas.ts
var serviceAreas = (api) => {
  const baseUrl = "/api/v1/service_areas";
  return {
    baseUrl,
    get: buildGet_default({ api, baseUrl }),
    getAll: buildGetAll_default({ api, baseUrl }),
    delete: buildDelete_default({ api, baseUrl }),
    post: (options = {}) => {
      const data = options.data || options.params;
      return api.post(baseUrl, data);
    },
    put: (options = {}) => {
      const data = options.data || options.params;
      const url = `${baseUrl}/${data.id}`;
      return api.put(url, data);
    }
  };
};
var serviceAreas_default = serviceAreas;

// src/v1/services.ts
var services = (api) => {
  const baseUrl = "/api/v1/services";
  const resource = "service";
  return {
    baseUrl,
    get: buildGet_default({ api, baseUrl }),
    getAll: buildGetAll_default({ api, baseUrl }),
    delete: buildDelete_default({ api, baseUrl }),
    post: buildPost_default({ api, baseUrl, resource }),
    put: buildPut_default({ api, baseUrl, resource })
  };
};
var services_default = services;

// src/v1/sessions.ts
var sessions = (api) => {
  return {
    async create(params, options = {}) {
      const url = "/api/v1/login";
      return api.post(url, { user: params }, options);
    },
    async destroy(options = {}) {
      return api.delete("/api/v1/logout", options);
    }
  };
};
var sessions_default = sessions;

// src/v1/tags.ts
var tags = (api) => {
  const baseUrl = "/api/v1/tags";
  const resource = "tag";
  return {
    baseUrl,
    getAll: buildGetAll_default({ api, baseUrl }),
    resource
  };
};
var tags_default = tags;

// src/v1/templates.ts
var templates = (api) => {
  const baseUrl = "/api/v1/templates";
  return {
    baseUrl,
    get: buildGet_default({ api, baseUrl }),
    getAll: buildGetAll_default({ api, baseUrl })
  };
};
var templates_default = templates;

// src/v1/textMessageSubscriptions.ts
var textMessageSubscriptions = (api) => {
  const baseUrl = "/api/v1/text_message_subscriptions";
  return {
    baseUrl,
    get: buildGet_default({ api, baseUrl })
  };
};
var textMessageSubscriptions_default = textMessageSubscriptions;

// src/v1/textMessagesUnsubscribe.ts
var textMessagesUnsubscribe = (api) => {
  const baseUrl = "/api/v1/public/text_messages/unsubscribe";
  const resource = "unsubscribe";
  return {
    baseUrl,
    post: buildPost_default({ api, baseUrl, resource })
  };
};
var textMessagesUnsubscribe_default = textMessagesUnsubscribe;

// src/v2/agencies.ts
var agencies = (api) => {
  const baseUrl = "/api/v2/agencies";
  const resource = "agency";
  return {
    baseUrl,
    get: buildGet_default({ api, baseUrl }),
    getAll: buildGetAll_default({ api, baseUrl }),
    delete: buildDelete_default({ api, baseUrl }),
    post: buildPost_default({ api, baseUrl, resource }),
    put: buildPut_default({ api, baseUrl, resource }),
    async bulkDelete(options = {}) {
      const url = `${baseUrl}/bulk_destroy`;
      return api.delete(url, options);
    },
    async bulkMerge(options = {}) {
      const url = `${baseUrl}/bulk_merge`;
      const data = options.data || options.params;
      return api.post(url, data, options);
    }
  };
};
var agencies_default = agencies;

// src/v2/agencyStats.ts
var agencyStats = (api) => {
  const baseUrl = "/api/v2/agency_stats";
  return {
    baseUrl,
    getAll: buildGetAll_default({
      api,
      baseUrl
    }),
    async export(options = {}) {
      const url = `${baseUrl}/export`;
      return api.post(
        url,
        options.data || options.params,
        options
      );
    }
  };
};
var agencyStats_default = agencyStats;

// src/v2/agentTeams.ts
var agentTeams = (api) => {
  const baseUrl = "/api/v2/agent_teams";
  const resource = "agent_team";
  return {
    baseUrl,
    get: buildGet_default({ api, baseUrl }),
    getAll: buildGetAll_default({ api, baseUrl }),
    post: buildPost_default({ api, baseUrl, resource }),
    put: buildPut_default({ api, baseUrl, resource }),
    delete: buildDelete_default({ api, baseUrl })
  };
};
var agentTeams_default = agentTeams;

// src/v2/agentTeamStats.ts
var agentTeamStats = (api) => {
  const baseUrl = "/api/v2/agent_team_stats";
  return {
    baseUrl,
    getAll: buildGetAll_default({
      api,
      baseUrl
    }),
    async export(options = {}) {
      const url = `${baseUrl}/export`;
      return api.post(
        url,
        options.data || options.params,
        options
      );
    }
  };
};
var agentTeamStats_default = agentTeamStats;

// src/v2/calendarEvents.ts
var calendarEvents = (api) => {
  const baseUrl = "/api/v2/calendar_events";
  const resource = "calendar_event";
  return {
    baseUrl,
    getAll: buildGetAll_default({
      api,
      baseUrl
    })
  };
};
var calendarEvents_default = calendarEvents;

// src/v2/changelogs.ts
var changelogs = (api) => {
  const baseUrl = "/api/v2/changelogs";
  return {
    baseUrl,
    getAll: buildGetAll_default({ api, baseUrl })
  };
};
var changelogs_default = changelogs;

// src/v2/companies.ts
var companies2 = (api) => {
  const baseUrl = "/api/v2/companies";
  const resource = "company";
  return {
    baseUrl,
    put: buildPut_default({ api, baseUrl, resource }),
    get: buildGet_default({ api, baseUrl })
  };
};
var companies_default2 = companies2;

// src/v2/companyPeople.ts
var companyPeople = (api) => {
  const baseUrl = "/api/v2/company_people";
  const resource = "company_person";
  return {
    baseUrl,
    get: buildGet_default({ api, baseUrl }),
    getAll: buildGetAll_default({
      api,
      baseUrl
    }),
    delete: buildDelete_default({ api, baseUrl }),
    post: buildPost_default({ api, baseUrl, resource }),
    put: buildPut_default({ api, baseUrl, resource }),
    async export(options = {}) {
      const url = `${baseUrl}/export`;
      const data = options.data || options.params;
      return api.post(url, data, options);
    }
  };
};
var companyPeople_default = companyPeople;

// src/v2/companyTrialBoardings.ts
var companyTrialBoardings = (api) => {
  const baseUrl = "/api/v2/company_trialboardings";
  const resource = "company_trialboardings";
  return {
    baseUrl,
    get: buildGet_default({ api, baseUrl }),
    async track(options = {}) {
      var _a;
      const id = (_a = options == null ? void 0 : options.data) == null ? void 0 : _a.id;
      const url = `${baseUrl}/${id}/track`;
      const data = options.data;
      return api.post(url, {
        data: {
          type: resource,
          attributes: data
        }
      }, options);
    }
  };
};
var companyTrialBoardings_default = companyTrialBoardings;

// src/v2/connections.ts
var connections = (api) => {
  const baseUrl = "/api/v2/connections";
  const resource = "connection";
  return {
    baseUrl,
    get: buildGet_default({ api, baseUrl }),
    getAll: buildGetAll_default({ api, baseUrl }),
    delete: buildDelete_default({ api, baseUrl }),
    post: buildPost_default({ api, baseUrl, resource }),
    put: buildPut_default({ api, baseUrl, resource }),
    async bulkDelete(options = {}) {
      const url = `${baseUrl}/bulk_destroy`;
      return api.delete(url, options);
    },
    async bulkUpdate(options = {}) {
      const url = `${baseUrl}/bulk_update`;
      const data = options.data || options.params;
      return api.post(url, data, options);
    },
    async export(options = {}) {
      const url = `${baseUrl}/export`;
      const data = options.data || options.params;
      return api.post(url, data, options);
    }
  };
};
var connections_default = connections;

// src/v2/connectionStats.ts
var connectionStats = (api) => {
  const baseUrl = "/api/v2/connection_stats";
  return {
    baseUrl,
    getAll: buildGetAll_default({
      api,
      baseUrl
    }),
    async export(options = {}) {
      const url = `${baseUrl}/export`;
      return api.post(
        url,
        options.data || options.params,
        options
      );
    }
  };
};
var connectionStats_default = connectionStats;

// src/v2/contacts.ts
var contacts = (api) => {
  const baseUrl = "/api/v2/contacts";
  const resource = "contact";
  return {
    baseUrl,
    delete: buildDelete_default({ api, baseUrl }),
    get: buildGet_default({ api, baseUrl }),
    getAll: buildGetAll_default({ api, baseUrl }),
    post: buildPost_default({ api, baseUrl, resource }),
    put: buildPut_default({ api, baseUrl, resource }),
    async bulkDelete(options = {}) {
      const url = `${baseUrl}/bulk_destroy`;
      return api.delete(url, options);
    },
    async export(options = {}) {
      const url = `${baseUrl}/export`;
      const data = options.data || options.params;
      return api.post(url, data, options);
    }
  };
};
var contacts_default = contacts;

// src/v2/contractors.ts
var contractors = (api) => {
  const baseUrl = "/api/v2/contractors";
  const resource = "contractor";
  return {
    baseUrl,
    get: buildGet_default({ api, baseUrl }),
    getAll: buildGetAll_default({ api, baseUrl }),
    delete: buildDelete_default({ api, baseUrl }),
    post: buildPost_default({ api, baseUrl, resource }),
    put: buildPut_default({ api, baseUrl, resource }),
    async bulkDelete(options = {}) {
      const url = `${baseUrl}/bulk_destroy`;
      return api.delete(url, options);
    },
    bulkUpdate: buildPut_default({ api, baseUrl, resource })
  };
};
var contractors_default = contractors;

// src/v2/customFieldTemplates.ts
var CustomFieldTemplates = (api) => {
  const baseUrl = "/api/v2/custom_field_templates";
  const resource = "custom_field_template";
  return {
    baseUrl,
    bulkUpdate: async (options = {}) => {
      const url = `${baseUrl}/bulk_update`;
      const data = options.data || options.params;
      return api.post(url, data, options);
    },
    delete: buildDelete_default({ api, baseUrl }),
    get: buildGet_default({ api, baseUrl }),
    getAll: buildGetAll_default(
      { api, baseUrl }
    ),
    post: buildPost_default({ api, baseUrl, resource }),
    put: buildPut_default({ api, baseUrl, resource })
  };
};
var customFieldTemplates_default = CustomFieldTemplates;

// src/v2/customPhoneNumbers.ts
var CustomPhoneNumbers = (api) => {
  const baseUrl = "/api/v2/custom_phone_numbers";
  return {
    baseUrl,
    getAll: buildGetAll_default({
      api,
      baseUrl
    })
  };
};
var customPhoneNumbers_default = CustomPhoneNumbers;

// src/v2/duplicatedConnections.ts
var connectionStats2 = (api) => {
  const baseUrl = "/api/v2/duplicated_connections";
  return {
    baseUrl,
    getAll: buildGetAll_default({
      api,
      baseUrl
    }),
    async merge(options) {
      const url = `${baseUrl}/merge`;
      const data = options.params;
      return api.post(url, data, options);
    },
    async export(options = {}) {
      const url = `${baseUrl}/export`;
      return api.post(
        url,
        options.data || options.params,
        options
      );
    }
  };
};
var duplicatedConnections_default = connectionStats2;

// src/v2/email_templates.ts
var connections2 = (api) => {
  const baseUrl = "/api/v2/email_templates";
  const resource = "email_template";
  return {
    baseUrl,
    delete: buildDelete_default({ api, baseUrl }),
    async duplicate(options) {
      var _a;
      const id = (_a = options == null ? void 0 : options.params) == null ? void 0 : _a.id;
      const url = `${baseUrl}/${id}/duplicate`;
      const data = options.data || options.params;
      return api.post(url, data, options);
    },
    get: buildGet_default({ api, baseUrl }),
    getAll: buildGetAll_default({
      api,
      baseUrl
    }),
    post: buildPost_default({ api, baseUrl, resource }),
    put: buildPut_default({ api, baseUrl, resource })
  };
};
var email_templates_default = connections2;

// src/v2/emails.ts
var connections3 = (api) => {
  const baseUrl = "/api/v2/emails";
  const resource = "email";
  return {
    baseUrl,
    get: buildGet_default({ api, baseUrl }),
    getAll: buildGetAll_default({ api, baseUrl }),
    post: buildPost_default({ api, baseUrl, resource })
  };
};
var emails_default = connections3;

// src/buildExport.ts
var buildExport = ({
  api,
  baseUrl
}) => {
  return async (options = {}) => {
    const url = `${baseUrl}/export`;
    const processedOptions = processOptions_default(options);
    return api.post(
      url,
      processedOptions.data || {},
      processedOptions
    );
  };
};
var buildExport_default = buildExport;

// src/v2/events.ts
var events = (api) => {
  const baseUrl = "/api/v2/events";
  return {
    baseUrl,
    getAll: buildGetAll_default({ api, baseUrl }),
    export: buildExport_default({ api, baseUrl })
  };
};
var events_default = events;

// src/v2/imports.ts
var imports2 = (api) => {
  const baseUrl = "/api/v2/imports";
  const resource = "data_import";
  return {
    baseUrl,
    getAll: buildGetAll_default({ api, baseUrl }),
    post: buildPost_default({ api, baseUrl, resource })
  };
};
var imports_default2 = imports2;

// src/v2/inspectionCities.ts
var inspectionCities = (api) => {
  const baseUrl = "/api/v2/inspection_locations/cities";
  const resource = "inspector";
  return {
    baseUrl,
    getAll: buildGetAll_default({ api, baseUrl }),
    resource
  };
};
var inspectionCities_default = inspectionCities;

// src/v2/inspectionConciergeRequests.ts
var inspectionConciergeRequests = (api) => {
  const baseUrl = "/api/v2/inspection_concierge_requests";
  const resource = "inspector_concierge_request";
  return {
    baseUrl,
    get: buildGet_default({ api, baseUrl }),
    put: buildPut_default({ api, baseUrl, resource })
  };
};
var inspectionConciergeRequests_default = inspectionConciergeRequests;

// src/v2/inspections.ts
var inspections2 = (api) => {
  const baseUrl = "/api/v2/inspections";
  const resource = "inspection";
  return {
    baseUrl,
    get: buildGet_default({ api, baseUrl }),
    getAll: buildGetAll_default({ api, baseUrl }),
    async demo(options = {}) {
      const url = `${baseUrl}?demo=true`;
      return api.post(url, null, options);
    },
    async export(options = {}) {
      const url = `${baseUrl}/export`;
      const data = options.data || options.params;
      return api.post(url, data, options);
    },
    async getAllWithPost(options = {}) {
      const url = `${baseUrl}/index`;
      const processedOptions = processOptions_default(options);
      const data = processedOptions.data || processedOptions.params;
      return api.post(url, data);
    }
  };
};
var inspections_default2 = inspections2;

// src/v2/inspectionStates.ts
var inspectionStates = (api) => {
  const baseUrl = "/api/v2/inspection_locations/states";
  const resource = "inspector";
  return {
    baseUrl,
    getAll: buildGetAll_default({ api, baseUrl }),
    resource
  };
};
var inspectionStates_default = inspectionStates;

// src/v2/inspectorRegistrations.ts
var inspectorRegistrations = (api) => {
  const baseUrl = "/api/v2/inspector_registrations";
  const resource = "inspector_registration";
  return {
    baseUrl,
    post: buildPost_default({ api, baseUrl, resource }),
    additionalDetails: async (url, options) => {
      const preparedData = prepareData(options);
      const data = { type: resource, attributes: preparedData };
      const config = {
        ...options,
        params: { [resource]: options.params },
        data
      };
      return api.post(url, config);
    }
  };
};
var inspectorRegistrations_default = inspectorRegistrations;

// src/v2/inspectors.ts
var inspectors2 = (api) => {
  const baseUrl = "/api/v2/inspectors";
  const resource = "inspector";
  return {
    baseUrl,
    getAll: buildGetAll_default({ api, baseUrl }),
    get: buildGet_default({ api, baseUrl }),
    post: buildPost_default({ api, baseUrl, resource }),
    put: buildPut_default({ api, baseUrl, resource }),
    delete: buildDelete_default({ api, baseUrl }),
    resource
  };
};
var inspectors_default2 = inspectors2;

// src/v2/managers.ts
var managers = (api) => {
  const baseUrl = "/api/v2/managers";
  const resource = "manager";
  return {
    baseUrl,
    getAll: buildGetAll_default({ api, baseUrl }),
    get: buildGet_default({ api, baseUrl }),
    post: buildPost_default({ api, baseUrl, resource }),
    put: buildPut_default({ api, baseUrl, resource }),
    delete: buildDelete_default({ api, baseUrl }),
    resource
  };
};
var managers_default = managers;

// src/v2/massEmails.ts
var massEmails = (api) => {
  const baseUrl = "/api/v2/mass_emails";
  const resource = "mass_email";
  return {
    baseUrl,
    get: buildGet_default({ api, baseUrl }),
    getAll: buildGetAll_default({ api, baseUrl }),
    post: buildPost_default({ api, baseUrl, resource }),
    put: buildPut_default({ api, baseUrl, resource }),
    delete: buildDelete_default({ api, baseUrl }),
    async bulkDelete(options = {}) {
      const url = `${baseUrl}/bulk_destroy`;
      return api.delete(url, options);
    },
    async sendPreview(options = {}) {
      var _a;
      const id = (_a = options == null ? void 0 : options.params) == null ? void 0 : _a.id;
      const url = `${baseUrl}/${id}/preview_emails`;
      const data = options.data || options.params;
      return api.post(url, data, options);
    },
    async sendAll(options = {}) {
      var _a;
      const id = (_a = options == null ? void 0 : options.params) == null ? void 0 : _a.id;
      const url = `${baseUrl}/${id}/emails`;
      const data = options.data || options.params;
      return api.post(url, data, options);
    },
    async startTrial(options = {}) {
      const url = `${baseUrl}/start_trial`;
      const data = options.data || options.params;
      return api.post(url, data, options);
    },
    async filterRecipients(options = {}) {
      const url = `${baseUrl}/filter_recipients`;
      const data = options.data || options.params;
      return api.post(url, data, options);
    },
    async copy(options) {
      var _a;
      const id = (_a = options == null ? void 0 : options.params) == null ? void 0 : _a.id;
      const url = `${baseUrl}/${id}/copy`;
      const data = options.data || options.params;
      return api.post(url, data, options);
    }
  };
};
var massEmails_default = massEmails;

// src/v2/massEmailSettings.ts
var massEmailSettings = (api) => {
  const baseUrl = "/api/v2/mass_email_settings";
  const resource = "mass_email_setting";
  return {
    baseUrl,
    get: buildGet_default({ api, baseUrl }),
    getAll: buildGetAll_default({
      api,
      baseUrl
    }),
    put: buildPut_default({ api, baseUrl, resource }),
    async current() {
      const url = `${baseUrl}/current`;
      return api.get(url);
    }
  };
};
var massEmailSettings_default = massEmailSettings;

// src/v2/monthlyRevenueReports.ts
var monthlyRevenueReports = (api) => {
  const baseUrl = "/api/v2/monthly_revenue_reports";
  return {
    baseUrl,
    getAll: buildGetAll_default({
      api,
      baseUrl
    })
  };
};
var monthlyRevenueReports_default = monthlyRevenueReports;

// src/v2/notes.ts
var notes = (api) => {
  const baseUrl = "/api/v2/notes";
  const resource = "note";
  return {
    baseUrl,
    get: buildGet_default({ api, baseUrl }),
    getAll: buildGetAll_default({ api, baseUrl }),
    delete: buildDelete_default({ api, baseUrl }),
    post: buildPost_default({ api, baseUrl, resource }),
    put: buildPut_default({ api, baseUrl, resource })
  };
};
var notes_default = notes;

// src/v2/payments.ts
var payments = (api) => {
  const baseUrl = "/api/v2/payments";
  return {
    baseUrl,
    async balance(options) {
      const url = `${baseUrl}/balance`;
      return api.get(url, options);
    },
    getAll: buildGetAll_default({ api, baseUrl }),
    async export(options = {}) {
      const url = `${baseUrl}/export`;
      const data = options.data || options.params;
      return api.post(url, data, options);
    }
  };
};
var payments_default = payments;

// src/v2/paysplitPayoutReports.ts
var paysplitPayoutReports = (api) => {
  const baseUrl = "/api/v2/paysplit_payout_reports";
  return {
    baseUrl,
    getAll: buildGetAll_default({ api, baseUrl }),
    async export(options = {}) {
      const url = `${baseUrl}/export`;
      const data = options.data || options.params;
      return api.post(url, data, options);
    }
  };
};
var paysplitPayoutReports_default = paysplitPayoutReports;

// src/v2/paysplits.ts
var paysplits = (api) => {
  const baseUrl = "/api/v2/paysplits";
  const resource = "paysplit";
  return {
    baseUrl,
    get: buildGet_default({ api, baseUrl }),
    getAll: buildGetAll_default({ api, baseUrl }),
    delete: buildDelete_default({ api, baseUrl }),
    post: buildPost_default({ api, baseUrl, resource }),
    copyInspectorPaysplits: buildPost_default({
      api,
      baseUrl: `${baseUrl}/copy_inspector_paysplits`,
      resource
    }),
    put: buildPut_default({ api, baseUrl, resource })
  };
};
var paysplits_default = paysplits;

// src/v2/projectedRevenueReports.ts
var projectedRevenueReports = (api) => {
  const baseUrl = "/api/v2/projected_revenue_reports";
  return {
    baseUrl,
    getAll: buildGetAll_default({
      api,
      baseUrl
    })
  };
};
var projectedRevenueReports_default = projectedRevenueReports;

// src/v2/public/addOnRequests.ts
var addOnRequests = ({ api, baseUrl }) => {
  const addOnRequestsBaseUrl = `${baseUrl}/add_on_requests`;
  const resource = "add_on_request";
  return {
    baseUrl: addOnRequestsBaseUrl,
    getAll: buildGetAll_default({
      api,
      baseUrl: addOnRequestsBaseUrl
    }),
    post: buildPost_default({ api, baseUrl: addOnRequestsBaseUrl, resource })
  };
};
var addOnRequests_default = addOnRequests;

// src/v2/public/authorizations.ts
var authorizations = ({ api, baseUrl }) => {
  const authorizationsBaseUrl = `${baseUrl}/authorizations`;
  return {
    baseUrl: authorizationsBaseUrl,
    async clientPortalAccess(options) {
      const url = `${authorizationsBaseUrl}/client_portal_access`;
      const { data } = await api.get(url, options);
      return data;
    }
  };
};
var authorizations_default = authorizations;

// src/v2/public/charges.ts
var charges = ({ api, baseUrl }) => {
  const chargesBaseUrl = `${baseUrl}/charges`;
  return {
    baseUrl: chargesBaseUrl,
    getAll: buildGetAll_default({
      api,
      baseUrl: chargesBaseUrl
    })
  };
};
var charges_default = charges;

// src/v2/public/contractors.ts
var contractors2 = ({ api, baseUrl }) => {
  const contractorsBaseUrl = `${baseUrl}/company_contractors`;
  return {
    baseUrl: contractorsBaseUrl,
    getAll: buildGetAll_default({
      api,
      baseUrl: contractorsBaseUrl
    })
  };
};
var contractors_default2 = contractors2;

// src/v2/public/inspectionPeople.ts
var inspectionPeople = ({ api, baseUrl }) => {
  const inspectionPeopleBaseUrl = `${baseUrl}/inspection_people`;
  return {
    baseUrl: inspectionPeopleBaseUrl,
    getAll: buildGetAll_default({
      api,
      baseUrl: inspectionPeopleBaseUrl
    })
  };
};
var inspectionPeople_default = inspectionPeople;

// src/v2/public/inspections.ts
var inspections3 = ({ api, baseUrl }) => {
  const inspectionsBaseUrl = `${baseUrl}/inspections`;
  return {
    baseUrl: inspectionsBaseUrl,
    get: buildGet_default({
      api,
      baseUrl: inspectionsBaseUrl
    }),
    async updateClosingDate(options) {
      var _a;
      const url = `${inspectionsBaseUrl}/${(_a = options.data) == null ? void 0 : _a.slug}/closing_date`;
      const { id_token, closing_date } = options.data;
      const data = { data: { attributes: { id_token, closing_date } } };
      return api.post(
        url,
        data,
        options
      );
    }
  };
};
var inspections_default3 = inspections3;

// src/v2/public/inspectionServices.ts
var inspectionServices = ({ api, baseUrl }) => {
  const inspectionServicesBaseUrl = `${baseUrl}/inspection_services`;
  return {
    baseUrl: inspectionServicesBaseUrl,
    getAll: buildGetAll_default({
      api,
      baseUrl: inspectionServicesBaseUrl
    })
  };
};
var inspectionServices_default = inspectionServices;

// src/v2/public/inspectionTasks.ts
var inspections4 = ({ api, baseUrl }) => {
  const inspectionTasksBaseUrl = `${baseUrl}/client_portal/inspection_tasks`;
  return {
    baseUrl: inspectionTasksBaseUrl,
    getAll: buildGetAll_default({
      api,
      baseUrl: inspectionTasksBaseUrl
    })
  };
};
var inspectionTasks_default = inspections4;

// src/v2/public/index.ts
var publicV2 = (api) => {
  const baseUrl = "/api/v2/public";
  return {
    baseUrl,
    addOnRequests: addOnRequests_default({ api, baseUrl }),
    authorizations: authorizations_default({ api, baseUrl }),
    charges: charges_default({ api, baseUrl }),
    contractors: contractors_default2({ api, baseUrl }),
    inspectionPeople: inspectionPeople_default({ api, baseUrl }),
    inspections: inspections_default3({ api, baseUrl }),
    inspectionServices: inspectionServices_default({ api, baseUrl }),
    inspectionTasks: inspectionTasks_default({ api, baseUrl })
  };
};
var public_default = publicV2;

// src/v2/quarterlyRevenueReports.ts
var quarterlyRevenueReports = (api) => {
  const baseUrl = "/api/v2/quarterly_revenue_reports";
  const resource = "quarterly_revenue_report";
  return {
    baseUrl,
    getAll: buildGetAll_default({
      api,
      baseUrl
    })
  };
};
var quarterlyRevenueReports_default = quarterlyRevenueReports;

// src/v2/radonDevices.ts
var radonDevices = (api) => {
  const baseUrl = "/api/v2/radon_devices";
  const resource = "radon_device";
  return {
    baseUrl,
    get: buildGet_default({ api, baseUrl }),
    getAll: buildGetAll_default({
      api,
      baseUrl
    }),
    delete: buildDelete_default({ api, baseUrl }),
    post: buildPost_default({ api, baseUrl, resource }),
    put: buildPut_default({ api, baseUrl, resource })
  };
};
var radonDevices_default = radonDevices;

// src/v2/recommendations.ts
var recommendations = (api) => {
  const baseUrl = "/api/v2/recommendations";
  const resource = "recommendation";
  return {
    baseUrl,
    get: buildGet_default({
      api,
      baseUrl
    }),
    getAll: buildGetAll_default({
      api,
      baseUrl
    }),
    post: buildPost_default({ api, baseUrl, resource }),
    put: buildPut_default({ api, baseUrl, resource }),
    delete: buildDelete_default({ api, baseUrl })
  };
};
var recommendations_default = recommendations;

// src/v2/revenueByInspectorReports.ts
var revenueByInspectorReports = (api) => {
  const baseUrl = "/api/v2/revenue_by_inspector_reports";
  return {
    baseUrl,
    getAll: buildGetAll_default({
      api,
      baseUrl
    })
  };
};
var revenueByInspectorReports_default = revenueByInspectorReports;

// src/v2/revenueByServiceReports.ts
var revenueByServiceReports = (api) => {
  const baseUrl = "/api/v2/revenue_by_service_reports";
  return {
    baseUrl,
    get: async (options = {}) => {
      const processedOptions = processOptions_default(options);
      return api.get(baseUrl, processedOptions);
    },
    async export(options = {}) {
      const url = `${baseUrl}/export`;
      const data = options.data || options.params;
      return api.post(url, data, options);
    }
  };
};
var revenueByServiceReports_default = revenueByServiceReports;

// src/v2/savedReports.ts
var SavedReports = (api) => {
  const baseUrl = "/api/v2/saved_reports";
  const resource = "saved_report";
  return {
    baseUrl,
    get: buildGet_default({ api, baseUrl }),
    getAll: buildGetAll_default({
      api,
      baseUrl
    }),
    delete: buildDelete_default({ api, baseUrl }),
    post: buildPost_default({ api, baseUrl, resource }),
    put: buildPut_default({ api, baseUrl, resource })
  };
};
var savedReports_default = SavedReports;

// src/v2/schedulerReports.ts
var schedulerReports = (api) => {
  const baseUrl = "/api/v2/scheduler_reports";
  return {
    baseUrl,
    getAll: buildGetAll_default({
      api,
      baseUrl
    }),
    export: buildExport_default({ api, baseUrl })
  };
};
var schedulerReports_default = schedulerReports;

// src/v2/serviceAddOns.ts
var serviceAddOns = (api) => {
  const baseUrl = "/api/v2/service_add_ons";
  const resource = "service_add_on";
  return {
    baseUrl,
    getAll: buildGetAll_default({ api, baseUrl }),
    resource
  };
};
var serviceAddOns_default = serviceAddOns;

// src/v2/serviceAreas.ts
var serviceAreas2 = (api) => {
  const baseUrl = "/api/v2/service_areas";
  const resource = "contractors_service_area";
  return {
    baseUrl,
    getAssignedContractors: async (params) => {
      const include = params.include ? `?include=${params.include.join(",")}` : "";
      const url = `${baseUrl}/${params.service_area_id}/contractors${include}`;
      return api.get(url);
    },
    assignContractors: async (options = {}) => {
      const data = {
        type: resource,
        attributes: options.data || options.params
      };
      const url = `${baseUrl}/${data.attributes.service_area_id}/contractors`;
      return api.post(url, { data }, options);
    },
    unassignContractor: async (options = {}) => {
      const data = {
        type: resource,
        attributes: options.data || options.params
      };
      const url = `${baseUrl}/${data.attributes.service_area_id}/contractors/${data.attributes.company_contractor_id}`;
      return api.delete(url);
    }
  };
};
var serviceAreas_default2 = serviceAreas2;

// src/v2/serviceCategories.ts
var serviceCategories = (api) => {
  const baseUrl = "/api/v2/service_categories";
  const resource = "service_category";
  return {
    baseUrl,
    get: buildGet_default({
      api,
      baseUrl
    }),
    getAll: buildGetAll_default({
      api,
      baseUrl
    }),
    delete: buildDelete_default({ api, baseUrl }),
    post: buildPost_default({ api, baseUrl, resource }),
    put: buildPut_default({ api, baseUrl, resource })
  };
};
var serviceCategories_default = serviceCategories;

// src/v2/serviceModifiers.ts
var serviceModifiers = (api) => {
  const baseUrl = "/api/v2/service_modifiers";
  const resource = "service_modifier";
  return {
    baseUrl,
    getAll: buildGetAll_default({ api, baseUrl }),
    resource
  };
};
var serviceModifiers_default = serviceModifiers;

// src/v2/services.ts
var services2 = (api) => {
  const baseUrl = "/api/v2/services";
  const resource = "service";
  return {
    baseUrl,
    getAll: buildGetAll_default({ api, baseUrl }),
    resource
  };
};
var services_default2 = services2;

// src/v2/tags.ts
var tags2 = (api) => {
  const baseUrl = "/api/v2/tags";
  const resource = "tag";
  return {
    baseUrl,
    get: buildGet_default({ api, baseUrl }),
    getAll: buildGetAll_default({ api, baseUrl }),
    delete: buildDelete_default({ api, baseUrl }),
    post: buildPost_default({ api, baseUrl, resource }),
    put: buildPut_default({ api, baseUrl, resource })
  };
};
var tags_default2 = tags2;

// src/v2/taskComments.ts
var taskComments = (api) => {
  const baseUrl = `/api/v2/tasks/`;
  const resource = "task_comment";
  return {
    baseUrl,
    getAll: async (options = {}, taskId) => {
      const processedOptions = processOptions_default(options);
      return api.get(
        `${baseUrl}${String(taskId)}/task_comments/`,
        processedOptions
      );
    },
    delete: async (options, taskId) => {
      var _a;
      const id = (_a = options == null ? void 0 : options.params) == null ? void 0 : _a.id;
      return api.delete(
        `${baseUrl}${taskId}/task_comments/${id}`,
        options
      );
    },
    post: async (options, taskId) => {
      const config = {
        params: { [resource]: options.params },
        ...options
      };
      config.data = { type: resource, attributes: options.data };
      return api.post(
        `${baseUrl}${taskId || options.data.task_id}/task_comments/`,
        config
      );
    }
  };
};
var taskComments_default = taskComments;

// src/v2/tasks.ts
var tags3 = (api) => {
  const baseUrl = "/api/v2/tasks";
  const resource = "task";
  return {
    baseUrl,
    get: buildGet_default({ api, baseUrl }),
    getAll: buildGetAll_default({ api, baseUrl }),
    delete: buildDelete_default({ api, baseUrl }),
    post: buildPost_default({ api, baseUrl, resource }),
    put: buildPut_default({ api, baseUrl, resource })
  };
};
var tasks_default = tags3;

// src/v2/textMessages.ts
var text_messages = (api) => {
  const baseUrl = "/api/v2/text_messages";
  const resource = "text_message";
  return {
    baseUrl,
    getAll: buildGetAll_default({ api, baseUrl }),
    post: buildPost_default({ api, baseUrl, resource }),
    put: buildPut_default({ api, baseUrl, resource })
  };
};
var textMessages_default = text_messages;

// src/v2/users.ts
var users = (api) => {
  const baseUrl = "/api/v2/users";
  return {
    async me(params = { include: [], optional_fields: {} }) {
      const response = await api.get("/api/v2/users/me", { params });
      return response;
    },
    getAll: buildGetAll_default({ api, baseUrl }),
    exists: async (params) => await api.post("/api/v2/users/exists", params),
    requestDeletion: async (params) => await api.post("/api/v2/users/request_deletion", params)
  };
};
var users_default = users;

// src/v2/yearlyRevenueReports.ts
var yearlyRevenueReports = (api) => {
  const baseUrl = "/api/v2/yearly_revenue_reports";
  return {
    baseUrl,
    getAll: buildGetAll_default(
      {
        api,
        baseUrl
      }
    )
  };
};
var yearlyRevenueReports_default = yearlyRevenueReports;

// src/types/company.types.ts
var DEFAULT_COF_ORDER = [
  "clients",
  "referral_source",
  "property",
  "agents_and_additional_people",
  "services",
  "datetime",
  "inspectors",
  "payment_details",
  "order_settings"
];
var COF_SECTION_TITLE_MAPPER = {
  clients: "Client(s)",
  referral_source: "Referral Source",
  property: "Property",
  agents_and_additional_people: "Agent(s)/People",
  datetime: "Date/Time",
  services: "Service(s)",
  inspectors: "Inspector(s)",
  payment_details: "Payment",
  order_settings: "Order Settings"
};
var PointsOfContact = /* @__PURE__ */ ((PointsOfContact2) => {
  PointsOfContact2["Company"] = "Company";
  PointsOfContact2["Inspector"] = "Inspector";
  return PointsOfContact2;
})(PointsOfContact || {});
var FraudStatuses = /* @__PURE__ */ ((FraudStatuses2) => {
  FraudStatuses2["Fraudulent"] = "fraudulent";
  FraudStatuses2["Monitoring"] = "monitoring";
  FraudStatuses2["FraudAlert"] = "fraud_alert";
  return FraudStatuses2;
})(FraudStatuses || {});

// src/types/payment.types.ts
var PaymentProcessors = /* @__PURE__ */ ((PaymentProcessors2) => {
  PaymentProcessors2["Spectora"] = "spectora";
  PaymentProcessors2["Stripe"] = "stripe";
  PaymentProcessors2["Authorize"] = "authorize";
  return PaymentProcessors2;
})(PaymentProcessors || {});
var PaymentStripeSetupType = /* @__PURE__ */ ((PaymentStripeSetupType2) => {
  PaymentStripeSetupType2["Company"] = "company";
  PaymentStripeSetupType2["Individual"] = "individual";
  return PaymentStripeSetupType2;
})(PaymentStripeSetupType || {});

// src/types/user.types.ts
var UserProfileTypes = /* @__PURE__ */ ((UserProfileTypes2) => {
  UserProfileTypes2["Inspector"] = "inspector";
  UserProfileTypes2["Manager"] = "manager";
  return UserProfileTypes2;
})(UserProfileTypes || {});

// src/index.ts
var buildConfig = (config = {}) => {
  config.headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    ...config.headers
  };
  if (!config.paramsSerializer) {
    config.paramsSerializer = paramsSerializer_default;
  }
  return config;
};
var baseApiService = {
  init: (config = {}, options = {}) => {
    const api = axios.create(buildConfig(config));
    api.interceptors.response.use(
      (response) => processResponse_default(response),
      (error) => processError_default(error, options)
    );
    return {
      // used to remove default headers for the axios instance
      deleteDefaultHeader: ({ name }) => {
        delete api.defaults.headers.common[name];
      },
      // access the axios instance if you need to do any lower level calls
      instance: api,
      // set a header (like an authorization token) for all api calls going forward
      setDefaultHeader: ({
        name,
        value
      }) => {
        api.defaults.headers.common[name] = value;
      },
      v1: {
        autocompleteChoices: autocompleteChoices_default(api),
        agents: agents_default(api),
        assignments: assignments_default(api),
        companies: companies_default(api),
        imports: imports_default(api),
        contractorTextMessageSubscribe: contractorTextMessageSubscribe_default(api),
        contractorTextMessageSubscribeFormDetails: contractorTextMessageSubscribeFormDetails_default(api),
        inspections: inspections_default(api),
        inspectionAgreementTemplates: inspectionAgreementTemplates_default(api),
        inspectors: inspectors_default(api),
        reportViews: reportViews_default(api),
        reports: reports_default(api),
        serviceAreas: serviceAreas_default(api),
        services: services_default(api),
        sessions: sessions_default(api),
        tags: tags_default(api),
        templates: templates_default(api),
        textMessageSubscriptions: textMessageSubscriptions_default(api),
        textMessagesUnsubscribe: textMessagesUnsubscribe_default(api)
      },
      v2: {
        agencies: agencies_default(api),
        agencyStats: agencyStats_default(api),
        agentTeams: agentTeams_default(api),
        agentTeamStats: agentTeamStats_default(api),
        calendarEvents: calendarEvents_default(api),
        changelogs: changelogs_default(api),
        companies: companies_default2(api),
        companyPeople: companyPeople_default(api),
        companyTrialBoardings: companyTrialBoardings_default(api),
        connections: connections_default(api),
        connectionStats: connectionStats_default(api),
        contacts: contacts_default(api),
        contractors: contractors_default(api),
        customFieldTemplates: customFieldTemplates_default(api),
        customPhoneNumbers: customPhoneNumbers_default(api),
        duplicatedConnections: duplicatedConnections_default(api),
        emails: emails_default(api),
        emailTemplates: email_templates_default(api),
        events: events_default(api),
        imports: imports_default2(api),
        inspection_cities: inspectionCities_default(api),
        inspection_states: inspectionStates_default(api),
        inspectionConciergeRequests: inspectionConciergeRequests_default(api),
        inspections: inspections_default2(api),
        inspectors: inspectors_default2(api),
        managers: managers_default(api),
        massEmails: massEmails_default(api),
        massEmailSettings: massEmailSettings_default(api),
        monthlyRevenueReports: monthlyRevenueReports_default(api),
        notes: notes_default(api),
        payments: payments_default(api),
        paysplits: paysplits_default(api),
        paysplitPayoutReports: paysplitPayoutReports_default(api),
        projectedRevenueReports: projectedRevenueReports_default(api),
        public: public_default(api),
        quarterlyRevenueReports: quarterlyRevenueReports_default(api),
        radonDevices: radonDevices_default(api),
        recommendations: recommendations_default(api),
        revenueByInspectorReports: revenueByInspectorReports_default(api),
        revenueByServiceReports: revenueByServiceReports_default(api),
        savedReports: savedReports_default(api),
        services: services_default2(api),
        serviceAddOns: serviceAddOns_default(api),
        serviceAreas: serviceAreas_default2(api),
        serviceModifiers: serviceModifiers_default(api),
        serviceCategories: serviceCategories_default(api),
        schedulerReports: schedulerReports_default(api),
        tags: tags_default2(api),
        taskComments: taskComments_default(api),
        tasks: tasks_default(api),
        textMessages: textMessages_default(api),
        users: users_default(api),
        yearlyRevenueReports: yearlyRevenueReports_default(api),
        inspectorRegistrations: inspectorRegistrations_default(api)
      }
    };
  }
};
var src_default = baseApiService;
export {
  COF_SECTION_TITLE_MAPPER,
  DEFAULT_COF_ORDER,
  FraudStatuses,
  PaymentProcessors,
  PaymentStripeSetupType,
  PointsOfContact,
  UserProfileTypes,
  src_default as default
};
