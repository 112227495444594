import baseApiService from '@spectora/base-api-service'
import { buildAppUrl } from '@spectora/utils'

// re-export all types found in the baseApiService
export * from '@spectora/base-api-service'

const api = baseApiService.init(
  {
    baseURL: import.meta.env.VITE_API_URL as string,
    withCredentials: true,
  },
  {
    onUnauthorized: () => {
      let redirect = ''

      if (window && window.location && window.location.href) {
        redirect = `?return_to=${window.location.href}`
      }

      window.location.href = buildAppUrl(redirect)
    },
  },
)

export default api
