// src/index.ts
var productAnalyticsService = {
  identify: ({ id, debug = false, properties = {} }) => {
    if (!window.heap) {
      console.log("[ERROR] Product Analytics: Heap not found on window.");
      return;
    }
    if (debug) {
      console.log("[ANALYTICS] identifying", id);
    }
    window.heap.identify(id);
    if (Object.keys(properties).length > 0) {
      if (debug) {
        console.log("[ANALYTICS] sending user properties", properties);
      }
      window.heap.addUserProperties(properties);
    }
  },
  init: ({ appId }) => {
    ;
    window.heap = window.heap || [], heap.load = function(e, t) {
      ;
      window.heap.appid = e, window.heap.config = t = t || {};
      const r = document.createElement("script");
      r.type = "text/javascript", r.async = true, r.src = "https://cdn.heapanalytics.com/js/heap-" + e + ".js";
      const a = document.getElementsByTagName("script")[0];
      a.parentNode.insertBefore(r, a);
      for (let n = function(e2) {
        return function() {
          heap.push([e2].concat(Array.prototype.slice.call(arguments, 0)));
        };
      }, p = [
        "addEventProperties",
        "addUserProperties",
        "clearEventProperties",
        "identify",
        "resetIdentity",
        "removeEventProperty",
        "setEventProperties",
        "track",
        "unsetEventProperty"
      ], o = 0; o < p.length; o++)
        heap[p[o]] = n(p[o]);
    };
    window.heap.load(appId);
    return window.heap;
  },
  track: (eventName, eventProperties = {}, options = {}) => {
    if (!window.heap) {
      console.log("[ERROR] Product Analytics: Heap not found on window.");
      return;
    }
    if (options.debug) {
      console.log("[ANALYTICS] track", eventName, eventProperties, options);
    }
    window.heap.track(eventName, eventProperties);
  }
};
var src_default = productAnalyticsService;
export {
  src_default as default
};
