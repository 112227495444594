import { useAcl } from '@spectora/acl'
import { redirectUnauthorized } from '@spectora/utils'
import { NavigationGuardNext } from 'vue-router'
import routerGuardRedirectUnlessCan from './routerGuardRedirectUnlessCan'

const routerGuardRequireAdvancedPermission = (
  to,
  from,
  next: NavigationGuardNext,
) => {
  const { ROLES, canUseAdvanced } = useAcl()

  if (!canUseAdvanced) {
    return redirectUnauthorized()
  }

  routerGuardRedirectUnlessCan({
    permission: ROLES.canUseAdvanced,
    next,
    nextRouteName: 'reporting.payments',
  })
}

export default routerGuardRequireAdvancedPermission
