// src/useAcl.ts
import { useAcl } from "vue-simple-acl";

// src/utils/roles.ts
var NEXT_ROLES = {
  canAccessConversations: "canAccessConversations",
  canAccessFinancialData: "canAccessFinancialData",
  canAddToTemplate: "canAddToTemplate",
  canEditTemplate: "canEditTemplate",
  canManageContacts: "canManageContacts",
  canPublish: "canPublish",
  canSchedule: "canSchedule",
  canUseAdvanced: "canUseAdvanced",
  canUseAgentReports: "canUseAgentReports",
  canUseAutoTagging: "canUseAutoTagging",
  canUseContractorsImporter: "canUseContractorsImporter",
  canUseContractorsServiceAreas: "canUseContractorsServiceAreas",
  canUseContractorsSMSQuoteRequest: "canUseContractorsSMSQuoteRequest",
  canUseDuplicatedAgentsReport: "canUseDuplicatedAgentsReport",
  canUseManualEmails: "canUseManualEmails",
  canUseMassEmail: "canUseMassEmail",
  canUseRecommendedContractors: "canUseRecommendedContractors",
  isAdmin: "isAdmin",
  isCompanyAdmin: "isCompanyAdmin",
  canUseClientPortalV3: "canUseClientPortalV3"
};
var PORTAL_ROLES = {
  canCreatePayments: "canCreatePayments",
  canReadAgreements: "canReadAgreements",
  canReadPayments: "canReadPayments",
  canReadReports: "canReadReports",
  canReadSpecialOffers: "canReadSpecialOffers",
  canUpdateAgreements: "canUpdateAgreements",
  canUpdateClosingDate: "canUpdateClosingDate",
  canUpdateServiceAddOns: "canUpdateServiceAddOns",
  canUpdateSpecialOffers: "canUpdateSpecialOffers",
  canUseRecommendedContractors: "canUseRecommendedContractors",
  canViewContractors: "canViewContractors"
};
var ROLES = {
  next: NEXT_ROLES,
  portal: PORTAL_ROLES
};
var getRoles = (app) => ROLES[app];

// src/utils/useAclStrategy.ts
var useAclStrategy = ({
  acl,
  roles,
  app
}) => {
  if (app === "next") {
    return {
      canAccessConversations: acl.can(roles.canAccessConversations),
      canAccessFinancialData: acl.can(roles.canAccessFinancialData),
      canAddToTemplate: acl.can(roles.canAddToTemplate),
      canEditTemplate: acl.can(roles.canEditTemplate),
      canManageContacts: acl.can(roles.canManageContacts),
      canPublish: acl.can(roles.canPublish),
      canSchedule: acl.can(roles.canSchedule),
      canUseAdvanced: acl.can(roles.canUseAdvanced),
      canUseAgentReports: acl.can(roles.canUseAgentReports),
      canUseAutoTagging: acl.can(roles.canUseAutoTagging),
      canUseContractorsImporter: acl.can(roles.canUseContractorsImporter),
      canUseContractorsServiceAreas: acl.can(
        roles.canUseContractorsServiceAreas
      ),
      canUseContractorsSMSQuoteRequest: acl.can(
        roles.canUseContractorsSMSQuoteRequest
      ),
      canUseDuplicatedAgentsReport: acl.can(roles.canUseDuplicatedAgentsReport),
      canUseManualEmails: acl.can(roles.canUseManualEmails),
      canUseMassEmail: acl.can(roles.canUseMassEmail),
      canUseRecommendedContractors: acl.can(roles.canUseRecommendedContractors),
      isAdmin: acl.can(roles.isAdmin),
      isCompanyAdmin: acl.can(roles.isCompanyAdmin),
      canUseClientPortalV3: acl.can(roles.canUseClientPortalV3)
    };
  } else {
    return {
      canCreatePayments: acl.can(roles.canCreatePayments),
      canReadAgreements: acl.can(roles.canReadAgreements),
      canReadPayments: acl.can(roles.canReadPayments),
      canReadReports: acl.can(roles.canReadReports),
      canReadSpecialOffers: acl.can(roles.canReadSpecialOffers),
      canUpdateAgreements: acl.can(roles.canUpdateAgreements),
      canUpdateClosingDate: acl.can(roles.canUpdateClosingDate),
      canUpdateServiceAddOns: acl.can(roles.canUpdateServiceAddOns),
      canUpdateSpecialOffers: acl.can(roles.canUpdateSpecialOffers),
      canViewContractors: acl.can(roles.canViewContractors),
      canUseRecommendedContractors: acl.can(roles.canUseRecommendedContractors)
    };
  }
};
var useAclStrategy_default = useAclStrategy;

// src/useAcl.ts
function useAcl_default(app = "next") {
  const acl = useAcl();
  const can = acl.can;
  const roles = getRoles(app);
  const rules = useAclStrategy_default({
    acl,
    roles,
    app
  });
  return {
    acl,
    can,
    ...rules,
    ROLES: roles
  };
}

// src/utils/buildAcl.ts
import { createAcl } from "vue-simple-acl";

// src/utils/defineAclRulesStrategy.ts
import { dig as dig4 } from "@spectora/utils";
import { defineAclRules } from "vue-simple-acl";

// src/utils/buildAclMassEmailRule.ts
import { dig as dig2 } from "@spectora/utils";

// src/utils/hasPermission.ts
import { dig } from "@spectora/utils";
var isAdmin = (user) => user && user.profile_type === "Admin";
var hasPermission = (user, name, fallback = false) => isAdmin(user) || Boolean(dig(user, name, fallback));
var hasPermission_default = hasPermission;

// src/utils/buildAclMassEmailRule.ts
var buildAclMassEmailRule = (user) => {
  const isCompanyAdmin = hasPermission_default(user, "profile.is_company_admin");
  const isMassEmailBlocked = dig2(
    user,
    "profile.company.settings.mass_email_blocked_by_admin"
  );
  const isMassEmailTrialStarted = dig2(
    user,
    "profile.company.settings.mass_email_trial_started_at"
  );
  const isMassEmailTrialExpired = dig2(
    user,
    "profile.company.settings.mass_email_trial_expired"
  );
  const hasMassEmailActiveSubscription = dig2(
    user,
    "profile.company.plan_ids",
    []
  ).find((plan) => plan.includes("mass_email"));
  return Boolean(
    isCompanyAdmin && !isMassEmailBlocked && (isMassEmailTrialStarted && !isMassEmailTrialExpired || hasMassEmailActiveSubscription)
  );
};
var buildAclMassEmailRule_default = buildAclMassEmailRule;

// src/utils/hasFeatureEnabled.ts
import { dig as dig3 } from "@spectora/utils";
var hasFeatureEnabled = (user, name) => !!dig3(user, `profile.company.features.${name}`, false);
var hasFeatureEnabled_default = hasFeatureEnabled;

// src/utils/defineAclRulesStrategy.ts
var nextRulesStrategy = () => defineAclRules((setRule) => {
  setRule(
    ROLES.next.canAccessConversations,
    (user) => hasPermission_default(user, "profile.settings.can_access_conversations")
  );
  setRule(
    ROLES.next.canAccessFinancialData,
    (user) => hasPermission_default(user, "profile.settings.can_access_financial_data")
  );
  setRule(
    ROLES.next.canAddToTemplate,
    (user) => hasPermission_default(user, "profile.settings.can_add_to_template")
  );
  setRule(
    ROLES.next.canEditTemplate,
    (user) => hasPermission_default(user, "profile.settings.can_edit_template")
  );
  setRule(
    ROLES.next.canManageContacts,
    (user) => hasPermission_default(user, "profile.settings.can_manage_contacts")
  );
  setRule(
    ROLES.next.canPublish,
    (user) => hasPermission_default(user, "profile.settings.can_publish")
  );
  setRule(
    ROLES.next.canSchedule,
    (user) => hasPermission_default(user, "profile.settings.can_schedule")
  );
  setRule(
    ROLES.next.canUseAdvanced,
    (user) => dig4(user, "profile.company.settings.enable_advanced", false)
  );
  setRule(
    ROLES.next.canUseAgentReports,
    (user) => dig4(user, "profile.company.settings.enable_agents_reports", false)
  );
  setRule(ROLES.next.canUseAutoTagging, (user) => {
    const advancedEnabled = dig4(
      user,
      "profile.company.settings.enable_advanced",
      false
    );
    return advancedEnabled && hasFeatureEnabled_default(user, "auto_tagging");
  });
  setRule(
    ROLES.next.canUseContractorsImporter,
    (user) => hasFeatureEnabled_default(user, "recommended_contractors_importer")
  );
  setRule(
    ROLES.next.canUseContractorsServiceAreas,
    (user) => hasFeatureEnabled_default(user, "recommended_contractors_service_boundaries")
  );
  setRule(
    ROLES.next.canUseContractorsSMSQuoteRequest,
    (user) => hasFeatureEnabled_default(user, "recommended_contractors_sms_quote_request")
  );
  setRule(ROLES.next.canUseDuplicatedAgentsReport, (user) => {
    const canManageContacts = hasPermission_default(
      user,
      "profile.settings.can_manage_contacts"
    );
    const isDuplicateAgentsReportFeatureEnabled = hasFeatureEnabled_default(
      user,
      "duplicate_agents_report"
    );
    return canManageContacts && isDuplicateAgentsReportFeatureEnabled;
  });
  setRule(
    ROLES.next.canUseManualEmails,
    (user) => dig4(user, "profile.company.settings.enable_manual_emails", false)
  );
  setRule(ROLES.next.canUseMassEmail, buildAclMassEmailRule_default);
  setRule(
    ROLES.next.canUseRecommendedContractors,
    (user) => dig4(
      user,
      "profile.company.settings.enable_recommended_contractors",
      false
    )
  );
  setRule(ROLES.next.isAdmin, (user) => user.admin);
  setRule(
    ROLES.next.isCompanyAdmin,
    (user) => hasPermission_default(user, "profile.is_company_admin")
  );
  setRule(
    ROLES.next.canUseClientPortalV3,
    (user) => hasFeatureEnabled_default(user, "client_portal_v3")
  );
});
var portalRulesStrategy = () => defineAclRules((setRule) => {
  setRule(
    ROLES.portal.canCreatePayments,
    (user) => dig4(user, "profile.permissions.can_create_payments", false)
  );
  setRule(
    ROLES.portal.canReadPayments,
    (user) => dig4(user, "profile.permissions.can_read_payments", false)
  );
  setRule(
    ROLES.portal.canReadAgreements,
    (user) => dig4(user, "profile.permissions.can_read_agreements", false)
  );
  setRule(
    ROLES.portal.canUpdateAgreements,
    (user) => dig4(user, "profile.permissions.can_update_agreements", false)
  );
  setRule(
    ROLES.portal.canReadReports,
    (user) => dig4(user, "profile.permissions.can_read_reports", false)
  );
  setRule(
    ROLES.portal.canUpdateServiceAddOns,
    (user) => dig4(user, "profile.permissions.can_update_service_add_ons", false)
  );
  setRule(
    ROLES.portal.canUpdateClosingDate,
    (user) => dig4(user, "profile.permissions.can_update_closing_date", false)
  );
  setRule(
    ROLES.portal.canReadSpecialOffers,
    (user) => dig4(user, "profile.permissions.can_read_special_offers", false)
  );
  setRule(
    ROLES.portal.canUpdateSpecialOffers,
    (user) => dig4(user, "profile.permissions.can_update_special_offers", false)
  );
  setRule(
    ROLES.portal.canViewContractors,
    (user) => dig4(
      user,
      "company.settings.enable_company_contractors_in_client_portal_v3",
      false
    )
  );
  setRule(
    ROLES.portal.canUseRecommendedContractors,
    (user) => dig4(user, "company.settings.enable_recommended_contractors", false)
  );
});
var defineAclRulesStrategy = {
  next: nextRulesStrategy,
  portal: portalRulesStrategy
};
var defineAclRulesStrategy_default = defineAclRulesStrategy;

// src/utils/buildAcl.ts
var buildAcl_default = ({ user, router, app = "next" }) => {
  const rules = () => defineAclRulesStrategy_default[app]();
  return createAcl({ rules, router, user });
};
export {
  ROLES,
  buildAcl_default as buildAcl,
  buildAclMassEmailRule_default as buildAclMassEmailRule,
  hasFeatureEnabled_default as hasFeatureEnabled,
  hasPermission_default as hasPermission,
  useAcl_default as useAcl
};
