// src/index.ts
import * as Sentry from "@sentry/vue";
import { captureException } from "@sentry/vue";
var transportOptionsDefaults = {
  SENTRY_LOCAL_DB: "sentry_local_events",
  SENTRY_LOCAL_STORE: "sentry_local_store",
  SENTRY_LOCAL_QUEUE_SIZE: 50
};
var vueErrorService = {
  enabled: false,
  error(err) {
    captureException(err);
  },
  init({
    Vue,
    dsn,
    env,
    release,
    router = null,
    tracingOrigins = [],
    integrations = [],
    replaysSessionSampleRate,
    replaysOnErrorSampleRate,
    transportOptions = {
      dbName: transportOptionsDefaults.SENTRY_LOCAL_DB,
      storeName: transportOptionsDefaults.SENTRY_LOCAL_STORE,
      queueSize: transportOptionsDefaults.SENTRY_LOCAL_QUEUE_SIZE
    }
  }) {
    if (!dsn) {
      return;
    }
    vueErrorService.enabled = true;
    console.log("Sentry active!");
    const integrationsDefaults = [
      Sentry.browserTracingIntegration({
        router,
        tracingOrigins
      })
    ];
    Sentry.init({
      Vue,
      dsn,
      integrations: [...integrationsDefaults, ...integrations],
      transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
      transportOptions,
      release,
      environment: env || "production",
      replaysSessionSampleRate,
      replaysOnErrorSampleRate
    });
  },
  identify({ id, email }) {
    if (!vueErrorService.enabled)
      return;
    Sentry.setUser({ id, email });
  },
  setContext: Sentry.setContext
};
var src_default = vueErrorService;
export {
  src_default as default
};
