<script setup lang="ts">
import useDateFormat from '@/composables/useDateFormat'
import formatDatetimeInTimezone from '@/utils/formatDatetimeInTimezone'
import { BaseIconV2 } from '@spectora/ui'
import { ref } from 'vue'
import flatPickr from 'vue-flatpickr-component'

const { dateFormat: altFormat } = useDateFormat({ format: 'flatpickr' })

interface iProps {
  context: any
}

const props = withDefaults(defineProps<iProps>(), {
  context: () => ({}),
})

const internalConfig = ref(
  Object.assign(
    {
      allowInput: true,
      altFormat,
      altInput: true,
      dateFormat: props.context.datetime ? 'Z' : 'Y-m-d',
      wrap: true,
    },
    // allows default config to be extended
    props.context.pluginConfig,
  ),
)

const prepareDate = (value) => {
  if (props.context.startOfDay) {
    return formatDatetimeInTimezone({ value, startOfDay: true })
  }

  if (props.context.endOfDay) {
    return formatDatetimeInTimezone({ value, endOfDay: true })
  }

  return value
}

// if the date is manually changed, we need to trigger this
// seems to be a bug with flatpickr and altInput
// https://github.com/flatpickr/flatpickr/issues/1551
const handleClose = (values) => {
  const date = prepareDate(values[0])

  props.context.node.input(date)
}

let lastUpdatedAt = new Date().getTime()

const handleUpdate = (value) => {
  if (new Date().getTime() - lastUpdatedAt < 10) return // Prevent infinite recursion

  lastUpdatedAt = new Date().getTime()
  const date = prepareDate(value)

  props.context.node.input(date)
}
</script>

<template>
  <div class="relative">
    <div
      class="absolute right-2.5 top-2 hidden cursor-pointer text-gray-400 sm:block"
      data-toggle
    >
      <base-icon-v2 icon="calendar-days" />
    </div>
    <flat-pickr
      :config="internalConfig"
      :class="context.classes.input"
      :model-value="context._value"
      :disabled="context.disabled"
      @update:model-value="handleUpdate"
      @on-close="handleClose"
    />
  </div>
</template>

<style>
/* https://github.com/flatpickr/flatpickr/issues/1904 */
@import 'flatpickr/dist/flatpickr.min.css';
</style>
