import { useAcl } from '@spectora/acl'
import { redirectUnauthorized } from '@spectora/utils'
import { NavigationGuardNext } from 'vue-router'
import routerGuardRedirectUnlessCan from './routerGuardRedirectUnlessCan'

const routerGuardRequireAdvancedAndAccessFinancialDataPermissions = (
  to,
  from,
  next: NavigationGuardNext,
) => {
  const { ROLES, canUseAdvanced, canAccessFinancialData } = useAcl()

  if (!canUseAdvanced && !canAccessFinancialData) {
    return redirectUnauthorized()
  }

  routerGuardRedirectUnlessCan({
    permission: [ROLES.canAccessFinancialData, ROLES.canUseAdvanced],
    next,
    nextRouteName: 'reporting.payments',
  })
}

export default routerGuardRequireAdvancedAndAccessFinancialDataPermissions
