import useSettingsStore from '@/stores/settingsStore'
import { parseDateFormat } from '@spectora/utils'

interface iUseDateFormat {
  format?: 'flatpickr' | 'dayjs'
}

const useDateFormat = ({ format = 'dayjs' }: iUseDateFormat = {}) => {
  const settings = useSettingsStore()
  const value = settings.company.settings?.date_format || 'MM/DD/YYYY'

  return {
    dateFormat: parseDateFormat({ value, format }),
  }
}

export default useDateFormat
