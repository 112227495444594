import { useAcl } from '@spectora/acl'
import { NavigationGuardNext } from 'vue-router'
import routerGuardRedirectUnlessCan from './routerGuardRedirectUnlessCan'

const routerGuardRequireEmailTemplatesPermission = (
  to,
  from,
  next: NavigationGuardNext,
) => {
  const { ROLES } = useAcl()

  return routerGuardRedirectUnlessCan({
    permission: [
      ROLES.canUseManualEmails,
      ROLES.isCompanyAdmin,
      ROLES.canUseAdvanced,
    ],
    next,
  })
}

export default routerGuardRequireEmailTemplatesPermission
