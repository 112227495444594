import api from '@/api'
import {
  getEnvVar,
  routerGuardMassEmail,
  routerGuardRequireAccessConversationsPermission,
  routerGuardRequireAccessFinancialDataPermission,
  routerGuardRequireAdvancedAndAccessFinancialDataPermissions,
  routerGuardRequireAdvancedPermission,
  routerGuardRequireEmailTemplatesPermission,
  routerGuardRequireManageContactsPermission,
} from '@/utils'
import { useAcl } from '@spectora/acl'
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

export const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'index',
    component: () => import('@/pages/PassThroughPage.vue'),
    beforeEnter: () => {
      window.location.href = getEnvVar('VITE_APP_URL') as string
    },
  },
  // Contacts
  {
    path: '/contacts',
    name: 'contacts',
    component: () => import('@/pages/PassThroughPage.vue'),
    children: [
      {
        path: 'clients',
        name: 'contacts.clients.index',
        component: () => import('@/pages/ClientsIndexPage.vue'),
        beforeEnter: routerGuardRequireManageContactsPermission,
      },
      {
        path: 'agents',
        name: 'contacts.agents.index',
        component: () => import('@/pages/AgentIndexPage.vue'),
        beforeEnter: routerGuardRequireManageContactsPermission,
        alias: ['/agents'],
      },
      {
        path: 'agents/:id',
        name: 'contacts.agents.show',
        component: () => import('@/pages/AgentEditOrShowPage.vue'),
        alias: ['/agents/:id'],
      },
      {
        path: 'agent-teams',
        name: 'contacts.agentTeams.index',
        component: () => import('@/pages/AgentTeamIndexPage.vue'),
        alias: ['/agent-teams'],
      },
      {
        path: 'agencies',
        name: 'contacts.agencies.index',
        component: () => import('@/pages/AgencyIndexPage.vue'),
        alias: ['/agencies'],
      },
      {
        path: 'agencies/:id',
        name: 'contacts.agencies.show',
        component: () => import('@/pages/AgencyEditOrShowPage.vue'),
        alias: ['/agencies/:id'],
      },
      {
        path: 'people',
        name: 'contacts.people.index',
        component: () => import('@/pages/PeopleIndexPage.vue'),
        beforeEnter: routerGuardRequireManageContactsPermission,
        alias: ['/people'],
      },
      {
        path: 'clients',
        name: 'contacts.clients.index',
        component: () => import('@/pages/ClientsIndexPage.vue'),
        beforeEnter: routerGuardRequireManageContactsPermission,
        alias: ['/clients'],
      },
      {
        path: 'contractors',
        name: 'contacts.contractors.index',
        component: () => import('@/pages/ContractorsIndexPage.vue'),
        alias: ['/contractors'],
      },
    ],
    redirect() {
      const { canUseAgentReports } = useAcl()

      const name = canUseAgentReports
        ? 'contacts.agents.index'
        : 'contacts.agents.clients'

      return { name }
    },
  },
  // Mass Emails
  {
    path: '/mass-emails',
    name: 'massEmails.index',
    component: () => import('@/pages/MassEmailIndexPage.vue'),
    beforeEnter: routerGuardMassEmail,
    alias: '/contacts/mass-emails',
  },
  // Commenting it out until Mass Emails issues are fixed - JENN-1140
  // {
  //   path: '/mass-emails/trial-expired',
  //   name: 'massEmails.trialExpired',
  //   component: () => import('@/pages/MassEmailTrialPage.vue'),
  //   beforeEnter: routerGuardMassEmail,
  //   alias: '/contacts/mass-emails/trial-expired',
  // },
  // {
  //   path: '/mass-emails/trial',
  //   name: 'massEmails.trial',
  //   component: () => import('@/pages/MassEmailTrialPage.vue'),
  //   beforeEnter: routerGuardMassEmail,
  //   alias: '/contacts/mass-emails/trial',
  // },
  {
    path: '/mass-emails/:id',
    name: 'massEmails.show',
    component: () => import('@/pages/MassEmailShowPage.vue'),
    beforeEnter: routerGuardMassEmail,
    alias: '/contacts/mass-emails/:id',
  },
  {
    path: '/mass-emails/:id/edit',
    name: 'massEmails.edit',
    component: () => import('@/pages/MassEmailEditPage.vue'),
    beforeEnter: routerGuardMassEmail,
    alias: '/contacts/mass-emails/:id/edit',
  },
  {
    path: '/mass-emails/:id/sent-emails',
    name: 'massEmails.sentEmails',
    component: () => import('@/pages/MassEmailSentEmailsPage.vue'),
    beforeEnter: routerGuardMassEmail,
    alias: '/contacts/mass-emails/:id/sent-emails',
  },
  {
    path: '/inspections/export',
    name: 'inspections.export',
    component: () => import('@/pages/InspectionsExportPage.vue'),
  },
  {
    path: '/calendar',
    name: 'calendar.index',
    component: () => import('@/pages/CalendarIndexPage.vue'),
  },
  {
    path: '/recommended-contractors',
    name: 'recommended-contractors.index',
    component: () => import('@/pages/RecommendedContractorsPage.vue'),
  },
  {
    path: '/custom-recommendations',
    name: 'custom-recommendations.index',
    component: () => import('@/pages/CustomRecommendationIndexPage.vue'),
  },
  {
    path: '/custom-fields',
    name: 'custom-fields.index',
    component: () => import('@/pages/CustomFieldTemplatesIndexPage.vue'),
  },
  {
    path: '/inspections/new',
    name: 'inspections.new',
    component: () => import('@/pages/CustomOrderFormPage.vue'),
  },
  {
    path: '/default-recommendations',
    name: 'default-recommendations.index',
    component: () => import('@/pages/DefaultRecommendationIndexPage.vue'),
  },
  {
    path: '/service-categories',
    name: 'service-categories.index',
    component: () => import('@/pages/ServiceCategoriesIndexPage.vue'),
    beforeEnter: routerGuardRequireAdvancedAndAccessFinancialDataPermissions,
  },
  {
    path: '/tags',
    name: 'tags.index',
    component: () => import('@/pages/TagIndexPage.vue'),
  },
  {
    path: '/advanced/radon-devices',
    name: 'radon_devices.index',
    component: () => import('@/pages/RadonDevicesIndexPage.vue'),
  },
  // Reporting
  {
    path: '/reporting',
    name: 'reporting',
    component: () => import('@/layouts/ReportingLayout.vue'),
    children: [
      {
        path: 'agent-birthdays',
        name: 'reporting.agent-birthdays',
        component: () => import('@/pages/ReportsShowAgentBirthdays.vue'),
        beforeEnter:
          routerGuardRequireAdvancedAndAccessFinancialDataPermissions,
      },
      {
        path: 'inactive-agents',
        name: 'reporting.inactive-agents',
        component: () => import('@/pages/ReportsShowInactiveAgents.vue'),
        beforeEnter:
          routerGuardRequireAdvancedAndAccessFinancialDataPermissions,
      },
      {
        path: 'new-agents',
        name: 'reporting.new-agents',
        component: () => import('@/pages/ReportsShowNewAgents.vue'),
        beforeEnter:
          routerGuardRequireAdvancedAndAccessFinancialDataPermissions,
      },
      {
        path: 'top-agencies',
        name: 'reporting.top-agencies',
        component: () => import('@/pages/ReportsShowTopAgencies.vue'),
        beforeEnter:
          routerGuardRequireAdvancedAndAccessFinancialDataPermissions,
      },
      {
        path: 'top-agents',
        name: 'reporting.top-agents',
        component: () => import('@/pages/ReportsShowTopAgents.vue'),
        beforeEnter:
          routerGuardRequireAdvancedAndAccessFinancialDataPermissions,
      },
      {
        path: 'top-agent-teams',
        name: 'reporting.top-agent-teams',
        component: () => import('@/pages/ReportsShowTopAgentTeams.vue'),
        beforeEnter:
          routerGuardRequireAdvancedAndAccessFinancialDataPermissions,
      },
      {
        path: 'duplicated-agents',
        name: 'reporting.duplicated-agents',
        component: () => import('@/pages/ReportsShowDuplicatedAgents.vue'),
        beforeEnter: routerGuardRequireManageContactsPermission,
      },
      {
        path: 'payments',
        name: 'reporting.payments',
        component: () => import('@/pages/ReportsShowPayments.vue'),
        beforeEnter: routerGuardRequireAccessFinancialDataPermission,
      },
      {
        path: 'paysplit-payout',
        name: 'reporting.paysplit-payout',
        component: () => import('@/pages/ReportsPaysplitPayoutsPage.vue'),
        beforeEnter:
          routerGuardRequireAdvancedAndAccessFinancialDataPermissions,
      },
      {
        path: 'unpaid-inspections',
        name: 'reporting.unpaid-inspections',
        component: () => import('@/pages/ReportsShowUnpaidInspections.vue'),
        beforeEnter: routerGuardRequireAccessFinancialDataPermission,
      },
      {
        path: 'canceled-inspections',
        name: 'reporting.canceled-inspections',
        component: () => import('@/pages/ReportsShowCanceledInspections.vue'),
      },
      {
        path: 'close-rates',
        name: 'reporting.close-rates',
        component: () => import('@/pages/ReportsShowCloseRates.vue'),
        beforeEnter:
          routerGuardRequireAdvancedAndAccessFinancialDataPermissions,
      },
      {
        path: 'monthly-revenue',
        name: 'reporting.monthly-revenue',
        component: () => import('@/pages/ReportsShowMonthlyRevenue.vue'),
        beforeEnter:
          routerGuardRequireAdvancedAndAccessFinancialDataPermissions,
      },
      {
        path: 'quarterly-revenue',
        name: 'reporting.quarterly-revenue',
        component: () => import('@/pages/ReportsShowQuarterlyRevenue.vue'),
        beforeEnter:
          routerGuardRequireAdvancedAndAccessFinancialDataPermissions,
      },
      {
        path: 'yearly-revenue',
        name: 'reporting.yearly-revenue',
        component: () => import('@/pages/ReportsShowYearlyRevenue.vue'),
        beforeEnter:
          routerGuardRequireAdvancedAndAccessFinancialDataPermissions,
      },
      {
        path: 'revenue-by-service',
        name: 'reporting.revenue-by-service',
        component: () => import('@/pages/ReportsShowRevenueByService.vue'),
        beforeEnter:
          routerGuardRequireAdvancedAndAccessFinancialDataPermissions,
      },
      {
        path: 'radon-devices',
        name: 'reporting.radon-events',
        component: () => import('@/pages/ReportsShowRadonEventsPage.vue'),
        beforeEnter: routerGuardRequireAdvancedPermission,
      },
      {
        path: 'revenue-by-inspector',
        name: 'reporting.revenue-by-inspector',
        component: () => import('@/pages/ReportsShowRevenueByInspector.vue'),
        beforeEnter: routerGuardRequireAdvancedPermission,
      },
      {
        path: 'projected-revenue',
        name: 'reporting.projected-revenue',
        component: () => import('@/pages/ReportsShowProjectedRevenue.vue'),
        beforeEnter:
          routerGuardRequireAdvancedAndAccessFinancialDataPermissions,
      },
      {
        path: 'custom-inspection-export',
        name: 'reporting.custom-inspection-export',
        component: () => import('@/pages/SavedInspectionReportsIndexPage.vue'),
        beforeEnter:
          routerGuardRequireAdvancedAndAccessFinancialDataPermissions,
      },
    ],
  },
  // Reports
  {
    path: '/reports',
    name: 'reports',
    component: () => import('@/layouts/ReportLayout.vue'),
    children: [
      {
        path: 'agent-birthdays',
        name: 'reports.agent-birthdays',
        component: () => import('@/pages/ReportsShowAgentBirthdays.vue'),
        beforeEnter:
          routerGuardRequireAdvancedAndAccessFinancialDataPermissions,
      },
      {
        path: 'inactive-agents',
        name: 'reports.inactive-agents',
        component: () => import('@/pages/ReportsShowInactiveAgents.vue'),
      },
      {
        path: 'new-agents',
        name: 'reports.new-agents',
        component: () => import('@/pages/ReportsShowNewAgents.vue'),
      },
      {
        path: 'payments',
        name: 'reports.payments',
        component: () => import('@/pages/ReportsShowPayments.vue'),
      },
      {
        path: 'top-agencies',
        name: 'reports.top-agencies',
        component: () => import('@/pages/ReportsShowTopAgencies.vue'),
      },
      {
        path: 'top-agents',
        name: 'reports.top-agents',
        component: () => import('@/pages/ReportsShowTopAgents.vue'),
      },
      {
        path: 'top-agent-teams',
        name: 'reports.top-agent-teams',
        component: () => import('@/pages/ReportsShowTopAgentTeams.vue'),
      },
      {
        path: 'unpaid-inspections',
        name: 'reports.unpaid-inspections',
        component: () => import('@/pages/ReportsShowUnpaidInspections.vue'),
      },
      {
        path: 'canceled-inspections',
        name: 'reports.canceled-inspections',
        component: () => import('@/pages/ReportsShowCanceledInspections.vue'),
      },
      {
        path: 'radon-devices',
        name: 'reports.radon-events',
        component: () => import('@/pages/ReportsShowRadonEventsPage.vue'),
      },
    ],
  },
  {
    path: '/reports/radon-device-availability',
    name: 'reports.radon-device-availability',
    component: () =>
      import('@/pages/ReportsShowRadonDeviceAvailabilityPage.vue'),
  },
  // Trial Boarding
  {
    path: '/trial-boarding',
    name: 'trial-boarding',
    component: () => import('@/pages/TrialBoardingIndexPage.vue'),
    children: [
      {
        path: 'company-info',
        name: 'trial-boarding.company-info',
        component: () => import('@/pages/TrialBoardingCompanyInfoPage.vue'),
      },
      {
        path: 'team',
        name: 'trial-boarding.team',
        component: () => import('@/pages/TrialBoardingTeamPage.vue'),
      },
      {
        path: 'services-and-fees',
        name: 'trial-boarding.services-and-fees',
        component: () => import('@/pages/TrialBoardingServicesAndFeesPage.vue'),
      },
      {
        path: 'mobile-app',
        name: 'trial-boarding.mobile-app',
        component: () => import('@/pages/TrialBoardingMobileAppPage.vue'),
      },
    ],
  },
  {
    path: '/trial-boarding/optional-steps',
    name: 'trial-boarding.optional-steps',
    component: () => import('@/pages/TrialBoardingOptionalStepsPage.vue'),
  },
  {
    path: '/trial-boarding/payments',
    name: 'trial-boarding.payments',
    component: () => import('@/pages/TrialBoardingPaymentsPage.vue'),
  },
  {
    path: '/public-presence',
    name: 'trial-boarding.public-presence',
    component: () => import('@/pages/TrialBoardingPublicPresencePage.vue'),
  },
  {
    path: '/trial-boarding/client-portal',
    name: 'trial-boarding.client-portal',
    component: () => import('@/pages/TrialBoardingClientPortalPage.vue'),
    redirect: {
      name: 'trial-boarding.client-portal.inspection-reports',
    },
    children: [
      {
        path: 'inspection-reports',
        name: 'trial-boarding.client-portal.inspection-reports',
        component: () =>
          import('@/pages/TrialBoardingInspectionReportsPage.vue'),
      },
      {
        path: 'upsells',
        name: 'trial-boarding.client-portal.upsells',
        component: () => import('@/pages/TrialBoardingUpsellsPage.vue'),
      },
    ],
  },
  {
    path: '/trial-boarding/communication',
    name: 'trial-boarding.communication',
    component: () => import('@/pages/TrialBoardingCommunicationPage.vue'),
    redirect: {
      name: 'trial-boarding.communication.overview',
    },
    children: [
      {
        path: 'overview',
        name: 'trial-boarding.communication.overview',
        component: () =>
          import('@/pages/TrialBoardingCommunicationOverviewPage.vue'),
      },
      {
        path: 'point-of-contact',
        name: 'trial-boarding.communication.point-of-contact',
        component: () => import('@/pages/TrialBoardingPointOfContactPage.vue'),
      },
      {
        path: 'email-preview',
        name: 'trial-boarding.communication.email-preview',
        component: () => import('@/pages/TrialBoardingEmailPreviewPage.vue'),
      },
      {
        path: 'sms-text-numbers',
        name: 'trial-boarding.communication.sms-text-numbers',
        component: () => import('@/pages/TrialBoardingSMSTextNumbersPage.vue'),
      },
    ],
  },
  {
    path: '/support-center',
    name: 'support-center',
    component: () => import('@/pages/SupportCenterPage.vue'),
  },
  // ----------------------------
  // IFRAME EMBEDS FOR LEGACY APP
  // ----------------------------
  {
    path: '/iframe/connections/:id/edit',
    name: 'iframe.connections.edit',
    component: () => import('@/pages/IframeConnectionFormPage.vue'),
  },
  {
    path: '/iframe/connections/new',
    name: 'iframe.connections.new',
    component: () => import('@/pages/IframeConnectionFormPage.vue'),
  },
  {
    path: '/iframe/manual-emails/new',
    name: 'iframe.manual-emails.new',
    component: () => import('@/pages/IframeManualEmailPage.vue'),
  },
  {
    path: '/iframe/tasks/:id/edit',
    name: 'iframe.tasks.edit',
    component: () => import('@/pages/IframeTaskFormPage.vue'),
  },
  {
    path: '/iframe/tasks/new',
    name: 'iframe.tasks.new',
    component: () => import('@/pages/IframeTaskFormPage.vue'),
  },
  {
    path: '/emails/:id',
    name: 'emails.show',
    component: () => import('@/pages/EmailShowPage.vue'),
    beforeEnter: routerGuardMassEmail,
  },
  // ----------------------------
  // EMAIL TEMPLATES routes
  // ----------------------------
  {
    path: '/email-templates',
    name: 'emailTemplates.index',
    component: () => import('@/pages/EmailTemplatesIndexPage.vue'),
    beforeEnter: routerGuardRequireEmailTemplatesPermission,
  },
  // ----------------------------
  // CONVERSATION routes
  // ----------------------------
  {
    path: '/conversations/:type/:id?',
    name: 'conversations.index',
    component: () => import('@/pages/ConversationsIndexPage.vue'),
    beforeEnter: routerGuardRequireAccessConversationsPermission,
  },
  // ----------------------------
  // PAYSPLITS routes
  // ----------------------------
  {
    path: '/paysplits',
    name: 'paysplits.index',
    component: () => import('@/pages/PaysplitsIndexPage.vue'),
    beforeEnter: routerGuardRequireAccessFinancialDataPermission,
  },
  // ----------------------------
  // TASKS routes
  // ----------------------------
  {
    path: '/tasks',
    name: 'tasks.index',
    component: () => import('@/pages/TaskIndexPage.vue'),
    beforeEnter: routerGuardRequireAdvancedPermission,
  },
  // ----------------------------
  // NEW SIGN-UP EXPERIENCE routes
  // ----------------------------
  {
    path: '/join',
    name: 'join',
    component: () => import('@/pages/SignUpPage.vue'),
  },
  {
    path: '/request-deletion',
    name: 'requestDeletion',
    component: () => import('@/pages/RequestDeletionPage.vue'),
  },
  // ----------------------------
  // Partnerships
  // ----------------------------
  {
    path: '/partnerships/liveeasy/concierge-request/:id',
    name: 'partnerships.liveeasy.conciergeRequest',
    component: () => import('@/pages/LiveEasyConciergeRequestPage.vue'),
  },
  // ----------------------------------
  // Text message subscribe/unsubscribe
  // ----------------------------------
  {
    path: '/contractors/text-messages/subscribe',
    name: 'contractors.text-messages.subscribe',
    component: () => import('@/pages/ContractorsTextMessagesSubscribePage.vue'),
  },
  {
    path: '/text-messages/unsubscribe',
    name: 'text-messages.unsubscribe',
    component: () => import('@/pages/TextMessagesUnsubscribePage.vue'),
  },
]

const router = createRouter({
  history: createWebHistory('/'),
  routes,
})

router.beforeEach((to) => {
  if (to.query.current_company_id) {
    api.setDefaultHeader({
      name: 'COMPANY_ID',
      value: to.query.current_company_id,
    })
  }
})

export default router
