// forms
import FormKitDatePicker from '@/components/FormKitDatePicker.vue'
import router from '@/router'
import useSettingsStore from '@/stores/settingsStore'
import { buildAcl } from '@spectora/acl'
import { createHead } from '@spectora/composables'
import { BaseToastPlugin, formkit } from '@spectora/ui'
import { createPinia, storeToRefs } from 'pinia'
import { createApp } from 'vue'
import AppLayout from './layouts/AppLayout.vue'
import { errorReporterService } from './services'
// style
import './assets/main.scss'
// floating vue plugin (for popovers)
// https://floating-vue.starpad.dev/guide/component.html#dropdown
import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'
import ClickOutside from '@/directives/clickOutside'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import 'leaflet/dist/leaflet.css'
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css'

// ------------------------
// ORDER MATTERS HERE
// ------------------------
const app = createApp(AppLayout)

if (import.meta.env.VITE_APP_SENTRY_DSN && !import.meta.env.DEV) {
  errorReporterService.init({
    Vue: app,
    dsn: import.meta.env.VITE_APP_SENTRY_DSN,
    env: import.meta.env.VITE_APP_SPECTORA_ENV,
    // Don't use getEnvVar b/c of this:
    // https://vitejs.dev/guide/env-and-mode.html#production-replacement
    release: `spectora-frontend-${import.meta.env.PACKAGE_VERSION}`,
    router,
    // sampleRate: import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE,
    tracingOrigins: [/spectora/],
  })
}

// Install floating vue plugin
app.use(FloatingVue, {
  themes: {
    'info-tooltip': {
      $extend: 'tooltip',
    },
  },
})

// integrate pinia stores
// you always want to do this first b/c it's used later in this setup
app.use(createPinia())

// acl permissions
const settingsStore = useSettingsStore()
const { user } = storeToRefs(settingsStore)
const acl = buildAcl({ router, user })

app.use(acl)

// meta head
app.use(createHead())

// toast notifications
app.use(BaseToastPlugin)

// formkit
// TODO: Move these to @spectora/ui
app.use(
  formkit.plugin,
  formkit.generateConfig({
    plugins: {
      ...formkit.plugins,
      datePicker: formkit.generateDatePickerPlugin(FormKitDatePicker),
    },
  }),
)

// Recaptcha 3
app.use(VueReCaptcha, {
  siteKey: import.meta.env.VITE_RECAPTCHA_V3_SITE_KEY,
  loaderOptions: {
    useRecaptchaNet: true,
    autoHideBadge: true,
  },
})

// Click outside directive
app.directive('click-outside', ClickOutside)

// We want to mount the router only after we've fetched settings.
// this way we can use router guards to redirect and know the settings
// data will already be loaded
settingsStore
  .fetch()
  .then(() => {
    app.use(router)
    app.mount('#app')
  })
  .catch((err) =>
    console.error('[ERROR]: there was an error loading this app', err),
  )
