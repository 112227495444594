import { useAcl } from '@spectora/acl'
import { redirectUnauthorized } from '@spectora/utils'
import { NavigationGuardNext } from 'vue-router'

interface iRouterGuard {
  permission: string | string[]
  next?: NavigationGuardNext
  nextRouteName?: string
}

export const routerGuardRedirectUnlessCan = ({
  permission,
  next,
  nextRouteName,
}: iRouterGuard): boolean | void => {
  const { can } = useAcl()

  const isAuthorized =
    permission.constructor == Array
      ? permission.reduce((memo, perm) => memo && can(perm), true)
      : can(permission)

  if (isAuthorized) {
    return next ? next() : true
  }

  // Not authorized scenarios
  if (next && nextRouteName) {
    return next({ name: nextRouteName })
  }

  redirectUnauthorized()
}

export default routerGuardRedirectUnlessCan
