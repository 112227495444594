<script setup lang="ts">
import SitewideSearch from '@/components/SitewideSearch.vue'
import TheNavbar from '@/components/TheNavbar.vue'
import{ useAnalytics } from '@spectora/composables'
import useSettingsStore from '@/stores/settingsStore'
import { isPublicRoute } from '@/utils'
import { BaseLoadingV2, BaseToast } from '@spectora/ui'
import { logAppVersion } from '@spectora/utils'
import { storeToRefs } from 'pinia'
import { computed, onMounted, ref } from 'vue'

const settingsStore = useSettingsStore()
const { user, profile, company } = storeToRefs(settingsStore)
const loading = ref(false)

const { setup } = useAnalytics()

enum LayoutMode {
  Sidebar = 'Sidebar',
  FullScreen = 'FullScreen',
  Navbar = 'Navbar',
}

const publicRoute = computed((): boolean => {
  return isPublicRoute(window.location.pathname)
})

// we process these here instead of using the router because there is a delay in getting it
// from the router and causes the header to render for a brief second
const mode = computed<LayoutMode>(() => {
  const pathname = window.location.pathname || ''

  if (pathname.search('trial-boarding') > -1) {
    return LayoutMode.Sidebar
  } else if (pathname.search('iframe') === -1) {
    return LayoutMode.FullScreen
  }

  return LayoutMode.Navbar
})
const shouldLoadHeap = [LayoutMode.FullScreen, LayoutMode.Sidebar].includes(
  mode.value,
)

if (shouldLoadHeap) {
  setup({
    company: company.value,
    profile: profile.value,
    user: user.value,
    isPublicRoute: publicRoute.value,
  })
}

onMounted(() => {
  logAppVersion({
    name: import.meta.env.PACKAGE_NAME,
    version: import.meta.env.PACKAGE_VERSION,
  })
})
</script>

<template>
  <base-toast />
  <div>
    <base-loading-v2
      v-if="loading"
      class="flex min-h-screen items-center justify-center"
      size="xl"
    />
    <div v-else>
      <template v-if="mode === LayoutMode.FullScreen">
        <the-navbar
          v-if="!publicRoute"
          :company="company"
          :profile="profile"
          :user="user"
        >
          <template #search>
            <sitewide-search />
          </template>
        </the-navbar>
        <router-view v-slot="{ Component, route }">
          <transition name="fade" mode="out-in">
            <component :is="Component" :key="route.path" />
          </transition>
        </router-view>
        <div id="popup-target"></div>
      </template>
      <template v-else-if="mode === LayoutMode.Sidebar">
        <router-view />
      </template>
      <template v-else-if="mode === LayoutMode.Navbar">
        <router-view v-slot="{ Component, route }">
          <transition name="fade" mode="out-in">
            <component :is="Component" :key="route.path" />
          </transition>
        </router-view>
      </template>
    </div>
  </div>
</template>
