import { useAcl } from '@spectora/acl'
import { NavigationGuardNext } from 'vue-router'
import routerGuardRedirectUnlessCan from './routerGuardRedirectUnlessCan'

const { ROLES } = useAcl()

const routerGuardMassEmail = (to, from, next: NavigationGuardNext) =>
  routerGuardRedirectUnlessCan({
    permission: ROLES.canUseMassEmail,
    next,
  })

export default routerGuardMassEmail
