import useSettingsStore from '@/stores/settingsStore'
import dayjs from '@/utils/dayjs'
import { useTimezone } from '@spectora/composables'

const formatDatetimeInTimezone = ({
  value,
  startOfDay = false,
  endOfDay = false,
}: {
  value: Date | string
  timezone?: string
  startOfDay?: boolean
  endOfDay?: boolean
}) => {
  const settings = useSettingsStore()
  const { timezone: zone } = useTimezone(settings)

  if (startOfDay) {
    return dayjs(value)
      .hour(12) // This fixes daylight savings time issue
      .tz(zone)
      .startOf('day')
      .utc()
      .format('YYYY-MM-DDTHH:mm:ss[Z]')
  }

  if (endOfDay) {
    return dayjs(value)
      .hour(12) // This fixes daylight savings time issue
      .tz(zone)
      .endOf('day')
      .utc()
      .format('YYYY-MM-DDTHH:mm:ss[Z]')
  }

  return dayjs(value).tz(zone).utc().format('YYYY-MM-DDTHH:mm:ss[Z]')
}

export default formatDatetimeInTimezone
