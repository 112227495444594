const froalaOptions = {
  key: import.meta.env.VITE_FROALA_KEY,
  attribution: false,
  pastePlain: true,
  useClasses: false,
  placeholderText: 'Email content',
  charCounterCount: false,
  videoResponsive: true,
  toolbarButtons: [
    'inlineStyle',
    'bold',
    'italic',
    'underline',
    'textColor',
    'backgroundColor',
    'color',
    '|',
    'formatOL',
    'formatUL',
    '|',
    'align',
    'insertLink',
    'insertImage',
    // 'insertVideo',
    'insertTable',
    '|',
    'html',
    'clearFormatting',
  ],
  toolbarButtonsMD: [
    'inlineStyle',
    'bold',
    'italic',
    'underline',
    'textColor',
    'backgroundColor',
    'color',
    '|',
    'align',
    'insertLink',
    'insertImage',
    // 'insertVideo',
    'insertTable',
    '|',
    'html',
    'clearFormatting',
  ],
  toolbarButtonsSM: [
    'inlineStyle',
    'bold',
    'italic',
    'underline',
    'textColor',
    'backgroundColor',
    'color',
    '|',
    'align',
    'insertLink',
    'insertImage',
    // 'insertVideo',
    'insertTable',
    '|',
    'html',
    'clearFormatting',
  ],
  toolbarButtonsXS: [
    'inlineStyle',
    'bold',
    'italic',
    'underline',
    'textColor',
    'backgroundColor',
    'color',
    '|',
    'align',
    'insertLink',
    'insertImage',
    // 'insertVideo',
    'insertTable',
    '|',
    'html',
    'clearFormatting',
  ],

  colorsText: [
    '#000000',
    '#222222',
    '#555555',
    '#888888',
    '#aaaaaa',
    '#dddddd',
    '#ffffff',
    '#d53636',
    '#f9890e',
    '#e5e318',
    '#65c635',
    '#5c9ccf',
    '#731eb5',
    'REMOVE',
  ],
  colorsBackground: [
    '#000000',
    '#222222',
    '#555555',
    '#888888',
    '#aaaaaa',
    '#dddddd',
    '#ffffff',
    '#d53636',
    '#f9890e',
    '#e5e318',
    '#65c635',
    '#5c9ccf',
    '#731eb5',
    'REMOVE',
  ],
  inlineStyles: {
    XLarge: 'font-size:2rem; color: inherit; line-height:2.25rem;',
    Large: 'font-size:1.5rem; color:inherit; line-height:1.7rem;',
    Normal: 'font-size:1rem; color: inherit; line-height: 1.2rem',
    Small: 'font-size: .8rem; line-height:1rem;',
    Light: 'color: #aaaaaa;',
    'Small/Light': 'font-size: .8rem; line-height:1rem; color: #aaaaaa;',
  },
  quickInsertButtons: ['ul', 'ol', 'hr'],
  pluginsEnabled: [
    'draggable',
    'align',
    'lineBreaker',
    'lists',
    'colors',
    'link',
    'image',
    'video',
    'table',
    'inlineStyle',
    'paragraphFormat',
    'paragraphStyle',
    'codeMirror',
    'codeView',
  ],
  height: window.innerHeight * 0.3,
  imageUploadURL: `${import.meta.env.VITE_APP_URL}/editor_assets`,
  linkInsertButtons: ['linkBack'],
  linkStyles: {
    'fr-blue': 'Blue',
    'fr-green': 'Green',
    'fr-strong': 'Thick',
  },
  videoInsertButtons: ['videoByURL', 'videoEmbed'],
  imageDefaultAlign: 'left',
  imageDefaultDisplay: 'inline',
  imageDefaultWidth: 200,
  imageEditButtons: [
    'imageAlign',
    'imageDisplay',
    'imageStyle',
    '-',
    'imageCaption',
    'imageAlt',
    'imageRemove',
    '-',
    'imageLink',
    'linkOpen',
    'linkEdit',
    'linkRemove',
  ],
  imageStyles: {
    circle: 'Circle',
    bordered: 'Border',
    shadowed: 'Shadow',
    margin: 'Margin',
    'padding-left': 'Padding Left',
    'padding-right': 'Padding Right',
  },
}

const froalaConfig = Object.assign(JSON.parse(JSON.stringify(froalaOptions)), {
  toolbarButtons: [
    'inlineStyle',
    'bold',
    'italic',
    'underline',
    'textColor',
    'backgroundColor',
    'color',
    '|',
    'formatOL',
    'formatUL',
    '|',
    'insertLink',
    'insertImage',
    // 'insertVideo',
    'insertTable',
    '|',
    'html',
    'clearFormatting',
  ],
  toolbarButtonsMD: [
    'inlineStyle',
    'bold',
    'italic',
    'underline',
    'textColor',
    'backgroundColor',
    'color',
    '|',
    'formatOL',
    'formatUL',
    '|',
    'insertLink',
    'insertImage',
    // 'insertVideo',
    'insertTable',
    '|',
    'html',
    'clearFormatting',
  ],
  toolbarButtonsSM: [
    'inlineStyle',
    'bold',
    'italic',
    'underline',
    'textColor',
    'backgroundColor',
    'color',
    '|',
    'formatOL',
    'formatUL',
    '|',
    'insertLink',
    'insertImage',
    // 'insertVideo',
    '|',
    'insertTable',
    '|',
    'html',
    'clearFormatting',
  ],
  toolbarButtonsXS: [
    'inlineStyle',
    'bold',
    'italic',
    'underline',
    'textColor',
    'backgroundColor',
    'color',
    '|',
    'formatOL',
    'formatUL',
    '|',
    'insertLink',
    'insertImage',
    // 'insertVideo',
    '|',
    'insertTable',
    '|',
    'html',
    'clearFormatting',
  ],
})

export default froalaConfig
